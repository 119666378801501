import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getCamerasLPRLogsByIdentifierAndParkId = async ({ id, estacionamentoId }) => {
    const results = await firebase.firestore().collection(Collection.CAMERAS_LPR_LOGS)
        .where(WhereField.PARKID, '==', estacionamentoId)
        .where(WhereField.CAMERA_ID, '==', id)
        .orderBy(WhereField.CREATED_AT, 'desc')
        .limit(100).get()
    return docsToItems(results).sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
}

export const deleteCameraLogById = async ({ id }) => {
    await deleteDoc({ collection: Collection.CAMERAS_LPR_LOGS, id: id })
}