import React from 'react'
import { EmptyContent, Loading } from '../../components/common/commons.js'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getCliente, getEstacionamento, getEstacionamentoCompact, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from '../../config/auth.js';
import { addTicket, getAllTicketsOpenByStatusAndParkId, getRandomNumberInTicketByParkId, getTicketById, getTicketByNumberAndParkId, getTicketByPlateAndParkId, removeTicketById } from "../../store/collections/ticketWorker"
import { ticketPermanenceFromNowDescription, ticketPermanenceFromNowDescriptionEdited, isValidDocument, isValidCPF, reloadWithAlert, reloadWindow, isValidatePlate, generateUUIDV7, takeIfIsNotNullOrEmpty, toCurrency, toastWarning, toastError, toastLoading, toastDismissLoading, toastSuccess, delay, isNullOrEmpty, valueByMaximunChars, documentMask } from '../../shared/utils.js';
import { searchCEP } from '../../shared/cepWorker';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { addNewNote } from '../../store/collections/newNotesWorker';
import { updateTicket } from '../../store/collections/ticketWorker';
import { getFormasPagamentosAtivas } from '../../store/collections/formaPagamentoWorker.js';
import { getAllActivePaymentTables, getAllPaymentTables, getCurrentPriceTableByDayAndTime } from '../../store/collections/paymentTableWorker.js';
import NumberFormat from 'react-number-format';
import { activeCashier } from '../../store/collections/cashierWorker.js';
import { getMonthlyById } from '../../store/collections/mensalistaWorker.js';
import { urlTicketOnlineFromTicket } from '../../shared/url-manager.js';
import { ProductsSimplyList } from '../dashboard/dashboard-products.js';
import { AlertDanger, AlertInfo, AlertSuccess, AlertWarning } from '../../shared/alert-manager.js';
import { getParameterByParkId } from '../../store/collections/parameterWorker.js';
import { getTicketSettings } from '../../store/collections/ticketSettings.js';
import { getAllVeiculosByMonthlyIdAndAccessNumber, getVehicleByPlateAndClientId } from '../../store/collections/veiculosWorker.js';
import { getAccessCardByParkIdAndNumber } from '../../store/collections/accessCardWorker.js';
import { getPrismSettingsByParkId } from '../../store/collections/prismWorker.js';
import { getAllBranchesAndModels } from '../../store/collections/branchesAndModelsWorker.js';
import { getRemoteConfigByKey } from '../../store/collections/remoteConfigWorker.js';
import { getAllProducts } from '../../store/collections/productWorker.js';
import { getAllSectoresByParkId, getSectorById } from '../../store/collections/setoresWorker.js';
import { addOrUpdateContactInfo, getContactInfoByPlateAndClientId } from '../../store/collections/contactInfoWorker.js';
import { getAccreditedById, getAccreditedByPlateAndParkId } from '../../store/collections/accreditedWorker.js';
import ReactToPrint from 'react-to-print'
import { PrinterTicketComponent } from '../../shared/printer/printer-ticket.js';
import { QRCodeSVG } from 'qrcode.react';
import { getAllRelationshipsByParkId, getRelationshipById } from '../../store/collections/relationshipWorker.js';
import { calculateTotalByTicket } from '../../shared/calculator/parking.calculate.js';
import { getProfileById } from '../../store/collections/profileWorker.js';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import TextField from '@mui/material/TextField';
import { NewNoteWidget } from '../nota-fiscal/novo/index'
import { TicketStatus } from '../../shared/constants.js';
import { TicketButton } from '../operation/permission.js';
import { TicketPaymentMethodModal, TicketRegisterConfirmedModal, TicketRegisterFindProductsModal, TicketRegisterNoteModal, TicketRegisterObservationModal, TicketRegisterPaymentModal } from '../ticket/index.js';

class SingleSaleModal extends React.Component {

    constructor(props) {
        super(props)
        const park = getEstacionamento()
        this.state = {
            value: "",
            state: "fill",
            buttonState: "in",
            parameter: null,
            isShowingModal: true,
            estacionamentoId: park.id,
            clienteId: park.clienteId,
            revendaId: park.revendaId,
            paymentTableSelectedId: "",
            habilitarObrigatoriedadeModeloCorDoVeiculoRapida: false,
            habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: false,
            currentDateChangeCalendarOpen: false,
            currentDate: moment(),
            currentDateChangedManually: false,
            habilitarDataEntrada: false,
            prismaEstacionamento: false,
            typePrismaEstacionamento: false,
            habilitarCampoContato: false,
            observacaoEstacionamento: false,
            setorEstacionamento: false,
            isSectorManuallyDefined: false,
            currentSector: null,
            ticketFound: false
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })
        const currentCashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ currentCashier: currentCashier })

        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameter: parameter })

        this.setState({ isLoading: false })
    }

    hideModal = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    saveNewticket = async () => {
        if (!this.validateAllFields()) {
            return null
        }
        this.setState({ isLoading: true })
        const id = generateUUIDV7()
        const date = this.state.currentDate.toDate()
        const estacionamento = getEstacionamentoCompact()
        const user = getUsuarioCompact()
        const promotialMessage = await getRemoteConfigByKey({ key: "APP_MESSAGE_TICKET" })
        const ticket = await getRandomNumberInTicketByParkId({ estacionamentoId: estacionamento.id })
        const system = `Web = ${window.navigator.userAgent}`
        const valor = this.state.products.map(e => e.quantidade * e.valor).reduce((a, b) => a + b, 0)
        const data = {
            id: id,
            version: "v6",
            revendaId: this.state.revendaId,
            clienteId: this.state.clienteId,
            estacionamento: {
                id: estacionamento.id,
                celular: estacionamento.celular,
                documento: estacionamento.documento,
                horarios: estacionamento.horarios,
                nome: estacionamento.nome,
                endereco: `${estacionamento.endereco.logradouro} - ${estacionamento.endereco.numero}`,
            },
            estacionamentoId: estacionamento.id,
            estacionamentoNome: estacionamento.nome,
            caixaFechado: false,
            ticket: ticket,
            type: "SALES",
            internalType: "SALES",
            promotialMessage: promotialMessage.value,
            status: "Pago e Entregue",
            caixasIds: [this.state.currentCashier?.id],
            paymentsLogged: [],
            statusLogged: [
                {
                    status: "Pago e Entregue",
                    createAt: date,
                    createBy: user,
                    currentCashier: this.state.currentCashier.id
                }
            ],
            changeLogged: [{
                message: "Venda avulsa",
                createdAt: new Date(),
                createdBy: user
            }],
            produtos: this.state.products,
            createAt: date,
            fastIn: true,
            createBy: user.email,
            createByName: user.nome,
            system: {
                app: system,
                version: moment().format("DD/MM/YYYY [às] HH:mm:ss"),
            },
            settings: {
                printCustomMessage: this.state.currentSettings?.printCustomMessage,
                flavor: "Web"
            },
            updatedAt: date,
        }
        var now = moment()
        var end = moment()
        var duration = moment.duration(end.diff(now)).asMinutes() ?? 0
        const payment = {
            caixaId: this.state.currentCashier?.id,
            identifier: generateUUIDV7(),
            user: user,
            formaPagamento: this.state.paymentMethod,
            resumo: {
                valorEstacionamento: 0,
                valorProdutos: this.state.products.filter(e => e.tipoProduto === "SELL").map(e => e.quantidade * e.valor).reduce((a, b) => a + b, 0),
                valorServicos: this.state.products.filter(e => e.tipoProduto === "SERVICES").map(e => e.quantidade * e.valor).reduce((a, b) => a + b, 0),
                valorDesconto: 0,
                valorCobrado: valor,
                startParking: new Date(),
                finishParking: new Date(),
                permanencia: duration,
                permanenciaMaxima: end.toDate(),
                products: data.produtos,
                type: "SINGLE_SALE",
                order: this.state.paymentMethod?.tipo && {
                    tipo: `ADMINISTRATIVO - ${this.state.paymentMethod.tipo?.toUpperCase()}`
                },
            },
            createAt: new Date(),
            canceled: false,
            online: false
        }
        data.paymentsLogged.push(payment)
        if (this.state.note) {
            data.newNote = await this.generateNoteIfNeed(id, valor)
            data.changeLogged.push({
                identifier: generateUUIDV7(),
                message: "Nota fiscal adicionada",
                createdAt: new Date(),
                createdBy: user
            })
        }
        data.closedAt = new Date()
        data.closedBy = user.email
        data.changeLogged.push({
            identifier: generateUUIDV7(),
            message: `Pagamento registrado com sucesso: ${toCurrency(valor)}`,
            createdAt: new Date(),
            createdBy: user
        })
        await addTicket({ data: data })
        const ticketAdded = await getTicketById({ id: id })
        return ticketAdded
    }

    generateNoteIfNeed = async (id, valor) => {
        if (this.state.note) {
            if (valor > 0) {
                var data = {
                    id: generateUUIDV7(),
                    ...this.state.note,
                    identifier: id,
                    type: "customizado",
                    valor: valor,
                }
                const newNote = await addNewNote({ data: data })
                return newNote
            }
        }
    }

    validateAllFields = () => {
        if (isNullOrEmpty(this.state.products)) {
            toastWarning("É preciso selecionar pelo menos um produto.")
            return false
        }
        if (isNullOrEmpty(this.state.paymentMethod)) {
            toastWarning("Informe a forma de pagamento.")
            return false
        }
        return true
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    changeStateFormTo = (state) => {
        this.setState({ state: state })
    }

    productsConfirmed = (item) => {
        console.log(item);
        
        this.setState({ products: item })
        this.setState({ productsQuantity: item.map(e => e.quantidade).reduce((a, b) => a + b, 0) })
    }

    paymentMethodConfirmed = (item) => {
        this.setState({ paymentMethod: item })
    }

    noteConfirmed = (note) => {
        this.setState({ note: note })
    }

    onCloseForms = () => {
        this.changeStateFormTo("fill")
    }

    reloadModal = () => {
        reloadWindow()
    }

    confirmVehicle = async () => {
        const ticket = await this.saveNewticket()
        if (ticket) {
            this.setState({ ticket: ticket })
            this.setState({ state: "ticket-added" })
            this.setState({ isLoading: false })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading ?
                        <Dialog open={this.state.isShowingModal}>
                            <Loading />
                        </Dialog> :
                        <Dialog maxWidth='xs' fullWidth open={this.state.isShowingModal}>
                            <MuiDialogTitle className='text-center'>
                                <span>Venda avulsa</span>
                            </MuiDialogTitle>
                            <MuiDialogContent style={{ maxHeight: '600px' }}>
                                {
                                    this.state.state === "fill" &&
                                    <div>
                                        <ul className="list-group">
                                            <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("products")}>
                                                <div className="me-auto">
                                                    <i className="fas fa-hamburger" />
                                                    <span className='mx-2'>Produtos e serviços</span>
                                                </div>
                                                {
                                                    this.state.products ?
                                                        <span className="badge bg-success rounded-pill">
                                                            {this.state.productsQuantity}
                                                            <i className="fas fa-edit mx-1" />
                                                        </span> :
                                                        <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                                }
                                            </li>
                                            {
                                                this.state.parameter?.habilitarEmissaoNotaV2 &&
                                                <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("note")}>
                                                    <div className="me-auto">
                                                        <i className="fas fa-copy" />
                                                        <span className='mx-2'>Nota fiscal</span>
                                                    </div>
                                                    {
                                                        this.state.note ?
                                                            <span className="badge bg-success rounded-pill">
                                                                {documentMask(this.state.note?.document)}
                                                                <i className="fas fa-edit mx-1" />
                                                            </span> :
                                                            <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                                    }
                                                </li>
                                            }
                                            <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("payment-method")}>
                                                <div className="me-auto">
                                                    <i className="fas fa-money-bill" />
                                                    <span className='mx-2'>Forma de pagamento</span>
                                                </div>
                                                {
                                                    this.state.paymentMethod ?
                                                        <span className="badge bg-success rounded-pill">
                                                            {this.state.paymentMethod?.nome}
                                                            <i className="fas fa-edit mx-1" />
                                                        </span> :
                                                        <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                                }
                                            </li>
                                            {
                                                !isNullOrEmpty(this.state.products) &&
                                                <div className='mt-2'>
                                                    <h5>Total de produtos</h5>
                                                    <h3 className='text-success'>
                                                        {toCurrency(this.state.products.map(e => e.quantidade * e.valor).reduce((a, b) => a + b, 0))}
                                                    </h3>
                                                    {
                                                        this.state.products.map(e => {
                                                            return <span key={e.id} className="badge bg-secondary rounded-pill mx-1">
                                                                {e.quantidade}x {e.descricao}
                                                            </span>
                                                        })
                                                    }
                                                </div>
                                            }
                                        </ul>
                                    </div>
                                }
                                {
                                    this.state.state === "products" && <TicketRegisterFindProductsModal
                                        estacionamentoId={this.state.estacionamentoId}
                                        items={this.state.products}
                                        onConfirm={this.productsConfirmed}
                                        onClose={this.onCloseForms} />
                                }
                                {
                                    this.state.state === "payment-method" && <TicketPaymentMethodModal
                                        estacionamentoId={this.state.estacionamentoId}
                                        items={this.state.paymentMethod}
                                        onConfirm={this.paymentMethodConfirmed}
                                        onClose={this.onCloseForms} />
                                }
                                {
                                    this.state.state === "note" && <TicketRegisterNoteModal
                                        estacionamentoId={this.state.estacionamentoId}
                                        note={this.state.note}
                                        onConfirm={this.noteConfirmed}
                                        onClose={this.onCloseForms} />
                                }
                                {
                                    this.state.state === "ticket-added" && <TicketRegisterConfirmedModal
                                        estacionamentoId={this.state.estacionamentoId}
                                        ticket={this.state.ticket}
                                        onClose={this.onCloseForms} />
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                {
                                    this.state.state === "fill" &&
                                    <div className='btn-group m-3'>
                                        <button onClick={this.hideModal} className='btn btn-secondary'>
                                            Fechar
                                        </button>
                                        <button onClick={this.confirmVehicle} className='btn btn-success'>
                                            Confirmar
                                        </button>
                                    </div>
                                }
                                {
                                    this.state.state === "ticket-added" &&
                                    <div className='btn-group m-3'>
                                        <div style={{ display: "none" }}>
                                            <PrinterTicketComponent
                                                ticket={this.state.ticket}
                                                park={getEstacionamento()}
                                                ref={el => (this.componentRef = el)} />
                                        </div>
                                        <a href={`/buscar/ticket?id=${this.state.ticket?.id}`} className='btn btn-success' target='_blank'>
                                            Ver ticket
                                        </a>
                                        <ReactToPrint
                                            trigger={() => {
                                                return <a href="#" className='btn btn-secondary'>Imprimir</a>
                                            }}
                                            content={() => this.componentRef}
                                        />
                                        <button onClick={this.reloadModal} className='btn btn-secondary'>
                                            Fechar
                                        </button>
                                    </div>
                                }
                            </MuiDialogActions>
                        </Dialog>
                }
                {
                    this.state.state === "payment" && <TicketRegisterPaymentModal
                        showCashiers={false}
                        generatedTicket={true}
                        ticketFound={this.state.ticketFound}
                        statusPaymentTicket={this.state.statusPaymentTicket}
                        ticket={this.state.ticket}
                        onClose={this.onClose} />
                }
            </>)
    }
}

export { SingleSaleModal }