import React from 'react';
import { NavBar } from '../../components/navbar'
import { Loading, EmptyContent } from '../../components/common/commons';
import { getAllLastTickets, getAllRequestedTickets, getTicketById, updateTicket } from '../../store/collections/ticketWorker';
import { getEstacionamento, getUsuarioCompact } from '../../config/auth';
import { ticketPermanenceFromNowDescription, toastSuccess, delay, takeIfIsNotNullOrEmpty, toastWarning, generateUUIDV7 } from '../../shared/utils';
import moment from 'moment';

class OperarPatioAovivo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            tickets: [],
            state: "loading"
        }
        this.loadData()
    }

    loadData = async () => {
        const tickets = await getAllRequestedTickets({ estacionamentoId: this.state.estacionamentoId })
        if (tickets.length > 0) {
            this.setState({
                tickets: tickets,
                state: "data"
            })
        } else {
            this.setState({
                state: "empty"
            })
        }
        await delay(30000)
        this.loadData()
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="col-lg-12">
                        <h3>
                            Veículos solicitados
                        </h3>
                        {
                            this.state.state === "loading" &&
                            <div className="card-body">
                                <Loading />
                            </div>
                        }
                        {
                            this.state.state === "empty" &&
                            <div className="card-body">
                                <EmptyContent text="🚀 Parece que todos os veículos solicitados já foram entregues." />
                            </div>
                        }
                        {
                            this.state.state === "data" &&
                            <div>
                                <div className='row mt-3'>
                                    {
                                        this.state.tickets.map(item =>
                                            <OperarPatioAovivoItem ticket={item} key={item.id} />
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </NavBar>
            </>
        )
    }
}


class OperarPatioAovivoItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket,
            random: Math.random()
        }
        this.loadData()
    }

    loadData = async () => {

    }

    alertFromCard = () => {
        if (this.state.ticket.requestedData?.confirmedAt) {
            return `alert alert-success`
        }
        if (this.state.ticket.requestedData?.requestedAt) {
            return `alert alert-warning`
        }
        return `alert alert-secondary`
    }

    buttonFromCard = () => {
        if (this.state.ticket.requestedData?.confirmedAt) {
            return `btn btn-success btn-lg text-white`
        }
        if (this.state.ticket.requestedData?.requestedAt) {
            return `btn btn-warning btn-lg text-white w-100`
        }
        return `btn btn-warning btn-lg text-white`
    }

    confirmInWay = async () => {
        const ticket = this.state.ticket
        const currentUser = getUsuarioCompact()
        ticket.requestedData = {
            confirmedAt: new Date(),
            confirmedBy: currentUser
        }
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: "Veículo no caminho",
            createdAt: new Date(),
            createdBy: currentUser
        })
        toastSuccess("Solicitação de veículo confirmada")
        await updateTicket(ticket.id, ticket)
        const newTicket = await getTicketById({ id: ticket.id })
        this.setState({ ticket: newTicket })
        this.setState({ random: Math.random() })
    }

    cancelDelivery = async () => {
        const ticket = this.state.ticket
        const currentUser = getUsuarioCompact()
        ticket.requestedData = {
            requestedAt: new Date(),
            requestedBy: currentUser
        }
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: "Solicitação de veículo cancelada",
            createdAt: new Date(),
            createdBy: currentUser
        })
        toastWarning("Solicitação de veículo cancelada")
        await updateTicket(ticket.id, ticket)
        const newTicket = await getTicketById({ id: ticket.id })
        this.setState({ ticket: newTicket })
        this.setState({ random: Math.random() })
    }

    confirmDelivery = async () => {
        const ticket = this.state.ticket
        const currentUser = getUsuarioCompact()
        ticket.requestedData = {
            deliveredAt: new Date(),
            deliveredAt: currentUser
        }
        ticket.requested = false
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: "Veículo entregue",
            createdAt: new Date(),
            createdBy: currentUser
        })
        toastSuccess("Veículo entregue")
        await updateTicket(ticket.id, ticket)
        const newTicket = await getTicketById({ id: ticket.id })
        this.setState({ ticket: newTicket })
        this.setState({ random: Math.random() })
    }

    render() {
        return (<>
            {this.state.ticket.requested &&
                <div className="col-lg-4" key={this.state.random}>
                    <div className={this.alertFromCard()} role='button'>
                        {
                            this.state.ticket.requestedData?.confirmedAt ?
                                <h4><span className="badge text-bg-success text-white">A caminho desde ás {moment(this.state.ticket.requestedData?.confirmedAt.seconds * 1000).format("HH:mm")}</span></h4> :
                                <>
                                    {
                                        this.state.ticket.requestedData?.requestedAt &&
                                        <h4><span className="badge text-bg-warning text-white">Solicitado ás {moment(this.state.ticket.requestedData?.requestedAt.seconds * 1000).format("HH:mm")}</span></h4>
                                    }
                                </>
                        }
                        {
                            this.state.ticket.vehicler?.modelo ?
                                <h4><strong>Modelo:</strong> {this.state.ticket.vehicler?.marca} {this.state.ticket.vehicler?.modelo}</h4> :
                                <h4><strong>Modelo:</strong> Não informado</h4>
                        }
                        <h4><strong>Placa:</strong> {takeIfIsNotNullOrEmpty(this.state.ticket.placa) ?? "Não informado"}</h4>
                        <h4><strong>Prisma:</strong> {takeIfIsNotNullOrEmpty(this.state.ticket.prisma) ?? "Não informado"}</h4>
                        <h5><strong>Ticket:</strong> {this.state.ticket.ticket}</h5>
                        {
                            this.state.ticket.requestedData?.confirmedAt ?
                                <div className='btn-group w-100'>
                                    <button type="button" onClick={(e) => this.cancelDelivery()} className="btn btn-danger btn-lg text-white">
                                        <i className="fas fa-car-alt" />
                                        <span className='mx-2'>Cancelar</span>
                                    </button>
                                    <button type="button" onClick={(e) => this.confirmDelivery()} className="btn btn-success btn-lg text-white">
                                        <i className="fas fa-car-alt" />
                                        <span className='mx-2'>Entregue</span>
                                    </button>
                                </div> : <>
                                    {
                                        this.state.ticket.requestedData?.requestedAt &&
                                        <button type="button" onClick={(e) => this.confirmInWay()} className={this.buttonFromCard()}>
                                            <i className="fas fa-car-alt" />
                                            <span className='mx-2'>A caminho</span>
                                        </button>
                                    }
                                </>
                        }
                    </div>
                </div>
            }
        </>
        )
    }
}

export { OperarPatioAovivo }