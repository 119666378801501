import React from 'react'

import Tooltip from '@mui/material/Tooltip';
import { getCliente, getEstacionamento } from '../../../config/auth'
import { Loading } from '../../../components/common/commons'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Moment from 'moment';
import { addAccessCard, getAllAccessCardsByReferenceId, removeAccessCard, getAccessCardByParkIdAndNumber } from '../../../store/collections/accessCardWorker';
import { getAllVeiculosByMonthlyIdAndAccessNumber } from '../../../store/collections/veiculosWorker';
import { toastWarning } from '../../../shared/utils';
import { getMonthlyById } from '../../../store/collections/mensalistaWorker';
import { getAccreditedById } from '../../../store/collections/accreditedWorker';

class RegisterAccessCardModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            referenceId: props.referenceId,
            showModal: props.showModal,
            state: "loading",
            cardNumber: "",
            allAccessCard: [],
            type: props.type
        }
    }

    componentDidMount = async () => {
        this.setState({ showModal: true })
        this.load()
    }

    load = async () => {
        this.setState({ state: "loading" })
        var allAccessCard = await getAllAccessCardsByReferenceId({ referenceId: this.state.referenceId, estacionamentoId: this.state.estacionamentoId })
        this.setState({ allAccessCard: allAccessCard })
        this.setState({ state: "fill" })
    }

    hideModal = () => {
        this.setState({ showModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover este cartão de acesso?`);
        if (confirm) {
            if (this.state.type === "monthly") {
                const vehicles = await getAllVeiculosByMonthlyIdAndAccessNumber({ mensalistaId: this.state.referenceId, accessCard: item.cardNumber })
                if (vehicles.length > 0) {
                    toastWarning("Existe um veículo associado a este cartão.")
                    return
                }
            }
            await removeAccessCard({ id: item.id })
            if (this.props.onCardUpdated) {
                this.props.onCardUpdated()
            }
            this.load()
        }
    }

    save = async () => {
        if (this.state.cardNumber === "") {
            toastWarning("Informe o cartão de acesso.")
            return null
        }
        const number = parseInt(this.state.cardNumber).toString()
        if (number.length < 6) {
            toastWarning("O cartão de acesso deve ter no mínimo 6 números.\nNão consideramos números zeros a esquerda.")
            return null
        }
        this.setState({ state: "loading" })
        const accessCard = await getAccessCardByParkIdAndNumber({ estacionamentoId: this.state.estacionamentoId, accessCard: number })
        if (accessCard) {
            this.setState({ state: "fill" })
            var message = "Já existe um cartão de acesso com este número criado para este estabelecimento."
            if (accessCard.type === "accredited") {
                const accredited = await getAccreditedById({ id: accessCard.referenceId })
                if (accredited) {
                    message = `O credenciado ${accredited.nome} já está associado a este cartão.`
                }
            } else {
                const monthly = await getMonthlyById(accessCard.referenceId)
                if (monthly) {
                    message = `O mensalista ${monthly.nome} já está associado a este cartão.`
                }
            }
            toastWarning(message)
            return
        }
        await addAccessCard({
            clienteId: this.state.clienteId,
            estacionamentoId: this.state.estacionamentoId,
            referenceId: this.state.referenceId,
            type: this.state.type,
            accessCard: this.state.cardNumber
        })
        if (this.props.onCardUpdated) {
            this.props.onCardUpdated()
        }
        this.setState({ cardNumber: "" })
        this.load()
    }

    handleChangeAccessCardNumber = (e) => {
        const value = e.target.value.toUpperCase()
        this.setState({ cardNumber: value })
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='sm' open={this.state.showModal}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "fill" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Cadastrar cartão de acesso
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    {
                                        this.state.message &&
                                        <div className="col-lg-12">
                                            <div className="alert alert-warning">
                                                <span>{this.state.message}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-lg-12">
                                        <label>Cartão de acesso</label>
                                        <input type="text" pattern="[a-zA-Z0-9-]+" value={this.state.cardNumber} onChange={this.handleChangeAccessCardNumber} className="form-control" required />
                                    </div>
                                    {
                                        this.state.allAccessCard.length > 0 &&
                                        <div className='col-lg-12 mt-3'>
                                            <h5>Cartões associados</h5>
                                            <table className="table">
                                                <tr>
                                                    <td>Cartão</td>
                                                    <td>Criação</td>
                                                    <td>Operador</td>
                                                    <td align='right'></td>
                                                </tr>
                                                {
                                                    this.state.allAccessCard.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.cardNumber}</td>
                                                            <td>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                            <td>{item.createdBy?.nome}</td>
                                                            <td align='right'>
                                                                <Tooltip title="Remover cartão de acesso" placement="top">
                                                                    <span>
                                                                        <button className="btn btn-danger btn-sm" onClick={(e) => this.onRemove(item)}>
                                                                            <i className="fas fa-trash" />
                                                                        </button>
                                                                    </span>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </table>
                                        </div>
                                    }
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.save} className='btn btn-success'>Salvar novo cartão</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog >
            </>
        )
    }
}

export { RegisterAccessCardModal };