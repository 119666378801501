import { getPerfil, getUsuario } from "./auth"

export const isAllowedByPage = () => {
    const user = getUsuario()
    if (user?.owner === true) {
        return true
    }
    const profile = getPerfil()
    const permissions = [
        { path: '/plataforma/revendas', allowed: user?.platform ?? false },
        { path: '/revenda/marketing/banner', allowed: user?.platform ?? false },
        { path: '/revenda/chamados', allowed: user?.acessoRevendedorChamados ?? false },
        { path: '/revenda/contratos', allowed: user?.acessoRevendedorContratos ?? false },
        { path: '/revenda/home', allowed: user.acessoRevendedorDashboard ?? false },
        { path: '/revenda/cliente', allowed: user?.acessoRevendedorCliente ?? false },
        { path: '/revenda/revendedores', allowed: user?.acessoRevendedorRevendedores ?? false },
        { path: '/revenda/central-suporte', allowed: user?.acessoRevendedorCentralSuporte ?? false },
        { path: '/revenda/detalhes', allowed: user?.acessoRevendedorConfiguracao ?? false },
        { path: '/revenda/analytics/logs', allowed: user?.acessoRevendedorLogs ?? false },
        { path: '/revenda/analytics/usuarios', allowed: user?.acessoRevendedorUsuarios ?? false },
        { path: '/revenda/analytics/consumo', allowed: user?.acessoRevendedorConsumo ?? false },
        { path: '/revenda/analytics/caixas', allowed: user?.acessoRevendedorCaixas ?? false },
        { path: '/revenda/analytics/mensalistas', allowed: user?.acessoRevendedorMensalistas ?? false },
        { path: '/revenda/financeiro/gerenciar-cobrancas', allowed: user?.acessoRevendedorCobrancas ?? false },
        { path: '/revenda/financeiro/gerenciar-planos', allowed: user?.acessoRevendedorPlanos ?? false },
        { path: '/revenda/financeiro/gerenciar-assinaturas', allowed: user?.acessoRevendedorAssinaturas ?? false },
        { path: '/revenda/financeiro/notas-fiscais', allowed: user?.acessoRevendedorNotasFiscais ?? false },
        { path: '/desenvolvimento/configuracoes-remotas', allowed: user?.developer ?? false },
        { path: '/desenvolvimento/versao-minima', allowed: user?.acessoRevendedorVersaoMinima ?? false },
        { path: '/desenvolvimento/marcas-e-modelos', allowed: user?.acessoRevendedorMarcasEModelos ?? false },
        { path: '/desenvolvimento/aplicacoes', allowed: user?.acessoRevendedorAplicacoes ?? false },
        { path: '/desenvolvimento/backup', allowed: user?.acessoRevendedorBackup ?? false },
        { path: '/desenvolvimento/atualizacao', allowed: profile?.developer ?? false },
        { path: '/desenvolvimento/filas-de-callback', allowed: user?.developer ?? false },
        { path: '/desenvolvimento/mensagens-whatsapp', allowed: user?.developer ?? false },
        { path: '/home', allowed: true },
        { path: '/pagamentos/resumo', allowed: profile?.acessoPagamentosResumo ?? true },
        { path: '/operacao/patio-ao-vivo', allowed: profile?.acessoHabilitarOperarPatioAoVivo ?? true },
        { path: '/licenca/minha-licenca', allowed: profile?.acessoHabilitarConfiguracoesLicenca ?? true },
        { path: '/cadastrar/mensalista', allowed: profile?.createClient ?? true },
        { path: '/visualizar/mensalista', allowed: profile?.createClient ?? true },
        { path: '/cadastrar/plano', allowed: profile?.createPlan ?? true },
        { path: '/cadastrar/produto', allowed: profile?.createProduct ?? true },
        { path: '/cadastrar/convenio', allowed: profile?.createPartnership ?? true },
        { path: '/cadastrar/perfil', allowed: profile?.createProfile ?? true },
        { path: '/cadastrar/usuario', allowed: profile?.createUser ?? true },
        { path: '/cadastrar/forma-pagamento', allowed: profile?.createWayPayment ?? true },
        { path: '/cadastrar/tabela-preco', allowed: profile?.createPaymentTable ?? true },
        { path: '/cadastrar/setor', allowed: profile?.createSectors ?? true },
        { path: '/cadastrar/credenciado', allowed: profile?.createAccredited ?? true },
        { path: '/buscar/ticket', allowed: profile?.acessoHabilitarBuscaTickets ?? true },
        { path: '/buscar/caixas', allowed: profile?.acessoHabilitarBuscaCaixas ?? true },
        { path: '/buscar/mensalistas', allowed: profile?.acessoHabilitarBuscaMensalistas ?? true },
        { path: '/relatorios/vendas', allowed: profile?.acessoHabilitarRelatorioVendas ?? true },
        { path: '/relatorios/caixas', allowed: profile?.acessoHabilitarRelatorioCaixas ?? true },
        { path: '/caixas/', allowed: profile?.acessoHabilitarRelatorioCaixas ?? true },
        { path: '/relatorios/mensalistas', allowed: profile?.acessoHabilitarRelatorioMensalistas ?? true },
        { path: '/relatorios/acessos', allowed: profile?.acessoHabilitarRelatorioAcessos ?? true },
        { path: '/nota-fiscal/listar', allowed: profile?.acessoHabilitarNotaFiscalSeries ?? true },
        { path: '/nota-fiscal/configurar', allowed: profile?.acessoHabilitarNotaFiscalConfigurar ?? true },
        { path: '/configuracao/parametros', allowed: profile?.acessoHabilitarConfiguracoesParametros ?? true },
        { path: '/configuracao/dados-cadastrais', allowed: profile?.acessoHabilitarConfiguracoesDadosCadastrais ?? true },
        { path: '/configuracao/cobrancas', allowed: profile?.acessoHabilitarConfiguracoesCobrancas ?? true },
        { path: '/equipamentos/totens', allowed: profile?.acessoCancelaTotem ?? true },
        { path: '/equipamentos/cameras-lpr', allowed: profile?.acessoCancelaLPR ?? true },
        { path: '/equipamentos/aparelhos', allowed: profile?.acessoAparelhos ?? true },
        { path: '/conta/termos', allowed: true },
    ]
    const selected = permissions.filter(e => window.location.href.indexOf(e.path) !== -1)
    if (selected.length > 0) {
        const item = selected[0]
        return item.allowed
    } else {
        return false
    }
}