import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const addProduct = async ({ clienteId,
    estacionamentoId,
    descricao,
    valor,
    valorCusto,
    margemLucro,
    tipoProduto,
    createdBy,
    revendaId }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: revendaId ?? getRevenda()?.id,
        createdAt: new Date(),
        createdBy: createdBy,
        descricao: descricao,
        tipoProduto: tipoProduto,
        valor: valor,
        valorCusto: valorCusto,
        margemLucro: margemLucro,
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.PRODUCT, data: data })
    return doc.id;
}

export const getProductById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.PRODUCT).doc(id).get()
    return docToItem(result)
}

export const getAllProductsByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.PRODUCT, clienteId: clienteId })
}

export const getAllProducts = async ({ parkId }) => {
    var results = await firebase.firestore().collection(Collection.PRODUCT).where(WhereField.PARKID, '==', parkId).get()
    return docsToItems(results).sort((a, b) => a.descricao.trim().localeCompare(b.descricao.trim()))
}

export const deleteProduct = async ({ id }) => {
    await deleteDoc({ collection: Collection.PRODUCT, id: id })
}

export const getAllCategories = async ({ parkId }) => {
    const results = await firebase.firestore().collection(Collection.PRODUCT_CATEGORIES).where(WhereField.PARKID, '==', parkId).get()
    return docsToItems(results)
}

export const getAllSubCategories = async ({ parkId }) => {
    const results = await firebase.firestore().collection(Collection.PRODUCT_SUB_CATEGORIES).where(WhereField.PARKID, '==', parkId).get()
    return docsToItems(results)
}

export const updateProductById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PRODUCT, id: id, data: data })
}