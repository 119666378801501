import { getRevenda, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { documentMask, normalizedField } from '../../shared/utils';
import { docsToItems, docToItem } from '../transform.docs';
import { addDoc, getAllDocsByRevendaId, updateDoc } from './collectionBaseWorker';

export const addPark = async ({ clienteId, nome, email, phone, document, nomeInterno, representante, horarios, address, revendaId }) => {
    const data = {
        nome: nome,
        nomeInterno: nomeInterno ?? nome,
        celular: phone,
        documento: documentMask(document),
        horarios: horarios ?? "Horario de funcionamento",
        clienteId: clienteId,
        createdAt: new Date(),
        createdBy: email,
        representante: representante ?? nome,
        revendaId: revendaId ?? getRevenda()?.id,
        endereco: {
            id: "",
            cep: address?.cep,
            logradouro: address?.logradouro ?? "Endereço de exemplo",
            numero: address?.numero ?? "123",
            bairro: address?.bairro,
            cidade: address?.cidade,
            estado: address?.estado,
            ibge: address?.ibge,
            complemento: address?.complemento
        },
        status: "Ativo",
        tipoDocumento: normalizedField(document).length === 11 ? "CPF" : "CNPJ",
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.PARK, data: data })
    return doc.id;
}

export const countParksByReseleId = async ({ revendaId }) => {
    const result = await firebase.firestore().collection(Collection.PARK).where("revendaId", "==", revendaId).get()
    return docsToItems(result).length
}

export const getAllParksByRevendaId = async ({ revendaId }) => {
    return getAllDocsByRevendaId({ collection: Collection.PARK, revendaId: revendaId })
}

export const getAllParksByClientId = async ({ clientId }) => {
    if (clientId) {
        const result = await firebase.firestore().collection(Collection.PARK).where('clienteId', '==', clientId).get()
        return docsToItems(result)
    }
    return []
}

export const getAllParks = async () => {
    const result = await firebase.firestore().collection(Collection.PARK).get()
    return docsToItems(result)
}

export const getAllParksByRessaleId = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.PARK).where("revendaId", "==", id).get()
    return docsToItems(result)
}

export const getAllParksByLimitId = async ({ limit }) => {
    const result = await firebase.firestore().collection(Collection.PARK).limit(limit).get()
    return docsToItems(result)
}

//Não usar mais
export const getPark = async ({ id }) => {
    try {
        const result = await firebase.firestore().collection(Collection.PARK).doc(id).get()
        return { success: true, data: docToItem(result) }
    } catch (err) {
        return { success: false, message: "Cliente não encontrado" }
    }
}

export const getParkById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.PARK).doc(id).get()
    return docToItem(result)
}

export const updateParkRevendaId = async ({ id, revendaId }) => {
    const data = {
        revendaId: revendaId,
        changeRevendaBy: getUsuario()?.nome,
        changeRevendaAt: new Date()
    }
    await updateDoc({ collection: Collection.PARK, id: id, data: data })
}

export const updateParkById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PARK, id: id, data: data })
}
