import * as XLSX from 'xlsx';
import { addUser, updateUser } from "../../../store/collections/userWorker"
import { addCliente } from "../../../store/collections/clienteWorker"
import { addPark } from "../../../store/collections/parkWorker"
import { addParameter } from "../../../store/collections/parameterWorker"
import { addLicenca } from "../../../store/collections/licencaWorker"
import { addPaymentTable } from "../../../store/collections/paymentTableWorker"
import { addProfileFilled } from "../../../store/collections/profileWorker"
import { addPayment } from "../../../store/collections/paymentWorker"
import { addVerify } from "../../../store/collections/cellphoneVerifyWorker"
import { searchCEP } from '../../../shared/cepWorker';
import { addRelationship } from '../../../store/collections/relationshipWorker';
import { addMonthlyPlan, getAllMonthlyPlans } from '../../../store/collections/monthlyPlans';
import { addMonthly } from '../../../store/collections/mensalistaWorker';
import { getAllVeiculosByClientId, addVehicle } from '../../../store/collections/veiculosWorker';
import Moment from 'moment';

var errors = []
var warnings = []
var clientId = ""
var parkId = ""
var userId = ""

export const importClientByExcell = async (data, updateStatus, error, completed) => {
    const response = prepareData(data.data)
    const validation = validateFields(response)
    if (validation.success === false) {
        error(validation.errors)
    } else {
        await importData(response, updateStatus, error, completed)
    }
}

const importData = async (response, updateStatus, error, completed) => {
    const created = await createUser(response, updateStatus, error)
    if (!created) { return }
    await createClient(response, updateStatus)
    await createPark(response, updateStatus)
    await createPlans(response, updateStatus)
    await createMonthly(response, updateStatus)
    await createWayPayments(response, updateStatus)
    await createRelationships(response, updateStatus)
    await addNormalData(response, updateStatus)
    completed(clientId, warnings)
}

const createUser = async (data, updateStatus, error) => {
    updateStatus({ progress: 5, text: "Cadastrando usuário..." })
    const client = data.park[0]
    const response = await addUser({
        nome: client.NOME_REPRESENTANTE,
        email: client.EMAIL_LOGIN,
        phone: client.CELULAR_OPCIONAL,
        password: "111111",
    })
    if (response.success === false) {
        addError("ESTACIONAMENTO", 0, response.message)
        error(errors)
        userId = null
        return false
    }
    userId = response.id
    return true
}

const createClient = async (data, updateStatus) => {
    updateStatus({ progress: 10, text: "Cadastrando cliente..." })
    const client = data.park[0]
    clientId = await addCliente({
        nome: client.NOME_ESTACIONAMENTO,
        email: client.EMAIL_LOGIN,
        representante: client.NOME_REPRESENTANTE,
        phone: client.CELULAR_OPCIONAL,
        document: client.DOCUMENTO_OPCIONAL,
        address: await fetchAddress(client)
    })
}

const createPlans = async (data, updateStatus) => {
    updateStatus({ progress: 20, text: "Cadastrando planos..." })
    const allPlans = await getAllMonthlyPlans({ estacionamentoId: parkId })
    for (let index = 0; index < data.plans.length; index++) {
        const item = data.plans[index];
        const planSelected = allPlans.filter(e => e.nome === item.NOME)
        if (planSelected.length !== 0) {
            warnings.push({
                message: `Já existe um plano cadastrado com o nome: ${item.NOME}`
            })
        } else {
            await addMonthlyPlan({
                clientId: clientId,
                estacionamentoId: parkId,
                nome: item.NOME,
                horarioInicio: Moment(item.HORARIO_INICIO).format("HH:mm"),
                horarioFim: Moment(item.HORARIO_FIM).format("HH:mm"),
                vagasDisponiveis: parseInt(item.VAGAS_DISPONIVEIS),
                valorMensal: item.VALOR,
            })
        }
    }
}

const createMonthly = async (data, updateStatus) => {
    updateStatus({ progress: 40, text: "Cadastrando mensalistas..." })
    const allPlans = await getAllMonthlyPlans({ estacionamentoId: parkId })
    const allVehicles = await getAllVeiculosByClientId({ clientId: clientId })
    for (let index = 0; index < data.monthlies.length; index++) {
        const item = data.monthlies[index];
        var hasError = false
        const planSelected = allPlans.filter(e => e.nome === item.PLANO)
        if (planSelected.length === 0) {
            warnings.push({
                message: `Não encontramos o plano ${item.PLANO} do mensalista ${item.NOME_DO_CLIENTE} e ele não foi cadastrado.`
            })
            hasError = true
        }
        const vehicleSelected = allVehicles.filter(e => e.placa === item.PLACA_VEICULO.toUpperCase().replace('-', ''))
        if (vehicleSelected.length !== 0) {
            warnings.push({
                message: `A placa ${item.PLACA_VEICULO} já esta associada a outro mensalista, ${item.NOME_DO_CLIENTE} não foi cadastrado.`
            })
            hasError = true
        }
        if (!hasError) {
            updateStatus({ progress: 40, text: `Cadastrando mensalista ${item.NOME_DO_CLIENTE}...` })
            const monthlyId = await addMonthly({
                clientId: clientId,
                estacionamentoId: parkId,
                nome: item.NOME_DO_CLIENTE,
                dataFatura: Moment(item.DATA_PRIMEIRA_FATURA),
                diaVencimento: item.DIA_VENCIMENTO.toString(),
                plano: planSelected[0],
            })
            await addVehicle({
                clientId: clientId,
                mensalistaId: monthlyId,
                placa: item.PLACA_VEICULO.toUpperCase().replace('-', ''),
                modelo: item.MODELO_VEICULO_OPCIONAL,
                marca: item.MARCA_VEICULO_OPCIONAL,
                cor: item.COR_VEICULO_OPCIONAL
            })
        }
    }
}

const createPark = async (data, updateStatus) => {
    updateStatus({ progress: 30, text: `Cadastrando estacionamento...` })
    const park = data.park[0]
    parkId = await addPark({
        clienteId: clientId,
        nome: park.NOME_ESTACIONAMENTO,
        email: park.EMAIL_LOGIN,
        representante: park.NOME_REPRESENTANTE,
        phone: park.CELULAR_OPCIONAL,
        document: park.DOCUMENTO_OPCIONAL,
        horarios: park.HORARIO_FUNCIONAMENTO,
        address: await fetchAddress(park)
    })
}

const createWayPayments = async (data, updateStatus) => {
    updateStatus({ progress: 60, text: `Cadastrando formas de pagamento...` })
    for (let index = 0; index < data.wayPayments.length; index++) {
        const item = data.wayPayments[index];
        await addPayment({
            clienteId: clientId,
            estacionamentoId: parkId,
            nome: item.NOME_BANDEIRA,
            tipo: item.TIPO,
            status: "Ativo"
        })
    }
}

const createRelationships = async (data, updateStatus) => {
    updateStatus({ progress: 70, text: `Cadastrando convênios...` })
    for (let index = 0; index < data.relationships.length; index++) {
        const item = data.relationships[index];
        await addRelationship({
            clienteId: clientId,
            estacionamentoId: parkId,
            nome: item.NOME_CONVENIO,
            tipo: item.TIPO,
            valor: item.VALOR_OU_PORCENTAGEM,
            tolerancia: Moment(item.TOLERANCIA_MAXIMA).format("HH:mm"),
            manterDesconto: item.MANTER_DESCONTO_POS_TOLERANCIA === "NAO" ? "Não" : "Sim",
            status: "Ativo"
        })
    }
}

const addNormalData = async (data, updateStatus) => {
    updateStatus({ progress: 75, text: "Salvando tabela de preço..." })
    await addParameter({
        clienteId: clientId,
        estacionamentoId: parkId,
    })

    updateStatus({ progress: 80, text: "Salvando tabela de preço..." })
    await addLicenca({
        clienteId: clientId,
        estacionamentoId: parkId,
    })

    updateStatus({ progress: 85, text: "Salvando tabela de preço de exemplo..." })
    await addPaymentTable({
        clienteId: clientId,
        estacionamentoId: parkId,
    })

    updateStatus({ progress: 90, text: "Salvando perfil administrativo..." })
    const profileId = await addProfileFilled({
        clienteId: clientId,
        clienteNome: data.park[0].NOME_ESTACIONAMENTO,
        estacionamentoId: parkId,
    })

    updateStatus({ progress: 100, text: "Atualizando usuário..." })
    await updateUser({
        userId: userId,
        clienteId: clientId,
        estacionamentoId: parkId,
        perfilId: profileId,
        status: "ACTIVE",
        parkName: data.park[0].NOME_ESTACIONAMENTO,
    })

    await addVerify({
        clientId: clientId,
        nome: data.park[0].NOME_REPRESENTANTE,
        email: data.park[0].EMAIL_LOGIN,
        phone: data.park[0].CELULAR_OPCIONAL
    })
}

const fetchAddress = async (park) => {
    const cep = park.CEP_OPCIONAL
    if (cep === undefined || cep === "") {
        return null
    }
    const number = park.NUMERO_OPCIONAL
    const address = await searchCEP(cep)
    if (address === null) {
        warnings.push({
            message: `CEP do estacionamento não encontrado: ${cep}, não foi possível salvar o endereço.`
        })
        return null
    }
    return {
        cep: cep,
        numero: number,
        estado: address.uf,
        cidade: address.localidade,
        ...address
    }
}

const prepareData = (readedData) => {
    var response = []
    for (let index = 0; index < readedData.SheetNames.length; index++) {
        const wsname = readedData.SheetNames[index];
        const ws = readedData.Sheets[wsname];
        const json = XLSX.utils.sheet_to_json(ws, { header: 2, cellDates: true });
        response.push({
            type: wsname,
            data: json
        })
    }
    const data = {
        park: getRowsFrom(response, "ESTACIONAMENTO"),
        plans: getRowsFrom(response, "PLANOS_MENSALISTA"),
        monthlies: getRowsFrom(response, "MENSALISTAS"),
        relationships: getRowsFrom(response, "CONVENIOS"),
        wayPayments: getRowsFrom(response, "FORMAS_DE_PAGAMENTO")
    }
    return data
}

const validateFields = (response) => {
    errors = []
    validateClient(response)
    validatePlans(response)
    validateMonthlies(response)
    validateRelationship(response)
    validateWayPayment(response)
    return { success: errors.length === 0, errors: errors }
}

const validateWayPayment = (response) => {
    for (let index = 0; index < response.wayPayments.length; index++) {
        const item = response.wayPayments[index];
        if ("Dinheiro,Crédito,Débito,Pix,Transferencia,Cheque,Boleto".indexOf(item.TIPO) === -1) {
            verifyWayPaymentField("", index, "Informar um tipo válido de forma de pagamento")
        } else {
            verifyWayPaymentField(item.TIPO, index, "É preciso informar o tipo da forma de pagamento")
        }
        verifyWayPaymentField(item.NOME_BANDEIRA, index, "É preciso informar o nome ou a bandeira do tipo de pagamento")
    }
}

const validateRelationship = (response) => {
    for (let index = 0; index < response.relationships.length; index++) {
        const item = response.relationships[index];
        verifyRelationshipField(item.NOME_CONVENIO, index, "É preciso informar o nome do convênio")
        verifyRelationshipField(item.TIPO, index, "É preciso informar o tipo do convênio")
        if (item.TIPO === "VALOR") {
            verifyRelationshipField(item.VALOR_OU_PORCENTAGEM, index, "É preciso informar o valor do desconto: ##.##")
        } else if (item.TIPO === "PORCENTAGEM") {
            verifyRelationshipField(item.VALOR_OU_PORCENTAGEM, index, "É preciso informar a porcentagem do desconto: de 0 a 100")
        }
        verifyRelationshipField(item.TOLERANCIA_MAXIMA, index, "É preciso informar a tolerância máxima em horas e minutos: ##:##")
        verifyRelationshipField(item.MANTER_DESCONTO_POS_TOLERANCIA, index, "É preciso informar se deve manter o desconto após a tolerância do convênio: SIM ou NÃO")
    }
}

const validatePlans = (response) => {
    for (let index = 0; index < response.plans.length; index++) {
        const item = response.plans[index];
        verifyPlanField(item.NOME, index, "É preciso informar o nome do plano")
        verifyPlanField(item.HORARIO_INICIO, index, "É preciso informar o inicio do plano: ##:##")
        verifyPlanField(item.HORARIO_FIM, index, "É preciso informar o horario fim do plano: ##:##")
        verifyPlanField(item.VALOR, index, "É preciso informar o valor do plano")
    }
}

const validateMonthlies = (response) => {
    for (let index = 0; index < response.monthlies.length; index++) {
        const item = response.monthlies[index];
        const planSelected = response.plans.filter(e => e.NOME === item.PLANO)
        if (planSelected === 0) {
            verifyMonthlyField("", index, `É preciso informar um nome do plano válido para o mensalista: ${item.NOME_DO_CLIENTE}`)
        }
        verifyMonthlyField(item.NOME_DO_CLIENTE, index, "É preciso informar o nome do mensalista")
        verifyMonthlyField(item.DATA_PRIMEIRA_FATURA, index, "É preciso informar a data da primeira fatura")
        verifyMonthlyField(item.DIA_VENCIMENTO, index, "É preciso informar o dia de vencimento")
        verifyMonthlyField(item.PLACA_VEICULO, index, "É preciso informar a placa do veículo")
    }
}

const validateClient = (response) => {
    if (response.park.length === 1) {
        const park = response.park[0]
        verifyParkField(park.NOME_REPRESENTANTE, 0, "É preciso informar o nome do representante")
        if (park.EMAIL_LOGIN === undefined || park.EMAIL_LOGIN === "") {
            verifyParkField(0, "É preciso informar um e-mail para login do representante")
        } else {
            var re = /\S+@\S+\.\S+/;
            if (re.test(park.EMAIL_LOGIN)) {
                verifyParkField(0, "É preciso informar um e-mail válido para login.")
            }
        }
        verifyParkField(park.NOME_ESTACIONAMENTO, 0, "É preciso informar o nome do estacionamento")
        verifyParkField(park.HORARIO_FUNCIONAMENTO, 0, "É preciso informar o horário de funcionamento")
        const document = (park.DOCUMENTO_OPCIONAL ?? "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
        verifyParkField(document, "É preciso um documento válido quando preenchido: ###.###.###-## ou ##.###.###/####-##")
        const phone = (park.CELULAR_OPCIONAL ?? "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        verifyParkField(phone, 0, "É preciso um número de telefone válido com o DDD quando preenchido: (##)####-#### ou (##)#####-####")
        const cep = (park.CEP_OPCIONAL ?? "").replaceAll("-", "")
        verifyParkField(cep, 0, "É preciso um CEP válido quando preenchido: ######-###")
        verifyParkField(park.NUMERO_OPCIONAL, 0, "É preciso um número do endereço válido quando o CEP for preenchido")
    }
}

const getRowsFrom = (response, type) => {
    var filtered = response.filter(e => e.type === type)
    if (filtered !== null && filtered[0].data.length > 0) {
        return filtered[0].data
    }
    return []
}

const verifyPlanField = (field, row, message) => {
    if (field === undefined || field === "") {
        addError("PLANOS_MENSALISTA", row, message)
    }
}

const verifyParkField = (field, row, message) => {
    if (field === undefined || field === "") {
        addError("ESTACIONAMENTO", row, message)
    }
}

const verifyMonthlyField = (field, row, message) => {
    if (field === undefined || field === "") {
        addError("MENSALISTAS", row, message)
    }
}

const verifyRelationshipField = (field, row, message) => {
    if (field === undefined || field === "") {
        addError("CONVENIOS", row, message)
    }
}

const verifyWayPaymentField = (field, row, message) => {
    if (field === undefined || field === "") {
        addError("FORMAS_DE_PAGAMENTO", row, message)
    }
}

const addError = (type, row, message) => {
    errors.push({
        row: row,
        type: type,
        message: message
    })
}