import React from 'react'
import { Link } from 'react-router-dom';
import { EmptyContent, HelpButton, Loading } from '../../components/common/commons'
import 'firebase/auth';
import 'firebase/storage';
import { Tabs, Tab } from 'react-bootstrap';
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { TicketAnalyticsDialog, TicketSimplyList } from '../buscas/ticketSearch'
import { getEstacionamento, getUsuario, getCliente, getUsuarioCompact, getRevendaIdByPark } from "../../config/auth"
import { createCashier, activeCashier, lastCashier, getAllCashiers, getCashierById, updateCashier, closeCashier } from "../../store/collections/cashierWorker"
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { saveLastUpdate, saveLastUpdateNoSkip } from '../../store/collections/lastUpdateWorker';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Tooltip from '@mui/material/Tooltip';
import { exportAndDownloadPDF, toCurrency, generateUUIDV7, goToNewWindow, queryString, reloadWindow, setPathWindow, secondsToDescription, reloadWithAlert, toastWarning, reloadWithAlertError, takeIfIsNotNullOrEmpty, toastSuccess, toastLoading, toastDismissLoading } from '../../shared/utils';
import { sendClickButton, sendLog, sendLogByUser } from '../../shared/analytics';
import { getTicketsByCashierId, getTicketsOpenByParkId, updateTicket } from '../../store/collections/ticketWorker';
import { getParameter, getParameterByParkId } from '../../store/collections/parameterWorker';
import { calculateTotalMergedOfTicketAndMonthlyFromCashierId, generateRelationship } from '../../store/collections/cashier';
import { resetPrism } from '../../store/collections/prismWorker';
import { getAllMovementsCategoriesCashiers, createMovementCategoryCashier, getMovementsCategoryCashierByName, deleteMovementCategoryCashier } from '../../store/collections/cashierMovementCategoryWorker';
import { getAllParksByClientId } from '../../store/collections/parkWorker';
import { AlertDanger, AlertWarning } from '../../shared/alert-manager';
import { CashierReportDocument } from '../../shared/pdf/cashier-report';
import { fetchFunction } from '../../shared/function-request';
import { PrinterCashierComponent } from '../../shared/printer/printer-ticket.js';
import ReactToPrint from 'react-to-print'
import { TicketStatus } from '../../shared/constants.js';


class CashierList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            caixas: [],
            tickets: [],
            isFromSearch: false,
            cashiersSelecteds: [],
            key: 'caixasanalisados'
        }
    }

    componentDidMount = async () => {
        if (this.props.resultado.caixas !== null) {
            const parks = await getAllParksByClientId({ clientId: getCliente()?.id })
            this.setState({ parks: parks })
            this.setState({ caixas: this.props.resultado.caixas.sort((a, b) => parseInt(b.sequence ?? 0) - parseInt(a.sequence ?? 0)) })
            const ids = this.props.resultado.caixas.map(e => e.id)
            this.setState({ cashiersSelecteds: ids })
            const tickets = this.props.resultado.caixas.flatMap(e => e.tickets)
            this.setState({ tickets: tickets })
        }
    }

    statusCashier = (item) => {
        if (item.statusFechamento === "Bateu" || item.statusFechamento === "Cego") {
            return "btn btn-success"
        }
        if (item.statusFechamento === "Sobrou") {
            return "btn btn-warning text-white"
        }
        if (item.statusFechamento === "Faltou") {
            return "btn btn-danger"
        }
        return "btn btn-secondary"
    }

    statusTitleCashier = (item) => {
        if (item.status === "fechado") {
            return item.statusFechamento
        }
        return "Este caixa ainda esta aberto."
    }


    showAnalyticsModal = () => {
        this.setState({ isShowingAnalyticsModal: true })
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ isShowingAnalyticsModal: false })
    }

    onCheckedCashier = (e, index) => {
        if (e.target.checked) {
            this.state.cashiersSelecteds[index] = this.state.caixas[index].id
            this.setState({ cashiersSelecteds: this.state.cashiersSelecteds })
        } else {
            this.state.cashiersSelecteds[index] = null
            this.setState({ cashiersSelecteds: this.state.cashiersSelecteds })
        }
        if (this.props.onCashiersSelected) {
            const ids = this.state.cashiersSelecteds.filter(f => f !== null)
            this.props.onCashiersSelected(ids)
        }
    }

    render() {
        return (
            <>
                {
                    this.state.caixas.length > 0 &&
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className='col-lg-12'>
                                <div className='alert alert-success'>
                                    Aproveite e faça uma analise dos dados dos veículos lançados. <p />
                                    <button type="button" onClick={this.showAnalyticsModal} className="btn btn-success">
                                        <QueryStatsIcon />
                                        <span className='mx-2'>Analisar dados</span>
                                    </button>
                                </div>
                            </div>
                            <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                                <Tab eventKey="caixasanalisados" title="Caixas analisados">
                                    <div className='row'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10} />
                                                        <td width={10} />
                                                        <td width="70" align="center"><strong>Caixa</strong></td>
                                                        <td><strong>Estabelecimento</strong></td>
                                                        <td><strong>Abertura</strong></td>
                                                        <td><strong>Fechamento</strong></td>
                                                        <td align='right'><strong>Faturamento</strong></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.caixas.map((item, index) =>
                                                            <tr key={index}>
                                                                <td width={10}>
                                                                    <input className="form-check-input mt-2" checked={this.state.cashiersSelecteds[index] === item.id} onChange={(e) => this.onCheckedCashier(e, index)} type="checkbox" />
                                                                </td>
                                                                <td width='10'>
                                                                    <Tooltip title={this.statusTitleCashier(item)} placement="right">
                                                                        <a href={`/caixas/${item.id}`} target='_blank'>
                                                                            <button type="button" className={this.statusCashier(item)}>
                                                                                <i className="fas fa-eye" />
                                                                            </button>
                                                                        </a>
                                                                    </Tooltip>
                                                                </td>
                                                                {
                                                                    (item.sequence !== undefined && item.sequence !== null) ? <td align="center">{item.sequence}</td> : <td align="center"> - </td>
                                                                }
                                                                <td>
                                                                    {this.state.parks.filter(e => e.id === item.estacionamentoId)[0]?.nomeInterno}
                                                                </td>
                                                                {
                                                                    <td>{Moment(item.openAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                }
                                                                {
                                                                    <td>{item.closeAt ? Moment(item.closeAt.toDate()).format("DD/MM/YYYY [às] HH:mm A") : "Caixa em aberto"}</td>
                                                                }
                                                                <td align='right'>
                                                                    <strong className='text-success'>{toCurrency(item.totais.faturamentoLiquido)}</strong>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="tickets" title="Tickets">
                                    {
                                        this.state.tickets.length === 0 ? <EmptyContent text="Não existem lançamentos de veículos para este caixa" /> :
                                            <div className='col-lg-12'>
                                                <TicketSimplyList key={this.state.random} tickets={this.state.tickets} />
                                            </div>
                                    }
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                }
                {
                    this.state.isShowingAnalyticsModal && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                }
            </>)
    }
}

class CashierSelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            estacionamentoId: getEstacionamento()?.id,
            allCashiers: [],
            selectedId: props.selectedId,
            cashier: null
        }
        this.load()
    }

    load = async () => {
        const currentCashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
        if (currentCashier !== null) {
            this.setState({ cashier: currentCashier })
            if (this.props.onSelectedCashier) {
                this.props.onSelectedCashier(currentCashier)
            }
            this.setState({ isLoading: false })
        } else {
            this.loadAllCashiers()
        }
    }

    loadAllCashiers = async () => {
        this.setState({ isLoading: true })
        var allCashiers = await getAllCashiers({ estacionamentoId: this.state.estacionamentoId })
        if (allCashiers.length > 0) {
            if (this.state.selectedId) {
                const cashier = await getCashierById({ id: this.state.selectedId })
                allCashiers.push(cashier)
                this.setState({ cashier: cashier })
                if (this.props.onSelectedCashier) {
                    this.props.onSelectedCashier(cashier)
                }
            } else {
                this.setState({ cashier: allCashiers[0] })
                if (this.props.onSelectedCashier) {
                    this.props.onSelectedCashier(allCashiers[0])
                }
            }
            this.setState({ allCashiers: allCashiers })
            this.setState({ isLoading: false })
        }
    }

    selectCashier = (e) => {
        const cashiers = this.state.allCashiers.filter(f => f.id === e.target.value)
        if (cashiers.length > 0) {
            const cashier = cashiers[0]
            this.setState({ cashier: cashier })
            if (this.props.onSelectedCashier) {
                this.props.onSelectedCashier(cashier)
            }
        }
    }

    render() {
        return (
            <div className={!this.state.cashier ? "alert alert-success" : this.state.cashier?.status?.toUpperCase() === "ABERTO" ? "alert alert-success" : "alert alert-danger"}>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading && <>
                        <div className="col-lg-12">
                            <strong>{this.props.title}</strong>
                            {
                                this.state.allCashiers?.length > 0 &&
                                <div className='w-100'>
                                    <select className='form-select' value={this.state.cashier?.id} onChange={(e) => this.selectCashier(e)}>
                                        {
                                            this.state.allCashiers?.map(item => { return <option value={item.id}>{item.sequence} - {Moment(item.openAt?.toDate()).format("DD/MM/YYYY")}</option> })
                                        }
                                    </select>
                                </div>
                            }
                        </div>
                        {
                            this.state.cashier &&
                            <>
                                <strong>Caixa: </strong><span>{this.state.cashier.sequence}</span>
                                <Tooltip title="Visualizar caixa" placement="top">
                                    <a href={`/caixas/${this.state.cashier.id}`} target="_blank">
                                        <i className="fas fa-eye mx-2" />
                                    </a>
                                </Tooltip>
                                <br />
                                <strong>Status: </strong><span>{this.state.cashier.status.toUpperCase()}</span> <br />
                                <strong>Aberto em: </strong><span>{Moment(this.state.cashier.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</span> <br />
                                <strong>Aberto por: </strong><span>{this.state.cashier.openBy.nome}</span> <br />
                            </>
                        }
                        {
                            this.state.allCashiers?.length === 0 &&
                            <button type="button" className="btn btn-success btn-sm mt-2" onClick={this.loadAllCashiers}>
                                <i className="fas fa-tasks mx-2" />
                                Carregar todos os caixas
                            </button>
                        }
                    </>
                }
            </div>)
    }
}

class CashierTotals extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            idCashier: window.location.pathname.split("/")[2],
            resultado: null,
            key: "totais",
            clienteId: getCliente()?.id,
            estacionamentoId: getEstacionamento()?.id,
            isShowingWithdrawModal: false,
            isShowingPrintReportModal: false,
            isShowingWithdrawLoading: false,
            impressaoTabelasUtilizadasCaixas: true,
            impressaoCaixaTotais: true,
            impressaoConveniosCaixas: true,
            impressaoDescontosCaixas: true,
            impressaoListagemPagamentosPorCaixas: true,
            impressaoListagemMensalistasCaixas: true,
            impressaoListagemTicketsCaixas: true,
            impressaoListagemProdutosCaixas: true,
            impressaoMovimentacoesCaixas: true,
            impressaoRetiradasCaixas: true,
            impressaoCancelamentosCaixas: true,
            impressaoCancelamentosCaixa: false,
            valor: 0.0,
            cashier: null
        }
    }

    componentDidMount = async () => {
        const tab = queryString("tab")
        this.changeTab(tab ?? "totais")
        if (this.props.resultado) {
            this.setState({ resultado: this.props.resultado })
        }
        //Caso seja um caixa apenas
        var cashier = await getCashierById({ id: this.state.idCashier })
        if (cashier) {
            this.setState({ cashier: cashier })
        }
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameters: parameters })

    }

    onBack = () => {
        window.history.go(-1)
    }

    hideWithdrawModal = () => {
        this.setState({ isShowingWithdrawModal: false })
    }

    showWithdrawModal = () => {
        this.setState({ isShowingWithdrawModal: true })
        this.setState({ isShowingWithdrawLoading: false })
        sendClickButton("Caixa", "Visualizou o modal de registrar retirada")
    }

    syncWithF360 = async () => {
        const cashiers = this.state.resultado.caixas.filter(e => e.movements?.length > 0)
        var message = ""
        if (cashiers.length > 1) {
            message = `Deseja mesmo sincronizar as movimentações dos ${cashiers.length} caixas com a F360?`
        } else {
            message = `Deseja mesmo sincronizar as movimentações do caixa ${cashiers[0].sequence} com a F360?`
        }
        const confirm = window.confirm(message);
        if (confirm) {
            toastSuccess("Sincronizando com a F360...")
            for (const cashier of cashiers) {
                const option = {
                    method: "POST",
                    body: {
                        ids: [cashier.id]
                    }
                };
                await fetchFunction(`/F360/resync/cashiers/ids`, option)
            }
            sendLogByUser("Caixa", "Sincronizou manualmente as movimentações de caixa com a F360.", cashiers)
        }
    }

    showMovimentsModal = () => {
        this.setState({ isShowingMovimentsModal: true })
        sendClickButton("Caixa", "Visualizou o modal de registrar movimentação")
    }

    registerWithdraw = async () => {
        if (this.state.valor === 0.0) {
            toastWarning("Informe o valor da retirada.")
            return
        }
        this.setState({ isShowingWithdrawLoading: true })
        var confirm = window.confirm(`Confirma mesmo o registro desta retirada?`);
        if (confirm) {
            const id = window.location.pathname.split("/")[2]
            var cashier = await getCashierById({ id: id })
            if (cashier.clienteId === this.state.clienteId) {
                const user = getUsuarioCompact()
                if (!cashier.retiradas) {
                    cashier.retiradas = []
                }
                cashier.retiradas.push({
                    comment: this.state.comment ?? "",
                    createAt: new Date(),
                    id: generateUUIDV7(),
                    price: this.state.valor,
                    user: user,
                    deleted: false
                })
                await updateCashier(cashier.id, cashier)
                await saveLastUpdateNoSkip()
                const message = `Registrou uma retirada no caixa ${cashier.sequence}.`
                sendLogByUser("Caixa", message, cashier)
                toastSuccess("Retirada registrado com sucesso no caixa!")
            } else {
                toastWarning("Você não tem permissão para fazer esta ação.")
            }
            reloadWindow()
        }
    }

    changeTab = (tab) => {
        const startDate = queryString("startDate")
        const endDate = queryString("endDate")
        const estacionamentosIds = queryString("es")
        const from = queryString("from")
        if (from) {
            this.setState({ isFromSearch: from === "search" })
        }
        const ticket = queryString("ticket")
        const pathname = `${window.location.pathname}?tab=${tab}${estacionamentosIds ? `&es=${estacionamentosIds}` : ""}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}${ticket ? `&ticket=${ticket}` : ""}`
        setPathWindow(pathname)
        this.setState({ key: tab })
        const sequence = this.state.cashier?.sequence
        if (sequence) {
            const message = `Visualizou a aba ${tab} do caixa ${sequence}.`
            sendLogByUser("Caixa", message)
        }
    }

    showProductSallesReport = () => {
        const message = `Tocou no botão de relatório de vendas completo.`
        sendLogByUser("Caixa", message)
        const cashiers = this.state.resultado.caixas.map(e => e.id).join(",")
        goToNewWindow(`/relatorios/vendas?ids=${cashiers}`)
    }

    updateValor = (event, maskedvalue, floatvalue) => {
        this.setState({ valor: floatvalue })
    }

    onRemoveMovement = async (item) => {
        var confirm = window.confirm(`Confirma mesmo a remoção desta movimentação?`);
        if (confirm) {
            var cashier = await getCashierById({ id: item.cashierId })
            const message = `Removeu uma movimentação do caixa caixa ${cashier.sequence}.`
            cashier.movements = cashier.movements.filter(e => e.id !== item.id)
            sendLogByUser("Caixa", message, cashier)
            await updateCashier(cashier.id, cashier)
            reloadWindow()
        }
    }

    onRemoveWithdrawal = async (item) => {
        var confirm = window.confirm(`Confirma mesmo a remoção desta retirada?`);
        if (confirm) {
            var cashier = await getCashierById({ id: item.cashierId })
            const user = getUsuarioCompact()
            const message = `Remover uma retirada do caixa caixa ${cashier.sequence}.`
            cashier.retiradas = cashier.retiradas.filter(e => e.id !== item.id)
            await updateCashier(cashier.id, cashier)
            sendLogByUser("Caixa", message, cashier)
            reloadWindow()
        }
    }

    hideShowingPrintReportModal = () => {
        this.setState({ isShowingPrintReportModal: false })
    }

    showShowingPrintReportModal = () => {
        this.setState({ isShowingPrintReportModal: true })
    }

    confirmPrint = async (e) => {
        toastLoading("Gerando relatório em PDF...")
        const message = `Exportou em PDF um relatório do caixa.`
        sendLogByUser("Caixa", message, this.state.resultado)
        e.preventDefault();
        const doc = <CashierReportDocument
            resultado={this.state.resultado}
            impressaoCaixaTotais={this.state.impressaoCaixaTotais}
            impressaoListagemMensalistasCaixas={this.state.impressaoListagemMensalistasCaixas}
            impressaoTabelasUtilizadasCaixas={this.state.impressaoTabelasUtilizadasCaixas}
            impressaoConveniosCaixas={this.state.impressaoConveniosCaixas}
            impressaoDescontosCaixas={this.state.impressaoDescontosCaixas}
            impressaoMovimentacoesCaixas={this.state.impressaoMovimentacoesCaixas}
            impressaoRetiradasCaixas={this.state.impressaoRetiradasCaixas}
            impressaoCancelamentosCaixas={this.state.impressaoCancelamentosCaixas}
        />
        await exportAndDownloadPDF(doc, 'relatorio_caixa.pdf')
        toastDismissLoading()
    }

    exportData = () => {
        // toastWarning("Em desenvolvimento")
        // if (this.state.ticketsCompleted.length === 0) {
        //     return
        // }
        // const revenda = getRevenda()
        // const rows = this.state.ticketsCompleted.map(e => {
        //     const payments = e.paymentsLogged.filter(e => e.canceled !== true)
        //     return {
        //         "TIPO": e.type === "SALES" ? "Venda" : e.monthly ? "Mensalista" : "Avulso",
        //         "PLACA": e.placa ?? "-",
        //         "TICKET": e.ticket,
        //         "MODELO": e.vehicler?.modelo?.toUpperCase() ?? "-",
        //         "ENTRADA": Moment(e.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm"),
        //         "SAÍDA": e.closedAt ? Moment(e.closedAt.seconds * 1000).format("DD/MM/YYYY HH:mm") : "-",
        //         "PERMANÊNCIA": e.closedAt ? this.secondsToHms(((e.closedAt.seconds - e.createAt.seconds))) : "-",
        //         "OPERADOR": payments.map(e => e.user.nome).join(","),
        //         "PAGAMENTO": payments.map(e => e.formaPagamento?.nome ?? "").join(","),
        //         "CONVÊNIO": payments.map(e => e.resumo?.convenio?.nome ?? "").join(","),
        //         "TABELA DE PREÇO": payments.map(e => e.resumo?.tabelaPreco?.nome ?? "").join(","),
        //         "VALOR ESTACIONADO": payments.map(e => e.resumo?.valorEstacionamento ?? 0).reduce((a, b) => a + b, 0),
        //         "VALOR DESCONTO": -(payments.map(e => e.resumo?.valorDesconto ?? 0).reduce((a, b) => a + b, 0)),
        //         "VALOR PRODUTOS": (payments.map(e => e.resumo?.valorProdutos ?? 0).reduce((a, b) => a + b, 0)) + (payments.map(e => e.resumo?.valorServicos ?? 0).reduce((a, b) => a + b, 0)),
        //         "VALOR COBRADO": payments.map(e => e.resumo?.valorCobrado ?? 0).reduce((a, b) => a + b, 0),
        //         "DETALHES": `${revenda.siteAdm}/buscar/ticket?id=${e.id}`
        //     }
        // })
        // exportAndDownloadXLS(rows, `VEICULOS_POR_PERIODO`)
        // sendClickButton("Relatório", "Tocou em exportar dados", null, JSON.stringify(rows))
    }

    render() {
        return (
            <>
                {
                    this.state.resultado ? <>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                {
                                    this.state.isFromSearch &&
                                    <button type="button" onClick={() => { this.onBack() }} className="btn btn-sm btn-secondary">
                                        <i className="fas fa-chevron-left mx-2" />
                                        Voltar
                                    </button>
                                }
                                <span className='mx-2'> Resumo do faturamento de {this.state.resultado.caixas.length} {this.state.resultado.caixas.length === 1 ? "caixa" : "caixas"} </span>
                                <div className='btn-group'>
                                    <button onClick={this.showShowingPrintReportModal} className='btn btn-sm btn-secondary'>
                                        <i className="fas fa-print mx-2" />
                                        Imprimir
                                    </button>
                                    <HelpButton />
                                </div>
                            </div>
                            <Dialog onClose={this.hideShowingPrintReportModal} fullWidth maxWidth='xs' open={this.state.isShowingPrintReportModal}>
                                <MuiDialogTitle className='text-center'>
                                    Impressão do relatório
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <AlertWarning message="Escolha quais dados deseja imprimir no relatório." />
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoCaixaTotais" checked={this.state.impressaoCaixaTotais} onChange={(e) => this.setState({ impressaoCaixaTotais: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoCaixaTotais">
                                            Totais do caixa
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoTabelasUtilizadasCaixas" checked={this.state.impressaoTabelasUtilizadasCaixas} onChange={(e) => this.setState({ impressaoTabelasUtilizadasCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoTabelasUtilizadasCaixas">
                                            Tabelas utilizadas
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoConveniosCaixas" checked={this.state.impressaoConveniosCaixas} onChange={(e) => this.setState({ impressaoConveniosCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoConveniosCaixas">
                                            Convênios
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoDescontosCaixas" checked={this.state.impressaoDescontosCaixas} onChange={(e) => this.setState({ impressaoDescontosCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoDescontosCaixas">
                                            Descontos manuais
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoListagemMensalistasCaixas" checked={this.state.impressaoListagemMensalistasCaixas} onChange={(e) => this.setState({ impressaoListagemMensalistasCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoListagemMensalistasCaixas">
                                            Pagamentos dos mensalistas
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoMovimentacoesCaixas" checked={this.state.impressaoMovimentacoesCaixas} onChange={(e) => this.setState({ impressaoMovimentacoesCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoMovimentacoesCaixas">
                                            Movimentações no caixa
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoRetiradasCaixas" checked={this.state.impressaoRetiradasCaixas} onChange={(e) => this.setState({ impressaoRetiradasCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoRetiradasCaixas">
                                            Retiradas do caixa
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="impressaoCancelamentosCaixas" checked={this.state.impressaoCancelamentosCaixas} onChange={(e) => this.setState({ impressaoCancelamentosCaixas: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="impressaoCancelamentosCaixas">
                                            Cancelamentos de tickets
                                        </label>
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.hideShowingPrintReportModal()} className='btn btn-secondary'>Cancelar</button>
                                        <>
                                            <div style={{ display: "none" }}>
                                                <PrinterCashierComponent
                                                    park={getEstacionamento()}
                                                    resultado={this.state.resultado}
                                                    impressaoCaixaTotais={this.state.impressaoCaixaTotais}
                                                    impressaoListagemMensalistasCaixas={this.state.impressaoListagemMensalistasCaixas}
                                                    impressaoTabelasUtilizadasCaixas={this.state.impressaoTabelasUtilizadasCaixas}
                                                    impressaoConveniosCaixas={this.state.impressaoConveniosCaixas}
                                                    impressaoDescontosCaixas={this.state.impressaoDescontosCaixas}
                                                    impressaoMovimentacoesCaixas={this.state.impressaoMovimentacoesCaixas}
                                                    impressaoRetiradasCaixas={this.state.impressaoRetiradasCaixas}
                                                    impressaoCancelamentosCaixas={this.state.impressaoCancelamentosCaixas}
                                                    ref={el => (this.componentRef = el)} />
                                            </div>
                                            <ReactToPrint
                                                trigger={() => {
                                                    return <button className='btn btn-success'>Impressora</button>
                                                }}
                                                content={() => this.componentRef}
                                            />
                                        </>
                                        <button onClick={(e) => this.confirmPrint(e)} className='btn btn-success'>PDF</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <div className="card-body">
                                <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeTab(tab)}>
                                    <Tab eventKey="totais" title="Totais">
                                        <div className='row my-4'>
                                            <Tooltip title="Total cobrado de estacionamento" placement="top">
                                                <div className="col-12 col-sm-6 col-lg-4">
                                                    <a onClick={() => this.changeTab("tickets")}>
                                                        <div className="card text-light bg-info mb-3" role="button">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-center text-white">Estacionamento</h5>
                                                                <h3 className="card-text text-center text-white">
                                                                    {toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}
                                                                </h3>
                                                                <div className="text-center">
                                                                    <small className="card-text text-white">Total</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </Tooltip>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <a onClick={() => this.changeTab("produtos")}>
                                                    <div className="card text-light bg-info mb-3" role="button">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center text-white">Produtos</h5>
                                                            <h3 className="card-text text-center text-white">
                                                                {toCurrency(this.state.resultado.totais.tickets.valorProdutos)}
                                                            </h3>
                                                            <div className="text-center">
                                                                <small className="card-text text-white">Total em produtos e serviços</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <a onClick={() => this.changeTab("mensalistas")}>
                                                    <div className="card text-light bg-info mb-3" role="button">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center text-white">Mensalistas</h5>
                                                            <h3 className="card-text text-center text-white">
                                                                {toCurrency(this.state.resultado.totais.monthly)}
                                                            </h3>
                                                            <div className="text-center">
                                                                <small className="card-text text-white">Total de mensalistas pagos</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <a onClick={() => this.changeTab("tickets")}>
                                                    <div className="card text-light bg-info mb-3" role="button">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center text-white">Tickets</h5>
                                                            <h3 className="card-text text-center text-white">
                                                                {this.state.resultado.totais.ticketsLancados}
                                                            </h3>
                                                            <div className="text-center">
                                                                <small className="card-text text-white">Média: {secondsToDescription(this.state.resultado.totais.ticketsLancadosPermanenciaMedia)}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <a onClick={() => this.changeTab("tickets")}>
                                                    <div className="card text-light bg-success mb-3" role="button">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center text-white">Ticket médio</h5>
                                                            <h3 className="card-text text-center text-white">
                                                                {
                                                                    this.state.resultado.totais.ticketMedio === Infinity ?
                                                                        <>{toCurrency(0)}</> :
                                                                        <>{toCurrency(this.state.resultado.totais.ticketMedio)}</>
                                                                }
                                                            </h3>
                                                            <div className="text-center">
                                                                <small className="card-text text-white">Faturamento médio</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <a onClick={() => this.changeTab("movimentacoes")}>
                                                    <div className="card text-light bg-secondary mb-3" role="button">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center text-white">Movimentações</h5>
                                                            <h3 className="card-text text-center text-white">
                                                                {this.state.resultado.movements.length}
                                                            </h3>
                                                            <div className="text-center">
                                                                {
                                                                    this.state.resultado.movements.length === 0 && <small className="card-text text-white">Nenhuma movimentação</small>
                                                                }
                                                                {
                                                                    this.state.resultado.movements.length > 0 &&
                                                                    <small className="card-text text-white">
                                                                        <span className="personalized-card">
                                                                            {this.state.resultado.totais.movements.earn > 0 &&
                                                                                <span className='text-success'>
                                                                                    +{toCurrency(this.state.resultado.totais.movements.earn)}
                                                                                </span>
                                                                            }
                                                                            {(this.state.resultado.totais.movements.earn !== 0 && this.state.resultado.totais.movements.spends !== 0) && <span className='text-secondary'> | </span>}
                                                                            {this.state.resultado.totais.movements.spends > 0 &&
                                                                                <span className='text-danger'>
                                                                                    -{toCurrency(this.state.resultado.totais.movements.spends)}
                                                                                </span>
                                                                            }
                                                                        </span>
                                                                    </small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <a onClick={() => this.changeTab("retiradas")}>
                                                    <div className="card text-light bg-danger mb-3" role="button">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-center text-white">Retiradas</h5>
                                                            <h3 className="card-text text-center text-white">
                                                                {this.state.resultado.retiradas.length}
                                                            </h3>
                                                            <div className="text-center">
                                                                {
                                                                    this.state.resultado.retiradas.length === 0 && <small className="card-text text-white">Nenhuma retirada efetuada</small>
                                                                }
                                                                {
                                                                    this.state.resultado.retiradas.length === 1 && <small className="card-text text-white">Retirada efetuada</small>
                                                                }
                                                                {
                                                                    this.state.resultado.retiradas.length > 1 && <small className="card-text text-white">Retiradas efetuadas</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            {
                                                this.state.resultado.totais.tickets.valorDesconto !== 0 &&
                                                <div className="col-12 col-sm-6 col-lg-4">
                                                    <a onClick={() => this.changeTab("descontos")}>
                                                        <div className="card text-light bg-danger mb-3" role="button">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-center text-white">Descontos</h5>
                                                                <h3 className="card-text text-center text-white">
                                                                    {toCurrency(-this.state.resultado.totais.tickets.valorDesconto)}
                                                                </h3>
                                                                <div className="text-center">
                                                                    <small className="card-text text-white">Convênios ou descontos manual</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            }
                                            {

                                                <div className="col-12 col-sm-6 col-lg-4">
                                                    <a onClick={() => this.changeTab("cancelados")}>
                                                        <div className="card text-light bg-danger mb-3" role="button">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-center text-white">Cancelados</h5>
                                                                <h3 className="card-text text-center text-white">
                                                                    <NumberFormat value={this.state.resultado.ticketsCanceled.length} displayType={'text'} />
                                                                </h3>
                                                                <div className="text-center">
                                                                    <small className="card-text text-white">Tickets cancelados</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            }
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <div className="card text-light bg-success mb-3" role="button">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-center text-white">Faturamento bruto</h5>
                                                        <h3 className="card-text text-center text-white">
                                                            {toCurrency(this.state.resultado.totais.faturamento)}
                                                        </h3>
                                                        <div className="text-center">
                                                            <small className="card-text text-white">Tickets + Mensalistas</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <div className="card text-light bg-success mb-3" role="button">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-center text-white">Faturamento líquido</h5>
                                                        <h3 className="card-text text-center text-white">
                                                            {toCurrency(this.state.resultado.totais.faturamentoLiquido)}
                                                        </h3>
                                                        <div className="text-center">
                                                            <small className="card-text text-white">Bruto - Descontos</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="pagamentos" title="Pagamentos">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        {
                                                            this.state.resultado?.totais?.tickets?.byWayPayment.length > 0 ?
                                                                <>
                                                                    <div className="alert alert-secondary" role="alert">
                                                                        Soma de pagamentos de tickets e mensalistas.
                                                                    </div>
                                                                    <div className='row'>
                                                                        {
                                                                            this.state.resultado?.totais?.tickets?.byWayPayment.map((item, index) =>
                                                                                <div className="col-12 col-sm-6 col-lg-4" key={"forma_pagamento_recebido_" + index}>
                                                                                    <div className="card text-light bg-info mb-3" role="button">
                                                                                        <div className="card-body">
                                                                                            <h5 className="card-title text-center text-white">{item.wayPayment?.nome ?? ""}</h5>
                                                                                            <h3 className="card-text text-center text-white">
                                                                                                {toCurrency(item.total)}
                                                                                            </h3>
                                                                                            <div className="text-center">
                                                                                                <h6 className="card-text text-white">Valor cobrado</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </> :
                                                                <EmptyContent text="Não existem movimentações neste caixa" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tickets" title="Tickets">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                <div className="alert alert-secondary" role="alert">
                                                    Pagamentos de veículos, produtos e descontos por convênio ou manual.
                                                </div>
                                                <div className='table-responsive'>
                                                    {
                                                        this.state.resultado?.totais?.ticketsPaymentsWay.length === 1 ?
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Forma de pagamento</strong></td>
                                                                        <td align='right'><strong>Estacionamento</strong></td>
                                                                        <td align='right'><strong>Descontos</strong></td>
                                                                        <td align='right'><strong>Produtos</strong></td>
                                                                        <td align='right'><strong>Valor cobrado</strong></td>
                                                                    </tr>
                                                                    {
                                                                        this.state.resultado?.totais?.ticketsPaymentsWay[0].allRevenues?.map((item, indexcashier) =>
                                                                            <tr key={"tickets_payments_cashier_" + indexcashier}>
                                                                                <td>{item.wayPayment.nome}</td>
                                                                                <td align='right'>{toCurrency(item.ticket.valorEstacionamento)}</td>
                                                                                <td align='right'>
                                                                                    {
                                                                                        (item.ticket.valorDesconto ?? 0) > 0 ?
                                                                                            <span className='text-danger'>{toCurrency(-item.ticket.valorDesconto)}</span> :
                                                                                            <span>{toCurrency(0)}</span>
                                                                                    }
                                                                                </td>
                                                                                <td align='right'>{toCurrency(item.ticket.valorProdutos)}</td>
                                                                                <td align='right'>{toCurrency(item.ticket.valorCobrado)}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    {
                                                                        <tr>
                                                                            <td><h6>Sub-Total</h6></td>
                                                                            <td align='right'><h4 className='text-success'>{toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}</h4></td>
                                                                            <td align='right'>
                                                                                {
                                                                                    (this.state.resultado.totais.tickets.valorDesconto ?? 0) > 0 ?
                                                                                        <h4 className='text-danger'>{toCurrency(-this.state.resultado.totais.tickets.valorDesconto)}</h4> :
                                                                                        <h4>{toCurrency(0)}</h4>
                                                                                }
                                                                            </td>
                                                                            <td align='right'><h4 className='text-success'>{toCurrency(this.state.resultado.totais.tickets.valorProdutos)}</h4></td>
                                                                            <td align='right'><h4 className='text-success'>{toCurrency(this.state.resultado.totais.tickets.valorCobrado)}</h4></td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table> :
                                                            <>
                                                                {
                                                                    this.state.resultado?.totais?.ticketsPaymentsWay.length === 0 &&
                                                                    <EmptyContent text="Não existem movimentações neste caixa" />
                                                                }
                                                                {
                                                                    this.state.resultado?.totais?.ticketsPaymentsWay.length !== 1 &&
                                                                    <>
                                                                        {
                                                                            this.state.resultado?.totais?.ticketsPaymentsWay.map((cashier, index) =>
                                                                                <>
                                                                                    <h6 className='text-right'>Caixa: {cashier.sequence} | Aberto {Moment(cashier.openAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</h6>
                                                                                    <table className="table">
                                                                                        <tr>
                                                                                            <td><strong>Forma de pagamento</strong></td>
                                                                                            <td align='right'><strong>Estacionamento</strong></td>
                                                                                            <td align='right'><strong>Descontos</strong></td>
                                                                                            <td align='right'><strong>Produtos</strong></td>
                                                                                            <td align='right'><strong>Valor cobrado</strong></td>
                                                                                        </tr>
                                                                                        {
                                                                                            cashier.allRevenues.map((item, index) =>
                                                                                                <tr key={"tickets_payments_cashier_" + index}>
                                                                                                    <td>{item.wayPayment.nome}</td>
                                                                                                    <td align='right'>{toCurrency(item.ticket.valorEstacionamento)}</td>
                                                                                                    <td align='right'><span className={item.ticket.valorDesconto !== 0 ? 'text-danger' : ""}>{toCurrency(-item.ticket.valorDesconto)}</span></td>
                                                                                                    <td align='right'>{toCurrency(item.ticket.valorProdutos)}</td>
                                                                                                    <td align='right'>{toCurrency(item.ticket.valorCobrado)}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </table>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="produtos" title="Produtos">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                {
                                                    this.state.resultado.productsSold.length > 0 ?
                                                        <>
                                                            <div className="alert alert-success d-flex justify-content-between align-items-center" role="alert">
                                                                <div>
                                                                </div>
                                                                <button type="button" onClick={() => { this.showProductSallesReport() }} className="btn btn-sm btn-success text-white">
                                                                    <i className="fas fa-chart-pie mx-2"></i>
                                                                    Visualizar relatório de vendas
                                                                </button>
                                                            </div>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><strong>Nome</strong></td>
                                                                            <td align='center'><strong>Quantidade</strong></td>
                                                                            <td align='right'><strong>Valor Unit.</strong></td>
                                                                            <td align='right'><strong>Valor total</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.productsSold.map((item, index) =>
                                                                                <tr key={"produtos_vendidos_" + index}>
                                                                                    <td>{item.descricao}</td>
                                                                                    <td align='center'>{item.quantity}</td>
                                                                                    <td align='right'><strong className='text-success'>{toCurrency(item.unitPrice)}</strong></td>
                                                                                    <td align='right'><strong className='text-success'>{toCurrency(item.total)}</strong></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        <tr>
                                                                            <td colSpan={3}><h4>Sub-Total</h4></td>
                                                                            <td align='right'><h4 className='text-success'>{toCurrency(this.state.resultado.totais.tickets.valorProdutos)}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <EmptyContent text="Nenhum produto vendido nos tickets" />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="mensalistas" title="Mensalistas">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                {
                                                    this.state.resultado.mensalistas.length > 0 ?
                                                        <>
                                                            <div className="alert alert-secondary" role="alert">
                                                                Pagamentos de mensalistas.
                                                            </div>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width='10'></td>
                                                                            <td><strong>Nome</strong></td>
                                                                            <td><strong>Forma de pagamento</strong></td>
                                                                            <td><strong>Vencimento</strong></td>
                                                                            <td align='right'><strong>Valor cobrado</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.mensalistas.map((item, index) =>
                                                                                <tr key={index}>
                                                                                    <td width='10'>
                                                                                        <Link to={`/visualizar/mensalista/${item.monthlyId}?chargeId=${item.id}`} target="_blank">
                                                                                            <button type="button" className="btn btn-secondary">
                                                                                                <i className="fas fa-eye" />
                                                                                            </button>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{item.clienteNome}</td>
                                                                                    <td>{item.payment?.formaPagamento?.nome ?? "Não disponível"}</td>
                                                                                    <td>{Moment(item.changeDate.seconds * 1000).format("DD/MM/YYYY")}</td>
                                                                                    <td align='right'>{toCurrency(item.payment?.total ?? 0)}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        <tr>
                                                                            <td colSpan={3}><h4>Sub-Total</h4></td>
                                                                            <td align='right'><h4 className='text-success'>{toCurrency(this.state.resultado.totais.monthly)}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <EmptyContent text="Não existem movimentações de mensalistas" />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    {
                                        (this.state.resultado.descontoManual.length > 0 || this.state.resultado.convenios.length > 0) &&
                                        <Tab eventKey="descontos" title="Descontos">
                                            <div className='row my-4'>
                                                <div className='col-lg-12'>
                                                    {
                                                        this.state.resultado.descontoManual.length > 0 &&
                                                        <>
                                                            <div className="alert alert-secondary" role="alert">
                                                                Descontos feitos de forma manual
                                                            </div>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><strong>Usuário</strong></td>
                                                                            <td><strong>Motivo</strong></td>
                                                                            <td><strong>Data</strong></td>
                                                                            <td align='right'><strong>Valor</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.descontoManual.map((item, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{item.descontoManual?.user?.nome === null ? "Não informado" : item.descontoManual?.user?.nome}</td>
                                                                                    <td>{item.descontoManual?.reason === '' ? "Não informado" : item.descontoManual?.reason}</td>
                                                                                    {
                                                                                        item.createAt === undefined ?
                                                                                            <td>Não informado</td> :
                                                                                            <td>{Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                                    }
                                                                                    <td align='right'><strong className='text-danger'>{toCurrency(-item.descontoManual?.discount ?? 0)}</strong></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        <tr>
                                                                            <td colSpan={3}><h4>Sub-Total</h4></td>
                                                                            <td align='right'><h4 className='text-danger'>{toCurrency(-this.state.resultado.totais.descontoManual)}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        this.state.resultado.convenios.length > 0 ?
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    <div className="alert alert-secondary" role="alert">
                                                                        Convênios usados
                                                                    </div>
                                                                    <div className='table-responsive'>
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><strong>Nome</strong></td>
                                                                                    <td align='center'><strong>Vezes usado</strong></td>
                                                                                    <td align='right'><strong>Valor total</strong></td>
                                                                                </tr>
                                                                                {
                                                                                    this.state.resultado.convenios.map((item, index) =>
                                                                                        <tr key={"tabelas_usadas_" + index}>
                                                                                            <td>{item.convenio.resumo.convenio.nome}</td>
                                                                                            <td align='center'>{item.quantityUsed}</td>
                                                                                            <td align='right'><strong className='text-danger'>{toCurrency(-item.total)}</strong></td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                                <tr>
                                                                                    <td colSpan={2}><h4>Sub-Total</h4></td>
                                                                                    <td align='right'><h4 className='text-danger'>{toCurrency(-this.state.resultado.totais.convenios)}</h4></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <EmptyContent text="Nenhuma convênio utilizado" />
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    <Tab eventKey="movimentacoes" title="Movimentações">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                <div className="d-flex justify-content-between align-items-center my-2" role="alert">
                                                    <div></div>
                                                    <div className='btn-group'>
                                                        {
                                                            this.state.parameters?.habilitarF360ERP === true &&
                                                            <button type="button" onClick={() => { this.syncWithF360() }} className="btn btn-sm btn-danger text-white">
                                                                <i className="fas fa-sync mx-2"></i>
                                                                Syncronizar F360
                                                            </button>
                                                        }
                                                        {
                                                            this.state.resultado.caixas.length === 1 &&
                                                            <button type="button" onClick={() => { this.showMovimentsModal() }} className="btn btn-sm btn-success text-white">
                                                                <i className="fas fa-plus mx-2"></i>
                                                                Registrar movimentação
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.state.resultado.movements?.length > 0 ?
                                                        <>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            {
                                                                                this.state.resultado.caixas.length !== 1 && <td></td>
                                                                            }
                                                                            <td width={10}></td>
                                                                            <td><strong>Categoria</strong></td>
                                                                            <td><strong>Operador</strong></td>
                                                                            <td><strong>Comentário</strong></td>
                                                                            <td><strong>Data</strong></td>
                                                                            <td align='right'><strong>Valor</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.movements?.map((item, index) =>
                                                                                <>
                                                                                    <tr key={index} className={item.removed && "table-danger"}>
                                                                                        {
                                                                                            this.state.resultado.caixas.length !== 1 &&
                                                                                            <td width='10'>
                                                                                                <Tooltip role="button" title="Visualizar caixa" placement="top">
                                                                                                    <Link to={'/caixas/' + item.id}>
                                                                                                        <button type="button" className="btn btn-primary btn-sm">
                                                                                                            <i className="fas fa-eye" />
                                                                                                        </button>
                                                                                                    </Link>
                                                                                                </Tooltip>
                                                                                            </td>
                                                                                        }
                                                                                        <td>
                                                                                            <div className='btn-group'>
                                                                                                {
                                                                                                    item.F360SyncStatus === "synced" ?
                                                                                                        <Tooltip role="button" title="Apagar movimentação" placement="top">
                                                                                                            <button type="button" onClick={() => { toastWarning("Movimentação já sincronizada com F360, não é possível apagá-la.") }} className="btn btn-danger btn-sm">
                                                                                                                <i className="fas fa-trash" />
                                                                                                            </button>
                                                                                                        </Tooltip> :
                                                                                                        <Tooltip role="button" title="Apagar movimentação" placement="top">
                                                                                                            <button type="button" onClick={() => { this.onRemoveMovement(item) }} className="btn btn-danger btn-sm">
                                                                                                                <i className="fas fa-trash" />
                                                                                                            </button>
                                                                                                        </Tooltip>
                                                                                                }
                                                                                                {
                                                                                                    item.removed &&
                                                                                                    <Tooltip role="button" title={`Removido por ${item.userRemoved.nome} em ${Moment(item.removedAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")} e não será considerada no calculo.`} placement="top">
                                                                                                        <button type="button" className="btn btn-primary btn-sm">
                                                                                                            <i className="fas fa-info-circle" />
                                                                                                        </button>
                                                                                                    </Tooltip>
                                                                                                }
                                                                                                {
                                                                                                    item.F360SyncStatus === "synced" &&
                                                                                                    <Tooltip role="button" title="Movimentação sincronizada com F360" placement="top">
                                                                                                        <button type="button" className="btn btn-success btn-sm">
                                                                                                            <i className="fas fa-check" />
                                                                                                        </button>
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{takeIfIsNotNullOrEmpty(item.category) ?? "Não informada"}</td>
                                                                                        <td>{item.createdBy?.nome}</td>
                                                                                        <td>{takeIfIsNotNullOrEmpty(item.comment) ?? "Não informado"}</td>
                                                                                        {
                                                                                            item.createAt === undefined ?
                                                                                                <td>Não informado</td> :
                                                                                                <td>{Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                                        }
                                                                                        <td align='right'>
                                                                                            {
                                                                                                item.type === "ENTRADA" ?
                                                                                                    <Tooltip title="Entrada" placement="top">
                                                                                                        <strong className='text-success'>{toCurrency(item.price)}</strong>
                                                                                                    </Tooltip>
                                                                                                    :
                                                                                                    <Tooltip title="Saída" placement="top">
                                                                                                        <strong className='text-danger'>{toCurrency(-item.price)}</strong>
                                                                                                    </Tooltip>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <tr>
                                                                            <td colSpan={5}><strong>Total Saídas</strong></td>
                                                                            <td align='right'><strong className='text-danger'>{toCurrency(this.state.resultado.totais.movements.spends)}</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={5}><strong>Total Entradas</strong></td>
                                                                            <td align='right'><strong className='text-success'>{toCurrency(this.state.resultado.totais.movements.earn)}</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={5}><h4>Sub-Total</h4></td>
                                                                            <td align='right'><h4 className={(this.state.resultado.totais.movements.earn - this.state.resultado.totais.movements.spends) > 0 ? "text-success" : "text-danger"} > {toCurrency(this.state.resultado.totais.movements.earn - this.state.resultado.totais.movements.spends)}</h4></td>
                                                                        </tr>
                                                                    </tbody >
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <EmptyContent text="Nenhuma movimentação registrada" />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="retiradas" title="Retiradas">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                {
                                                    this.state.resultado.caixas.length === 1 &&
                                                    <div className="d-flex justify-content-between align-items-center" role="alert">
                                                        <div></div>
                                                        <button type="button" onClick={() => { this.showWithdrawModal() }} className="btn btn-sm btn-success text-white">
                                                            <i className="fas fa-plus mx-2"></i>
                                                            Registrar retirada
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    this.state.resultado.retiradas.length > 0 ?
                                                        <>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            {
                                                                                this.state.resultado.caixas.length !== 1 && <td></td>
                                                                            }
                                                                            <td width={10}></td>
                                                                            <td><strong>Usuário</strong></td>
                                                                            <td><strong>Comentário</strong></td>
                                                                            <td><strong>Data</strong></td>
                                                                            <td align='right'><strong>Valor</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.retiradas.map((item, index) =>
                                                                                <>
                                                                                    <tr key={index}>
                                                                                        {
                                                                                            this.state.resultado.caixas.length !== 1 &&
                                                                                            <td width='10'>
                                                                                                <Tooltip role="button" title="Visualizar caixa" placement="top">
                                                                                                    <Link to={'/caixas/' + item.id}>
                                                                                                        <button type="button" className="btn btn-primary btn-sm">
                                                                                                            <i className="fas fa-eye" />
                                                                                                        </button>
                                                                                                    </Link>
                                                                                                </Tooltip>
                                                                                            </td>
                                                                                        }
                                                                                        <td>
                                                                                            <Tooltip role="button" title="Apagar retirada" placement="top">
                                                                                                <button type="button" onClick={() => { this.onRemoveMovement(item) }} className="btn btn-danger btn-sm">
                                                                                                    <i className="fas fa-trash" />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        </td>
                                                                                        <td>{item.user?.nome}</td>
                                                                                        <td>{item.comment === '' ? "Não informado" : item.comment}</td>
                                                                                        {
                                                                                            item.createAt === undefined ?
                                                                                                <td>Não informado</td> :
                                                                                                <td>{Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                                        }
                                                                                        <td align='right'><strong className='text-danger'>{toCurrency(-item.price)}</strong></td>
                                                                                    </tr>
                                                                                    {
                                                                                        item.removed === true && <tr colspan='3' className='text-red'>
                                                                                            <td colspan="5">
                                                                                                <small>A retirada acima foi foi removida por <strong>{item.userRemoved.nome}</strong> em <strong>{Moment(item.removedAt.toDate()).format("DD/MM/YYYY")}</strong> às <strong>{Moment(item.removedAt.toDate()).format("HH:mm A")}</strong> e não será considerada no calculo.</small>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                        <tr>
                                                                            <td colSpan={this.state.resultado.caixas.length !== 1 ? 5 : 4}><h4>Sub-Total</h4></td>
                                                                            <td align='right'><h4 className='text-danger'>{toCurrency(-this.state.resultado.totais.retiradas)}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <EmptyContent text="Nenhuma retirada efetuada" />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="cancelados" title="Cancelados">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                {
                                                    this.state.resultado.ticketsCanceled.length > 0 ?
                                                        <>
                                                            <div className="alert alert-secondary" role="alert">
                                                                Tickets cancelados nesse(s) caixa(s).
                                                            </div>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width={150}></td>
                                                                            <td><strong>Placa</strong></td>
                                                                            <td><strong>Operador</strong></td>
                                                                            <td><strong>Motivo</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.ticketsCanceled.map((item, index) =>
                                                                                <tr key={"tickets_canceled_" + index}>
                                                                                    <td>
                                                                                        <a href={'/buscar/ticket?id=' + item.id} target="_blank" className='btn btn-danger'>
                                                                                            <i className="fas fa-ticket-alt mx-2" />
                                                                                            {item.ticket}
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>{item.placa}</td>
                                                                                    <td>{item.statusLogged[item.statusLogged.length - 1].createBy?.nome}</td>
                                                                                    <td>{item.statusLogged[item.statusLogged.length - 1]?.reason}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <EmptyContent text="Nenhum ticket cancelado até o momento." />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabelas" title="Tabelas usadas">
                                        <div className='row my-4'>
                                            <div className='col-lg-12'>
                                                {
                                                    this.state.resultado.tabelasUseds.length > 0 ?
                                                        <>
                                                            <div className="alert alert-secondary" role="alert">
                                                                Tabelas de preço usadas no caixa para pagamento de tickets.
                                                            </div>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width={100}><strong>Tipo</strong></td>
                                                                            <td><strong>Nome</strong></td>
                                                                            <td align='center'><strong>Vezes usada</strong></td>
                                                                            <td align='right'><strong>Valor total estacionamento</strong></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.resultado.tabelasUseds.map((item, index) =>
                                                                                <tr key={"tabelas_usadas_" + index}>
                                                                                    <td>{item.tableSelected.resumo.tabelaPreco.tipo.replace("_", " ").toUpperCase()}</td>
                                                                                    <td>{item.tableSelected.resumo.tabelaPreco.nome}</td>
                                                                                    <td align='center'>{item.quantityUsed}</td>
                                                                                    <td align='right'><strong className='text-success'>{toCurrency(item.total)}</strong></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        <tr>
                                                                            <td colSpan={3}><h4>Sub-Total</h4></td>
                                                                            <td align='right'><h4 className='text-success'>{toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <EmptyContent text="Nenhuma tabela utilizada para pagamentos" />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs >
                                <Dialog onClose={this.hideWithdrawModal} fullWidth maxWidth='xs' open={this.state.isShowingWithdrawModal}>
                                    <MuiDialogTitle className='text-center'>
                                        Registrar retirada
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        {
                                            this.state.isShowingWithdrawLoading && <Loading />
                                        }
                                        {
                                            !this.state.isShowingWithdrawLoading &&
                                            <div className='row'>
                                                <div className="col-lg-12">
                                                    <div className="alert alert-secondary">
                                                        <strong>Usuário que esta registrando a retirada</strong> <br />
                                                        <strong>Nome: </strong><span>{getUsuario()?.nome}</span> <br />
                                                        <strong>Data e hora: </strong><span>{Moment(new Date()).format("DD/MM/YYYY [às] HH:mm A")}</span> <br />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <strong>Valor da retirada</strong>
                                                    <CurrencyInput value={this.state.valor} prefix="R$" className='form-control' onChangeEvent={this.updateValor} />
                                                </div>
                                                <div className="col-lg-12">
                                                    <strong>Observações da retirada </strong>
                                                    <textarea value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })} placeholder='Caso tenha alguma observação' rows={5} className='form-control' />
                                                </div>
                                            </div>
                                        }
                                    </MuiDialogContent>
                                    {
                                        !this.state.isShowingWithdrawLoading &&
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={(e) => this.registerWithdraw()} className='btn btn-success'>Registrar retirada</button>
                                            </div>
                                        </MuiDialogActions>
                                    }
                                </Dialog>
                                {
                                    this.state.isShowingMovimentsModal === true && <MovementCashierModal onClose={(e) => this.setState({ isShowingMovimentsModal: false })} id={this.state.idCashier} showModal={this.state.isShowingMovimentsModal} />
                                }
                            </div >
                        </div>
                    </> :
                        <div className='card mb-4'>
                            <EmptyContent text={"Nenhum faturamento encontrado"} />
                        </div>
                }
            </>
        )
    }
}

class OpenCashier extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            currentUser: getUsuario(),
            showModal: props.showModal,
            state: "loading",
            cashierIsOpen: false,
            lastTrocoMessage: "",
            trocoInicial: 0.0,
        }
    }

    componentDidMount = async () => {
        if (!this.props.showModal) {
            return
        }
        this.setState({ state: "loading" })
        var currentCashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
        if (currentCashier !== null) {
            this.setState({ state: "cashier-is-open" })
        } else {
            var lastCashierOpened = await lastCashier({ estacionamentoId: this.state.estacionamentoId })
            if (lastCashierOpened?.valorTrocoFinal !== null && lastCashierOpened?.valorTrocoFinal > 0) {
                const valorTrocoFinal = lastCashierOpened?.valorTrocoFinal
                this.setState({ lastTrocoMessage: `O operador que fechou o caixa deixou um troco final de R$ ${valorTrocoFinal.toFixed(2)}. Este valor é editavel.` })
                this.setState({ trocoInicial: valorTrocoFinal })
            }
            this.setState({ state: "open-cashier" })
        }
    }

    openCashier = async () => {
        this.setState({ state: "loading" })
        const newCashier = await createCashier({
            clienteId: this.state.clienteId,
            estacionamentoId: this.state.estacionamentoId,
            valorInicial: this.state.trocoInicial,
            currentUser: this.state.currentUser
        })
        if (newCashier) {
            await saveLastUpdateNoSkip()
            reloadWithAlert("Caixa aberto com sucesso!")
        } else {
            toastWarning("Não foi possível abrir o caixa, tente novamente mais tarde.")
        }
    }

    hideModal = () => {
        this.setState({ state: "loading" })
        this.setState({ showModal: false })
    }

    updateInitialPrice = (event, maskedvalue, floatvalue) => {
        this.setState({ trocoInicial: floatvalue })
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='xs' open={this.state.showModal}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "cashier-is-open" &&
                        <div className='text-center m-5'>
                            <h4>Já existe um caixa ativo para este estacionamento!</h4><span>Atualize a pagina e tente fazer esta ação novamente.</span>
                        </div>
                    }
                    {
                        this.state.state === "open-cashier" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Abrir caixa
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    {
                                        this.state.lastTrocoMessage !== "" &&
                                        <div className="col-lg-12">
                                            <div className="alert alert-secondary">
                                                <span>{this.state.lastTrocoMessage}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-lg-12">
                                        <div className="alert alert-secondary">
                                            <strong>Usuário: </strong><span>{this.state.currentUser?.nome}</span> <br />
                                            <strong>Data e hora: </strong><span>{Moment(new Date()).format("DD/MM/YYYY [às] HH:mm A")}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Troco inicial</label>
                                        <CurrencyInput value={this.state.trocoInicial && this.state.trocoInicial} prefix="R$" className='form-control' onChangeEvent={this.updateInitialPrice} />
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                    {
                                        this.state.placa !== '' &&
                                        <button onClick={this.openCashier} className='btn btn-success'>Confirmar</button>
                                    }
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
            </>
        )
    }
}

class CloseCashierModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            currentUser: getUsuario(),
            showModal: props.showModal,
            relationships: [],
            totalCaixa: 0,
            trocoFinal: 0,
            state: "loading",
            status: ""
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const cashier = await getCashierById({ id: this.state.id })
        if (cashier.clienteId === this.state.clienteId) {
            const tickets = (await getTicketsOpenByParkId(this.state.estacionamentoId)).filter(e => e.status !== TicketStatus.PARKING)
            console.log(tickets.map(e => e.ticket));
            const allPayments = await calculateTotalMergedOfTicketAndMonthlyFromCashierId({ estacionamentoId: this.state.estacionamentoId, tickets: tickets, cashier: cashier })
            this.setState({ cashier: cashier })
            this.setState({ tickets: tickets })
            this.setState({ expiredTickets: tickets.filter(e => e.status === TicketStatus.PAYED_AND_WAITING_BACK && e.limitPermanenceEnabled && e.limitPermanence && Moment(e.limitPermanence.seconds * 1000).isBefore(Moment())) })
            const relationships = generateRelationship(tickets, cashier)
            this.setState({ allPayments: allPayments })
            this.setState({ relationships: relationships })
            const valorRetiradas = this.state.cashier.retiradas?.map(e => e.price).reduce((a, b) => a + b, 0)
            const valorTotal = allPayments.map(e => e.total).reduce((a, b) => a + b, 0)
            this.setState({ valorTotal: (valorRetiradas - valorTotal) - cashier.valorInicial ?? 0 }, () => {
                this.calculateTotalOfCashier()
            })
            const parameter = await getParameter()
            this.setState({ habilitarZerarPrisma: parameter.habilitarZerarPrisma })
            this.setState({ habilitarZerarPrismaValorPadrao: parameter.habilitarZerarPrismaValorPadrao })
            this.setState({ state: "close-cashier" })
        } else {
            toastWarning("Caixa não identificado")
            window.location.href = "/"
        }
    }

    closeConfirm = async () => {
        if (this.state.status === "") {
            toastWarning("Informe o status do caixa.")
            return false
        }
        this.setState({ state: "loading" })
        const cashier = await getCashierById({ id: this.state.id })
        if (cashier.status === "aberto") {
            const user = getUsuarioCompact()
            for (var ticket of this.state.tickets.filter(e => e.status !== TicketStatus.PAYED_AND_WAITING_BACK && e.status !== TicketStatus.PAYED_AND_PARKING)) {
                ticket.caixaFechado = true
                ticket.caixasIds = ticket.paymentsLogged?.map(e => e.caixaId)
                await updateTicket(ticket.id, ticket)
            }
            //Da baixa nos espirados também.
            for (var ticket of this.state.expiredTickets) {
                ticket.caixaFechado = true
                ticket.caixasIds = ticket.paymentsLogged?.map(e => e.caixaId)
                ticket.status = TicketStatus.PAYED_AND_DELIVERED
                ticket.closedAt = new Date()
                ticket.closedBy = user
                if (!ticket.statusLogged) {
                    ticket.statusLogged = []
                }
                if (!ticket.changeLogged) {
                    ticket.changeLogged = []
                }
                ticket.changeLogged.push({
                    identifier: generateUUIDV7(),
                    message: "Baixa no fechamento do caixa por estar expirado.",
                    createdAt: new Date(),
                    createdBy: user
                })
                ticket.statusLogged.push({
                    status: TicketStatus.PAYED_AND_DELIVERED,
                    createBy: user,
                    createAt: new Date(),
                    canceled: false,
                    origin: "admin"
                })
                await updateTicket(ticket.id, ticket)
            }
            await closeCashier({
                cashier: this.state.cashier,
                trocoFinal: this.state.trocoFinal,
                valorTotal: this.state.valorTotal,
                status: this.state.status,
                comment: this.state.comment,
                tickets: this.state.tickets
            })
            if (this.state.habilitarZerarPrismaValorPadrao === true) {
                await resetPrism()
            }
            await saveLastUpdateNoSkip()
            reloadWithAlert("Caixa fechado com sucesso.")
        } else {
            reloadWithAlertError(`Este caixa já foi fechado pelo operador ${cashier.closeBy.nome}`)
        }
    }

    calculateTotalOfCashier = () => {
        const trocoFinal = this.state.trocoFinal
        const valorTotal = this.state.valorTotal
        this.setState({ totalCaixa: valorTotal + trocoFinal })
    }

    hideModal = () => {
        this.setState({ state: "loading" })
        this.setState({ showModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    updateInitialPrice = (event, maskedvalue, floatvalue) => {
        this.setState({ trocoFinal: floatvalue }, () => {
            this.calculateTotalOfCashier()
        })
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='sm' open={this.state.showModal}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "cashier-is-open" &&
                        <div className='text-center m-5'>
                            <h4>Já existe um caixa ativo para este estacionamento!</h4><span>Atualize a pagina e tente fazer esta ação novamente.</span>
                        </div>
                    }
                    {
                        this.state.state === "close-cashier" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Fechar o caixa {this.state.cashier?.sequence}
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <div className="alert alert-secondary">
                                            <h6>Usuário que abriu o caixa</h6>
                                            <strong>Usuário: </strong><span>{this.state.cashier.openBy?.nome}</span> <br />
                                            <strong>Data e hora: </strong><span>{Moment(this.state.cashier.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="alert alert-secondary">
                                            <h6>Usuário fechando</h6>
                                            <strong>Usuário: </strong><span>{this.state.currentUser?.nome}</span> <br />
                                            <strong>Data e hora: </strong><span>{Moment(new Date()).format("DD/MM/YYYY [às] HH:mm A")}</span> <br />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        this.state.expiredTickets.length > 0 &&
                                        <div className='col-lg-12'>
                                            <AlertWarning>
                                                <span className='my-2'>Os tickets abaixo serão dados baixa por estarem com a data de permanência máxima menor que hoje.</span>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Ticket</th>
                                                                    <th scope="col">Data de expiração</th>
                                                                    <th scope="col" width="10"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.expiredTickets.map(e => (
                                                                        <tr key={e.id}>
                                                                            <td>{e.ticket}</td>
                                                                            <td>{Moment(e.limitPermanence.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                            <td>
                                                                                <a href={`/buscar/ticket?id=${e.id}`} target="_blank" className='btn btn-warning text-white'>
                                                                                    <i className="fas fa-ticket-alt" />
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </AlertWarning>
                                        </div>
                                    }
                                    {
                                        this.state.allPayments ?
                                            <div className="col-lg-12">
                                                <h5>Pagamentos no caixa</h5>
                                                <div className='table-responsive'>
                                                    <table className="table table-striped">
                                                        {
                                                            this.state.allPayments.map(e => (
                                                                <tr key={e.id}>
                                                                    <td>{e.nome}</td>
                                                                    <td align='right'><strong className='text-success'>{toCurrency(e.total)}</strong></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </table>
                                                </div>
                                            </div> :
                                            <div className="col-lg-12 text-center">
                                                <div className='alert alert-warning'>
                                                    <h5>Não existem lançamentos de valores neste caixa</h5>
                                                </div>
                                            </div>
                                    }
                                    {
                                        this.state.cashier.retiradas?.length > 0 &&
                                        <div className="col-lg-12">
                                            <h5>Retiradas registradas</h5>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    {
                                                        this.state.cashier.retiradas.map(e => (
                                                            <tr key={e.id}>
                                                                <td>
                                                                    <Tooltip title="Operador responsável" placement="top">
                                                                        <strong>{e.user?.nome}</strong>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>{Moment(e.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='right'><strong className='text-danger'>{toCurrency(-e.price)}</strong></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.relationships.length > 0 &&
                                        <div className="col-lg-12">
                                            <h5>Convênios utilizados</h5>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    {
                                                        this.state.relationships.map(e => (
                                                            <tr key={e.id}>
                                                                <td>{e.quantityUsed}x - {e.convenio?.resumo?.convenio?.nome}</td>
                                                                <td align='right'><strong className='text-danger'>{toCurrency(-e.total)}</strong></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.cashier.valorInicial ?
                                            <div>
                                                <label>Troco inicial</label><br />
                                                <label>R$ {this.state.cashier.valorInicial.toFixed(2)}</label>
                                            </div> :
                                            <div>
                                                <label>Troco inicial</label><br />
                                                <label>R$ 0,00</label>
                                            </div>
                                    }
                                    <div className="col-lg-12">
                                        <label>Troco final</label>
                                        <CurrencyInput value={this.state.trocoFinal && this.state.trocoFinal} prefix="R$" className='form-control' onChangeEvent={this.updateInitialPrice} />
                                    </div>
                                    <div className="col-lg-12">
                                        <h5>Total</h5>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <tr key="total">
                                                    <td>Total</td>
                                                    <td align='right'><strong className='text-success'>{toCurrency(this.state.totalCaixa)}</strong></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {
                                        this.state.habilitarZerarPrisma &&
                                        <div className="col-lg-12">
                                            <div className="form-check form-switch mb-2">
                                                <input className="form-check-input" id="zerarPrisma" checked={this.state.habilitarZerarPrismaValorPadrao} onChange={(e) => this.setState({ habilitarZerarPrismaValorPadrao: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="zerarPrisma">
                                                    Zerar sequência de prisma
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-lg-12">
                                        <label>Status do caixa</label>
                                        <select className='form-select' value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                            <option value=''>Selecionar</option>
                                            <option value='Bateu'>Bateu</option>
                                            <option value='Sobrou'>Sobrou</option>
                                            <option value='Faltou'>Faltou</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-12'>
                                        <label>Observação</label>
                                        <textarea rows='4' onChange={(e) => this.setState({ comment: e.target.value })} placeholder='Opcional' value={this.state.comment} className="form-control" />
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.closeConfirm} className='btn btn-success'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
            </>
        )
    }
}

class MovementCashierModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            currentUser: getUsuario(),
            showModal: props.showModal,
            relationships: [],
            totalCaixa: 0,
            trocoFinal: 0,
            state: "loading",
            type: "",
            price: 0.0,
            defaultCategories: [
                "Conta de luz",
                "Conta de água",
                "Conta de gás",
                "Funcionários",
                "Vale",
                "Seguro",
                "Recebimento",
                "Convênios",
                "Outros"
            ]
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const cashier = await getCashierById({ id: this.state.id })
        this.setState({ cashier: cashier })
        const items = await getAllMovementsCategoriesCashiers({ estacionamentoId: this.state.estacionamentoId })
        if (items.length > 0) {
            this.setState({ defaultCategories: items.map(e => e.name) })
        }
        this.setState({ state: "fill" })
    }

    save = async () => {
        if (this.state.type === "") {
            toastWarning("Informe o tipo da movimentação.")
            return false
        }
        if (this.state.price === 0.0) {
            toastWarning("Informe o valor da movimentação.")
            return false
        }
        var confirm = window.confirm(`Deseja mesmo registrar esta movimentação de caixa?`);
        if (confirm) {
            this.setState({ state: "loading" })
            const user = getUsuarioCompact()
            const raw = {
                id: generateUUIDV7(),
                price: this.state.price,
                comment: this.state.comment !== "" ? this.state.comment : null,
                category: this.state.category,
                createdBy: user,
                origin: "site",
                isRemoved: false,
                createAt: new Date(),
                type: this.state.type,
            }
            var cashier = this.state.cashier
            if (!cashier.movements) {
                cashier.movements = []
            }
            cashier.movements.push(raw)
            await updateCashier(cashier.id, cashier)
            reloadWindow()
        }
    }

    hideModal = () => {
        this.setState({ showModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    updateInitialPrice = (event, maskedvalue, floatvalue) => {
        this.setState({ price: floatvalue })
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='xs' open={this.state.showModal}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "fill" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Registrar movimentação
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <label>Tipo</label>
                                        <select className='form-select' value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                                            <option value=''>Selecionar</option>
                                            <option value='ENTRADA'>Entrada</option>
                                            <option value='SAIDA'>Saída</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Categoria ( Opcional )</label>
                                        <select className='form-select' value={this.state.category} onChange={(e) => this.setState({ category: e.target.value })}>
                                            <option value="">Selecionar</option>
                                            {
                                                this.state.defaultCategories?.map(item => { return <option value={item}>{item}</option> })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label>Valor do lançamento</label>
                                        <CurrencyInput value={this.state.price && this.state.price} prefix="R$" className='form-control' onChangeEvent={this.updateInitialPrice} />
                                    </div>
                                    <div className='col-lg-12'>
                                        <label>Descrição</label>
                                        <textarea rows='4' onChange={(e) => this.setState({ comment: e.target.value })} placeholder='Opcional' value={this.state.comment} className="form-control" />
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.save} className='btn btn-success'>Adicionar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
            </>
        )
    }
}

class MovementCategoriesCashierModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            revendaId: getRevendaIdByPark(),
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            user: getUsuarioCompact(),
            name: "",
            state: "fill"
        }
    }

    componentDidMount = () => {
        sendLogByUser("Parâmetros", `Visualizou o modal de categorias de movimentações do caixa.`)
    }

    save = async () => {
        if (this.state.name?.trim() === "") {
            toastWarning("Informe o nome da categoria da movimentação.")
            return false
        }
        this.setState({ state: "loading" })
        const exist = await getMovementsCategoryCashierByName({
            name: this.state.name,
            estacionamentoId: this.state.estacionamentoId
        })
        if (exist.length === 0) {
            const data = {
                revendaId: this.state.revendaId,
                clienteId: this.state.clienteId,
                estacionamentoId: this.state.estacionamentoId,
                name: this.state.name,
                createdBy: this.state.user,
                createdAt: new Date()
            }
            await createMovementCategoryCashier({ data: data })
            this.setState({ state: "fill" })
            this.setState({ name: "" })
            this.listAllCategories()
        } else {
            this.setState({ state: "fill" })
            toastWarning("Já existe uma categoria cadastrada com este nome.")
        }
    }

    removeCategory = async (item) => {
        const confirm = window.confirm(`Deseja mesmo remover esta categoria de movimentação?`);
        if (confirm) {
            await deleteMovementCategoryCashier({ id: item.id })
            sendLogByUser("Licença", `Removeu a categoria ${item.name}`, item)
            this.listAllCategories()
        }
    }

    listAllCategories = async () => {
        this.setState({ isShowingAllCategories: true })
        this.setState({ categoryState: "loading" })
        const items = await getAllMovementsCategoriesCashiers({ estacionamentoId: this.state.estacionamentoId })
        if (items.length > 0) {
            this.setState({ allCoupons: items })
            this.setState({ categoryState: "items" })
        } else {

            this.setState({ categoryState: "empty" })
        }
    }

    hideModal = () => {
        this.setState({ showModal: false })
        if (this.props.onClose) {
            this.props.onClose()
            saveLastUpdate()
        }
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='xs' open={true}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "fill" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Registrar categoria de movimentação
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label>Nome</label>
                                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                    </div>
                                </div>
                                {
                                    this.state.isShowingAllCategories && <div>
                                        {
                                            this.state.categoryState === "loading" && <Loading />
                                        }
                                        {
                                            this.state.categoryState === "empty" && <EmptyContent text="Nenhuma categoria cadastrada." />
                                        }
                                        {
                                            this.state.categoryState === "items" &&
                                            <div className='mt-3'>
                                                <h6 className='text-center'>Categorias cadastradas</h6>
                                                <table className="table table-striped">
                                                    <tr>
                                                        <td><strong>Nome</strong></td>
                                                        <td></td>
                                                    </tr>
                                                    <tbody>
                                                        {
                                                            this.state.allCoupons.map((item, index) =>
                                                                <tr>
                                                                    <td>{item.name}</td>
                                                                    <td width={10}>
                                                                        <div className='btn-group'>
                                                                            <Tooltip title="Remover categoria" placement="top">
                                                                                <button type="button" onClick={() => { this.removeCategory(item, index) }} className="btn btn-danger btn-sm">
                                                                                    <i className="fas fa-trash" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.listAllCategories} className='btn btn-secondary'>Ver todas</button>
                                    <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.save} className='btn btn-success'>Adicionar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
            </>
        )
    }
}

export { CashierList, CashierTotals, OpenCashier, CashierSelector, CloseCashierModal, MovementCategoriesCashierModal }