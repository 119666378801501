import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getLicencaAtual, getRevenda, getRevendaIdByPark, getUsuarioCompact, isAdministrador } from '../../config/auth'
import 'firebase/auth';
import 'firebase/storage';
import { Loading, EmptyContent, ClientButton, HelpButton } from '../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CreditCardInput from 'react-credit-card-input';
import NumberFormat from 'react-number-format';
import { sendClickButton, sendLog, sendLogByUser } from '../../shared/analytics'
import { ListarLicencaHistorico } from '../licenca/index';
import { documentMask, exportAndDownloadXLS, goToNewWindow, isNullOrEmpty, isSelfParking, queryString, reloadWindow, reloadWithAlert, toastDismissLoading, toastLoading, toastSuccess, toastWarning, toCurrency, valueByMaximunChars } from '../../shared/utils';
import Tooltip from '@mui/material/Tooltip';
import { searchCEP } from '../../shared/cepWorker';
import { getAllPublicLicencePlan, getLicencePlanById } from '../../store/collections/planosLicencaWorker';
import { deleteNewSubscriptionItem } from '../../store/collections/newSubscriptionWorker';
import { getAllParksByClientId, getParkById } from '../../store/collections/parkWorker';
import { fetchFunction, fetchFunctionCall } from '../../shared/function-request';
import Chart from "react-apexcharts";
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { CardManagerLicence } from './card-manager/card-manager';
import moment from 'moment';
import { getClient, getClientById } from '../../store/collections/clienteWorker';
import { AnalyticsPark } from '../estacionamento';
import { getParameterByParkId, getParametersWithSemPararActivated } from '../../store/collections/parameterWorker';
import { AlertDanger, AlertSuccess } from '../../shared/alert-manager';
import { getRevendaById } from '../../store/collections/revendaWorker';

class MinhaLicenca extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            revendaId: getRevendaIdByPark(),
            formaCobranca: getRevenda()?.formaCobranca,
            startDate: new Moment(new Date()),
            isAdmin: isAdministrador(),
            loading: false,
            isEmpty: false,
            current: null,
            isOpenModal: false,
            isOpenCancelModal: false,
            isOpenModalCurrentPlan: false,
            expirationDateUpdate: null,
            sendFirstCharge: true,
            paymentMethod: "banksplit",
            stateModal: "fill",
            messageSimulate: "",
            name: '',
            email: '',
            document: '',
            phone: '',
            tipoDocumento: 'CPF',
            currentSubscription: [],
            street: '',
            number: '',
            district: '',
            zipCode: '',
            city: '',
            state: '',
            cardNumber: '',
            expirationDate: '',
            securityCode: '',
            addressBill: 'same', //same, other
            plans: [],
            customPlanSelected: null,
            isLicenceLoading: true,
            cobrancas: [],
            coupon: queryString("cupom")
        }
        this.load()
    }

    load = async () => {
        this.loadTypeURL()
        this.loadCurrentSubscription()
        this.loadParking()
        this.loadAllPans()
    }

    loadTypeURL = () => {
        const type = queryString("type")
        if (type === "plan") {
            this.showPreselectedPlanModal()
        } else if (type === "update-card") {
            this.setState({ isShowCardManagerModal: true })
        } else if (type === "charges-modal" || type === "charge-detail") {
            this.showHistoryPayments()
        }
    }

    loadAllPans = async () => {
        const plans = await getAllPublicLicencePlan()
        this.setState({ plans: plans });
    }

    loadParking = async () => {
        const park = await getParkById({ id: this.state.estacionamentoId })
        const data = await getClientById({ id: park.clienteId })
        if (data) {
            this.setState({ name: data.nome ?? "" })
            this.setState({ document: data.documento ?? "" })
            this.setState({ phone: data.celular ?? "" })
            this.setState({ email: data.email ?? "" })
            this.setState({ tipoDocumento: data.tipoDocumento ?? "" })
            const address = data.endereco
            if (address) {
                this.setState({ street: address.logradouro })
                this.setState({ number: address.numero })
                this.setState({ district: address.bairro })
                this.setState({ zipCode: address.cep })
                this.setState({ city: address.cidade })
                this.setState({ state: address.estado })
                this.setState({ ibge: address.ibge })
            }
        }
    }

    selectPlan = (plan) => {
        if (this.state.formaCobranca === "SELFPARKING") {
            this.setState({ currentPlan: plan })
            sendClickButton("Licença", "Plano selecionado: " + plan.nome)
            this.setState({ isOpenModal: true })
            var frequence = ""
            if (plan.anual === true) {
                frequence = "anual"
            } else if (plan.semestral === true) {
                frequence = "semestral"
            } else if (plan.trimestral === true) {
                frequence = "trimestral"
            } else if (plan.bimestral === true) {
                frequence = "bimestral"
            } else if (plan.mensal === true) {
                frequence = "mensal"
            }
            this.setState({ frequence: frequence })
        } else {
            toastWarning("Entre em contato com o seu representante comercial")
        }
    }

    loadCurrentSubscription = async () => {
        const option = {
            method: 'GET'
        }
        this.setState({ isLicenceLoading: true })
        const result = await fetchFunction(`/newSubscription/current-licence`, option)
        this.setState({ isLicenceLoading: false })
        if (result.success) {
            this.setState({ hasCardAdded: result.data.paymentMethod === "card" })
            this.setState({ currentSubscription: result.data })
        } else {
            this.setState({ currentSubscription: null })
        }
        this.loadTypeURL()
    }

    createSubscription = async () => {
        this.setState({ stateModal: "loading" })
        const option = this.generateSubscriptionBody()
        sendLogByUser("Licença", `Processando pagamento`, false, option)
        const result = await fetchFunction(`/newSubscription/create`, option)
        sendLogByUser("Licença", `Processando pagamento: ${result.message}`, false, result)
        if (result.success) {
            this.setState({ stateModal: "success" })
        } else {
            this.setState({ stateModal: "fill" })
            this.setState({ messageSimulate: result.message })
        }
    }

    simulateSubscription = async () => {
        this.setState({ isLoadingSimulate: true })
        const option = this.generateSubscriptionBody()
        const result = await fetchFunction(`/newSubscription/simulate`, option)
        sendLogByUser("Licença", `Simulando pagamento: ${result.message}`, false, option)
        this.setState({ messageSimulate: result.message })
        this.setState({ isLoadingSimulate: false })
    }

    generateSubscriptionBody = () => {
        return {
            method: 'POST',
            body: {
                payment: {
                    type: this.state.paymentMethod,
                    card: {
                        name: this.state.nameCardUpdate,
                        number: this.state.cardNumber,
                        date: this.state.date,
                        cvv: this.state.cvv
                    }
                },
                plan: {
                    id: this.state.currentPlan.id,
                    frequence: this.state.frequence,
                    dueDay: this.state.dueDay,
                    // firstChange: isAdministrador() ? this.state.startDate.format("MM/yyyy") : undefined
                },
                coupon: this.state.coupon,
                sendFirstCharge: this.state.sendFirstCharge,
                customer: {
                    name: this.state.name,
                    document: this.state.document,
                    email: this.state.email,
                    phone: this.state.phone,
                    address: {
                        zipCode: this.state.zipCode,
                        street: this.state.street,
                        number: this.state.number,
                        district: this.state.district,
                        city: this.state.city,
                        state: this.state.state,
                        ibge: this.state.ibge
                    }
                }
            }
        };
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ zipCode: result.cep })
            this.setState({ street: result.logradouro })
            this.setState({ district: result.bairro })
            this.setState({ city: result.localidade })
            this.setState({ state: result.uf })
            this.setState({ ibge: result.ibge })
        }
    }

    openCancelModal = () => {
        this.setState({ isOpenCancelModal: true })
    }

    closeModal = () => {
        this.setState({ isOpenModal: false })
    }

    closeCancelModal = () => {
        this.setState({ isOpenCancelModal: false })
        sendClickButton("Licença", "Fechar modal de cancelar")
    }

    reloadPage = () => {
        sendLogByUser("Licença", `Fechou modal de pagamento`)
        this.setState({ isEditingData: false })
        this.setState({ isOpenModal: false })
    }

    handleDueDayChange = (e) => {
        if (e.target.value === "") {
            return
        }
        this.setState({ dueDay: e.target.value }, () => {
            this.simulateSubscription()
        })
    }

    handleFrequenceChange = (e) => {
        if (e.target.value === "") {
            return
        }
        this.setState({ frequence: e.target.value }, () => {
            this.simulateSubscription()
        })
    }

    handleCardNumberChange = (e) => {
        this.setState({ cardNumber: e.target.value })
    }

    handleCardExpiryChange = (e) => {
        this.setState({ date: e.target.value })
    }

    handleCardCVCChange = (e) => {
        this.setState({ cvv: e.target.value })
    }

    changeWayPayment = async (e) => {
        this.setState({ paymentMethod: e.target.value })
        sendLogByUser("Licença", `Selecionada a forma de pagamento: ${e.target.value === "card" ? "Cartão de crédito" : "Boleto / Pix"}`)
    }

    hasActivePlan = async () => {
        if (this.state.current?.subscription?.idSubscription === undefined) {
            return false
        }
        return true
    }

    showPaymentHistory = () => {
        this.setState({ isShowPaymentHistoryModal: true })
    }

    handleStartDateChange = (newValue) => {
        this.setState({ startDate: newValue })
    }

    showManageCard = () => {
        this.setState({ isShowCardManagerModal: true })
    }

    hideManageCard = () => {
        this.setState({ isShowCardManagerModal: false })
    }

    showHistoryPayments = async () => {
        this.setState({ isChangeLoading: true })
        this.setState({ isShowHistoryPaymentModal: true })
        const option = {
            method: 'GET'
        }
        const result = await fetchFunction(`/newSubscription/all-changes`, option)
        this.setState({ isChangeLoading: false })
        if (result.success) {
            this.setState({ cobrancas: result.data.sort((a, b) => a.changeDate - b.changeDate) })
        }
        const type = queryString("type")
        if (type === "charge-detail") {
            const id = queryString("id")
            this.setState({ currentNewSubscriptionId: id })
        }
    }

    showPreselectedPlanModal = async () => {
        const id = queryString("id")
        if (id) {
            const plan = await getLicencePlanById({ id: id })
            if (plan) {
                this.selectPlan(plan)
            }
        }
    }

    hideHistoryPayments = () => {
        this.setState({ isShowHistoryPaymentModal: false })
    }

    statusSubscriptionColor = (status) => {
        switch (status) {
            case "FREE": return "alert alert-success";
            case "PENDING": return "alert alert-danger";
            case "GENERATED": return "alert alert-warning";
            case "PAYED": return "alert alert-success";
            case "CANCELED": return "alert alert-danger";
        }
    }

    editData = () => {
        this.setState({ isEditingData: true })
    }

    render() {
        return (
            <>
                <NavBar>
                    {
                        (this.state.currentBill?.subscription?.statusName?.toUpperCase() === "PENDENTE" || this.state.currentBill?.subscription?.statusName?.toUpperCase() === "INADIMPLENTE") &&
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="card text-white bg-danger" >
                                    <div className="card-body">
                                        <h5 className="card-title text-white ">Existe um pagamento pendente</h5>
                                        {
                                            this.state.currentBill?.transaction?.PaymentMethod === "1" && this.state.currentBill?.transaction?.PaymentObject?.BankSlipUrl === undefined &&
                                            <h6 className="text-white">Em breve enviaremos um boleto para o e-mail {this.state.currentBill?.paymentEmail} com o título "Boleto disponível".</h6>
                                        }
                                        {
                                            this.state.currentBill?.transaction?.PaymentMethod === "1" && this.state.currentBill?.transaction?.PaymentObject?.BankSlipUrl !== undefined &&
                                            <h6 className="text-white">Já estamos com o boleto do estabelecimento disponível, basta clicar no botão abaixo. Caso já tenha pago desconsidere este alerta.</h6>
                                        }
                                        {
                                            this.state.currentBill?.transaction?.PaymentMethod === "2" &&
                                            <h6 className="text-white">Pode ser que precise atualizar o seu cartão de crédito com final {this.state.currentBill?.transaction?.PaymentObject?.finalCreditNumber} para efetuarmos a cobrança.</h6>
                                        }
                                    </div>
                                    {
                                        (Number(this.state.currentBill?.transaction?.PaymentMethod) === 1 && this.state.currentBill?.transaction?.PaymentObject?.BankSlipUrl !== undefined) &&
                                        <div className="card-footer">
                                            <a href={this.state.currentBill?.transaction?.PaymentObject?.BankSlipUrl} target="_blank">
                                                <button className='btn btn-danger'>Ver boleto</button>
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.customPlanSelected !== null &&
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="card" >
                                    <div className="card-body">
                                        <h5 className="card-title">{this.state.customPlanSelected.Name}</h5>
                                        <h6>{this.state.customPlanSelected.Description}</h6>
                                        <h4>
                                            <span className="font-700 color-base2">
                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.customPlanSelected.Amount} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                <span> /mês</span>
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="card-footer">
                                        <button onClick={(e) => this.selectPlan(this.state.customPlanSelected)} className='btn btn-success'>Ver detalhes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Sua licença
                                    <HelpButton />
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        {
                                            this.state.isLicenceLoading ?
                                                <div className='col-lg-12'>
                                                    <Loading />
                                                </div> :
                                                <div className='col-lg-12'>
                                                    {
                                                        this.state.currentSubscription && <>
                                                            <Tooltip title={this.state.isAdmin ? this.state.currentSubscription.id : ""} placement="top">
                                                                <div className={this.statusSubscriptionColor(this.state.currentSubscription.status)} role="alert">
                                                                    <span className="badge text-bg-success">Seu plano atual</span> <br />
                                                                    {
                                                                        this.state.currentSubscription.plan && <>
                                                                            <strong>Plano: </strong> <span>{this.state.currentSubscription.plan?.nome}</span><br />
                                                                            {
                                                                                (this.state.currentSubscription.plan?.esconderValores ?? true) && <>
                                                                                    <strong>Valor: </strong> <span>{
                                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.currentSubscription.plan?.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                    }</span><br />
                                                                                </>
                                                                            }
                                                                            {/* {
                                                                                this.state.formaCobranca === "SELFPARKING" &&
                                                                                <>
                                                                                    <strong>Forma de pagamento: </strong>{this.state.currentSubscription.paymentMethod === "card" ? "Cartão de crédito" : "Boleto bancário"}<br />
                                                                                </>
                                                                            } */}
                                                                        </>
                                                                    }
                                                                    <strong>Status: </strong>
                                                                    {this.state.currentSubscription.status === "PENDING" && <span>Pendente</span>}
                                                                    {this.state.currentSubscription.status === "GENERATED" && <span>Aguardando pagamento</span>}
                                                                    {this.state.currentSubscription.status === "PAYED" && <span>Pago</span>}
                                                                    {this.state.currentSubscription.status === "CANCELED" && <span>Cancelado</span>}
                                                                    {this.state.currentSubscription.status === "FREE" && <span>Gratuito</span>}
                                                                    <br /><strong>Acesso liberado até: </strong>{this.state.currentSubscription.vencimentoFormatted}<br />
                                                                </div>
                                                            </Tooltip>
                                                            <AnalyticsPark estacionamentoId={this.state.estacionamentoId} />
                                                            {
                                                                this.state.currentSubscription?.status !== "FREE" &&
                                                                <div className='alert alert-secondary' role='button' onClick={this.showHistoryPayments} >
                                                                    <span className="badge text-bg-secondary">Suas cobranças</span> <br />
                                                                    Acompanhe todas as suas cobranças já pagas e pendentes. <p />
                                                                    <button className="btn btn-secondary btn-sm">
                                                                        <i className="fas fa-history mx-2" />
                                                                        Visualizar cobranças
                                                                    </button>
                                                                </div>
                                                            }
                                                            {/* {
                                                                this.state.formaCobranca === "SELFPARKING" &&
                                                                <>
                                                                    {
                                                                        this.state.currentSubscription.status !== "FREE" && <>
                                                                            {
                                                                                this.state.hasCardAdded ?
                                                                                    <div className='alert alert-success' role='button' onClick={this.showManageCard} >
                                                                                        <span className="badge text-bg-success">Novidade</span> <br />
                                                                                        Não se preocupe, usamos seu cartão para fazer a cobrança automaticamente. <p />
                                                                                        <button className="btn btn-success">
                                                                                            <i className="fas fa-credit-card mx-2" />
                                                                                            Gerenciar cartões
                                                                                        </button>
                                                                                    </div> :
                                                                                    <div className='alert alert-success' role='button' onClick={this.showManageCard} >
                                                                                        <span className="badge text-bg-success">Novidade</span> <br />
                                                                                        Automatize as cobranças da sua licença cadastrando um cartão de crédito. <p />
                                                                                        <button className="btn btn-success btn-sm">
                                                                                            <i className="fas fa-credit-card mx-2" />
                                                                                            Cadastrar cartão de crédito
                                                                                        </button>
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            } */}
                                                        </>
                                                    }
                                                    {/* <div className='col-lg-12'>
                                                        <div className="alert alert-info" role="alert">
                                                            <a href={getRevenda()?.youtube} target='_blank'><strong>- Aprenda tudo sobre nossa plataforma</strong></a> <br />
                                                            <Link to='/home'><strong>- Acompanhe em tempo real</strong></Link> <br />
                                                            <Link to='/cadastrar/mensalista'><strong>- Cadastre mensalistas</strong></Link> <br />
                                                            <Link to='/cadastrar/tabela-precos'><strong>- Cadastre suas tabelas de preços</strong></Link> <br />
                                                            <Link to='/cadastrar/produto'><strong>- Venda produtos e serviços</strong></Link> <br />
                                                            <Link to='/relatorios/vendas'><strong>- Acompanhe seus caixas fechados</strong></Link> <br />
                                                            <Link to='/relatorios/caixas'><strong>- Acompanhe seus relatórios de vendas</strong></Link> <br />
                                                            <Link to='/nota-fiscal/listar'><strong>- Emita nota fiscal</strong></Link> <p />
                                                        </div>
                                                    </div> */}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.plans?.map(item =>
                                <div className='col-lg-3'>
                                    <ItemPlan key={item.id} plan={item} onSelected={this.selectPlan} />
                                </div>)
                        }
                    </div>
                    <Dialog maxWidth='sm' fullWidth open={this.state.isShowCardManagerModal} onClose={this.hideManageCard}>
                        <MuiDialogContent>
                            <CardManagerLicence onCardAdded={this.loadCurrentSubscription} />
                        </MuiDialogContent>
                        <MuiDialogActions className='m-3'>
                            <button onClick={this.hideManageCard} className='btn btn-secondary'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='lg' fullWidth open={this.state.isShowHistoryPaymentModal} onClose={this.hideHistoryPayments}>
                        <MuiDialogTitle className='text-center'>
                            Cobranças disponíveis
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.isChangeLoading ? <Loading /> :
                                    <LicencaRenvendasProjectionTable estacionamentoId={this.state.estacionamentoId} selectedChanges={this.state.cobrancas} currentNewSubscriptionId={this.state.currentNewSubscriptionId} key={this.state.cobrancas} />
                            }
                        </MuiDialogContent>
                        <MuiDialogActions className='m-3'>
                            <button onClick={this.hideHistoryPayments} className='btn btn-secondary'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                    <div>
                        <Dialog fullWidth maxWidth={'md'} open={this.state.isOpenModal}>
                            <MuiDialogContent>
                                {
                                    this.state.currentPlan?.tipo === "privado-pre-selecionado" && <div className='alert alert-success'>
                                        {
                                            this.state.currentPlan?.estabelecimentos?.length === 1 && <><strong>Atenção:</strong> este plano é exclusivo e esta atribuido ao seguinte estabelecimento: {this.state.currentPlan?.estabelecimentos.map(e => e.nome).join(",")}.</>
                                        }
                                        {
                                            this.state.currentPlan?.estabelecimentos?.length > 1 && <><strong>Atenção:</strong> este plano é exclusivo e esta atribuido aos seguintes estabelecimentos: <br />{this.state.currentPlan?.estabelecimentos.map(e => e.nome).join(", ")}.</>
                                        }
                                    </div>
                                }
                                {
                                    this.state.stateModal === "fill" &&
                                    <div className='row'>
                                        {
                                            !this.state.isLoadingSimulate && this.state.messageSimulate !== "" &&
                                            <div className="col-lg-12">
                                                <AlertSuccess tag="Atenção">
                                                    {this.state.messageSimulate}
                                                </AlertSuccess>
                                            </div>
                                        }
                                        {
                                            this.state.isLoadingSimulate &&
                                            <div className="col-lg-12">
                                                <div className="alert alert-success" role="alert">
                                                    Aguarde...
                                                </div>
                                            </div>
                                        }
                                        <div className='col-lg-12'>
                                            {
                                                this.state.message &&
                                                <AlertSuccess tag="Atenção">
                                                    {this.state.message}
                                                </AlertSuccess>
                                            }
                                        </div>
                                        <div className='col-lg-6'>
                                            <ItemPlan plan={this.state.currentPlan} />
                                        </div>
                                        <div className='col-lg-6'>
                                            {/* {
                                                this.state.isAdmin &&
                                                <div className="col-lg-12">
                                                    <div className='alert alert-danger'>
                                                        <strong>Atenção:</strong> função disponível apenas para administradores. <br />
                                                        <MobileDatePicker
                                                            label="Mês da primeira cobrança"
                                                            inputFormat="MM"
                                                            value={this.state.startDate}
                                                            onChange={this.handleStartDateChange}
                                                            views={['month']}
                                                            renderInput={(params) => <TextField {...params} size="small" className='form-control mt-2' />}
                                                        />
                                                        {/* {
                                                                !isProd() && this.state.paymentMethod === "card" && <div className='mt-3'>
                                                                    <h5>Cartões de teste</h5>
                                                                    <table className='table table-striped'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Final do Cartão</td>
                                                                                <td>Status da Transação</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Final 0, 1 e 4</td>
                                                                                <td>Autoriza</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>2</td>
                                                                                <td>Não Autorizada</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            }
                                                    </div>
                                                </div>
                                            } */}
                                            {
                                                !this.state.isEditingData ?
                                                    <div className="alert alert-secondary">
                                                        <span className="badge text-bg-secondary">Dados para cobrança</span> <br />
                                                        <strong>E-mail: </strong>
                                                        <label>{this.state.email}</label> <br />
                                                        <strong>Celular: </strong>
                                                        <label>{this.state.phone !== "(00)00000-0000" ? this.state.phone : "Não informado"}</label> <br />
                                                        <strong>Número do documento: </strong>
                                                        <label>{this.state.document !== "00.000.000/0000-00" ? this.state.document : "Não informado"}</label> <br />
                                                        {
                                                            this.state.zipCode && this.state.zipCode !== "00000-00" ? <>
                                                                <strong>Endereço: </strong>
                                                                <label>{this.state.zipCode} - {this.state.street}, {this.state.number} - {this.state.district}</label> <br />
                                                            </> : <><strong>Endereço: </strong> Não informado <br /></>
                                                        }
                                                        <small>* Dados utilizados para envio de cobranças e suporte.</small> <br />
                                                        <button type="button" onClick={this.editData} className="btn btn-sm btn-secondary mt-2">
                                                            <i className="fas fa-edit mx-2" />
                                                            Editar dados
                                                        </button> <br />
                                                    </div> :
                                                    <>
                                                        <div className='row'>
                                                            <div className="col-lg-12">
                                                                <label>E-mail</label>
                                                                <input type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                                                                <label>Celular</label>
                                                                <NumberFormat className="form-control" value={this.state.phone} mask='_' onChange={(e) => this.setState({ phone: e.target.phone })} format="(##)#####-####" />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-4">
                                                                <label>Tipo documento</label>
                                                                <div className="input-group mb-3">
                                                                    <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} >
                                                                        <option value='CPF'>CPF</option>
                                                                        <option value='CNPJ'>CNPJ</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <label>Número documento</label>
                                                                <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-3">
                                                                <label>CEP</label>
                                                                <NumberFormat autoComplete='off' className="form-control" value={this.state.zipCode} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Logradouro</label>
                                                                <input type="text" onChange={(e) => this.setState({ street: e.target.value })} value={this.state.street} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Número</label>
                                                                <input type="text" onChange={(e) => this.setState({ number: e.target.value })} value={this.state.number} className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-6">
                                                                <label>Bairro</label>
                                                                <input type="text" onChange={(e) => this.setState({ district: e.target.value })} value={this.state.district} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Cidade</label>
                                                                <input type="text" onChange={(e) => this.setState({ city: e.target.value })} value={this.state.city} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Estado</label>
                                                                <input type="text" onChange={(e) => this.setState({ state: e.target.value })} value={this.state.state} className="form-control" required />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                            <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>Assinatura</label>
                                                    <div className="input-group">
                                                        <select className="form-select" onChange={this.handleFrequenceChange} value={this.state.frequence} >
                                                            {this.state.currentPlan?.anual && <option value="anual">Anual - R${this.state.currentPlan?.valorAnual?.toFixed(2)}</option>}
                                                            {this.state.currentPlan?.semestral && <option value="semestral">Semestral - R${this.state.currentPlan?.valorSemestral?.toFixed(2)}</option>}
                                                            {this.state.currentPlan?.trimestral && <option value="trimestral">Trimestral - R${this.state.currentPlan?.valorTrimestral?.toFixed(2)}</option>}
                                                            {this.state.currentPlan?.bimestral && <option value="bimestral">Bimestral - R${this.state.currentPlan?.valorBimestral?.toFixed(2)}</option>}
                                                            {this.state.currentPlan?.mensal && <option value="mensal">Mensal - R${this.state.currentPlan?.valorMensal?.toFixed(2)}</option>}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Dia de cobrança</label>
                                                    <div className="input-group">
                                                        <select className="form-select" onChange={this.handleDueDayChange} value={this.state.dueDay} >
                                                            <option value="">Selecionar</option>
                                                            {this.state.currentPlan?.dia1 && <option value="1">1</option>}
                                                            {this.state.currentPlan?.dia5 && <option value="5">5</option>}
                                                            {this.state.currentPlan?.dia10 && <option value="10">10</option>}
                                                            {this.state.currentPlan?.dia15 && <option value="15">15</option>}
                                                            {this.state.currentPlan?.dia20 && <option value="20">20</option>}
                                                            {this.state.currentPlan?.dia25 && <option value="25">25</option>}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* {
                                                    this.state.formaCobranca === "SELFPARKING" &&
                                                    <>
                                                        <div className="col-lg-12">
                                                            <label>Forma de pagamento</label>
                                                            <div className="input-group">
                                                                <select className="form-select" onChange={(e) => this.changeWayPayment(e)} value={this.state.paymentMethod} >
                                                                    <option value='card'>Cartão de crédito</option>
                                                                    <option value='banksplit'>Pix ou Boleto bancário</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.paymentMethod === "card" &&
                                                            <>
                                                                <div className='col-lg-12'>
                                                                    <label>Dados do cartão de crédito</label>
                                                                    <CreditCardInput
                                                                        cardNumberInputProps={{ value: this.state.cardNumber, onChange: this.handleCardNumberChange }}
                                                                        cardExpiryInputProps={{ value: this.state.date, onChange: this.handleCardExpiryChange }}
                                                                        cardCVCInputProps={{ value: this.state.cvv, onChange: this.handleCardCVCChange }}
                                                                        fieldClassName="form-control"
                                                                        customTextLabels={{
                                                                            invalidCardNumber: 'Número do cartão inválido',
                                                                            expiryError: {
                                                                                invalidExpiryDate: 'Data de expiração inválida',
                                                                                monthOutOfRange: 'Mês de expiração inválida',
                                                                                yearOutOfRange: 'Ano de expiração inválida',
                                                                                dateOutOfRange: 'A data de expiração não pode ser anterior a de hoje'
                                                                            },
                                                                            invalidCvc: 'Código do CVV Inválido',
                                                                            invalidZipCode: 'CEP inválido',
                                                                            cardNumberPlaceholder: 'Número do cartão',
                                                                            expiryPlaceholder: 'MM/AA',
                                                                            cvcPlaceholder: 'CVV',
                                                                            zipPlaceholder: 'CEP'
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12 my-2">
                                                                    <label>Nome como aparece no cartão</label>
                                                                    <input type="text" onChange={(e) => this.setState({ nameCardUpdate: e.target.value })} value={this.state.nameCardUpdate} className="form-control" required />
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                } */}
                                                <div className="col-lg-12 mt-2">
                                                    <div className='mt-2'>
                                                        <AlertSuccess tag="Novidade">
                                                            <span>Aplique um cupom de desconto na sua assinatura.</span>
                                                            <div className="col-lg-6 mt-2">
                                                                <input type="text" placeholder='Cupom de desconto' onBlur={this.simulateSubscription} onChange={(e) => this.setState({ coupon: e.target.value?.toUpperCase() })} value={this.state.coupon} className="form-control" required />
                                                            </div>
                                                        </AlertSuccess>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.formaCobranca === "SELFPARKING" && isAdministrador() && <>
                                                        <div className="col-lg-12 mt-2">
                                                            <div className='alert alert-danger'>
                                                                <strong>Visivel apenas para administradores</strong> <br />
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input text-success" id="sendFirstCharge" checked={this.state.sendFirstCharge} onChange={(e) => this.setState({ sendFirstCharge: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="sendFirstCharge">
                                                                        <span>Enviar link de cobrança imediatamente.</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.stateModal === 'loading' &&
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <h3 className='text-center'>Processando informações de pagamentos...</h3>
                                        </div>
                                        <div className='col-lg-12'>
                                            <Loading />
                                        </div>
                                    </div>
                                }
                                {
                                    (this.state.stateModal === 'success' || this.state.stateModal === 'pending') &&
                                    <div className='row'>
                                        <div className='col-lg-12 text-center'>
                                            <img width='150' height='150' src={require('../../files/icons/icon_success_check.png')} />
                                        </div>
                                        <div className='col-lg-12'>
                                            <h5 className='text-center'>Assinatura efetuada com sucesso.</h5>
                                            <h6 className='text-center'>Em breve você receberá o link de cobrança.</h6>
                                        </div>
                                    </div>
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    {
                                        (this.state.stateModal === 'success' || this.state.stateModal === 'pending') &&
                                        <button onClick={(e) => { window.location.href = "/licenca/minha-licenca" }} className='btn btn-secondary'>Fechar</button>
                                    }
                                    {
                                        this.state.stateModal === 'fill' && <>
                                            <button onClick={this.reloadPage} className='btn btn-secondary'>Fechar</button>
                                            <button onClick={this.createSubscription} className='btn btn-success'>Assinar 🥳</button>
                                        </>
                                    }
                                </div>
                            </MuiDialogActions>
                        </Dialog>
                    </div>
                    <Dialog onClose={this.closeCancelModal} fullWidth maxWidth='xs' open={this.state.isOpenCancelModal}>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h6>
                                        Para prosseguir com o cancelamento, solicite a um de nossos atendentes através do WhatsApp nesta mesma página. <p />
                                        Em até 48 horas úteis, o departamento responsável dará segmento a sua solicitação.
                                    </h6>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <a href='https://wa.me/message/G47IZWCLOI57H1' onClick={(e) => sendClickButton("Licença", "Abrir WhatsApp para cancelamento")} className='btn btn-secondary' target='_blank' >Abrir WhatsApp</a>
                            <button onClick={this.closeCancelModal} className='btn btn-success'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='lg' open={this.state.isOpenModalCurrentPlan}>
                        <MuiDialogContent>
                            <div className='row'>
                                <ItemPlan plan={this.state.current?.plano} />
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <button onClick={(e) => this.setState({ isOpenModalCurrentPlan: false })} className='btn btn-secondary'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='lg' open={this.state.isShowPaymentHistoryModal} onClose={(e) => this.setState({ isShowPaymentHistoryModal: false })}>
                        <MuiDialogContent>
                            <ListarLicencaHistorico estacionamentoId={this.state.estacionamentoId} read={true} />
                        </MuiDialogContent>
                        <MuiDialogActions className='m-3'>
                            <button onClick={(e) => this.setState({ isShowPaymentHistoryModal: false })} className='btn btn-secondary'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar >
            </>
        )
    }
}

class ItemPlan extends React.Component {

    constructor(props) {
        super(props);
        const plan = props.plan
        var valor = 0
        var tipo = ""
        if (plan.mensal) {
            valor = plan.valorMensal
            tipo = "Mês"
        } else if (plan.bimestral) {
            valor = plan.valorBimestral
            tipo = "Bimestre"
        } else if (plan.trimestral) {
            valor = plan.valorTrimestral
            tipo = "Trimestre"
        } else if (plan.semestral) {
            valor = plan.valorSemestral
            tipo = "Semestre"
        } else if (plan.anual) {
            valor = plan.valorAnual
            tipo = "Ano"
        }
        const data = {
            ...plan,
            valor: valor,
            tipo: tipo,
        }
        this.state = data
    }

    render() {
        return (
            <>
                <div className="card mb-2">
                    <div className="card-body">
                        <h5 className="card-title">{this.state.nome}</h5>
                        <span className="my-2">{this.state.descricao}</span> <br />
                        {
                            (this.state.esconderValores ?? true) && <>
                                <small>A partir de</small>
                                <h4>
                                    <span className="font-700 color-base2">
                                        {toCurrency(this.state.valor)}
                                        <span> /{this.state.tipo}</span>
                                    </span>
                                </h4>
                            </>
                        }
                        {this.state.destaque === "sim" && <h5><span className="badge text-bg-success">Plano escolhido pela maioria</span></h5>}
                    </div>
                    <ul className="list-group list-group-flush">
                        {this.state.diferenciais?.split(",")?.map(item => <li className="list-group-item">{item}</li>)}
                        {this.state.destaques !== "" && <span>{this.state.destaques?.split(",")?.map(item => <li className="list-group-item"><strong className='text-success'>{item}</strong></li>)}</span>}
                        <li className="list-group-item"><strong className='text-success'>Até {this.state.quantidadeVeiculosMes} Veículos lançados no mês</strong></li>
                        <li className="list-group-item"><strong className='text-success'>Até {this.state.quantidadeNotasMes} Notas emitidas no mês</strong></li>
                        <li className="list-group-item"><strong className='text-success'>Até {this.state.quantidadeMensagensMes ?? 20} Tickets via WhatsApp por mês</strong>
                            <span className="badge text-bg-success mx-2">Novidade</span>
                        </li>
                        <li className="list-group-item"><strong className='text-success'>Acesso para até {this.state.quantidadeUsuariosAtivos} usuários</strong></li>
                    </ul>
                    {
                        this.props.onSelected &&
                        <div className="card-footer">
                            <button onClick={(e) => this.props.onSelected(this.state)} className={`btn btn-block ${this.state.destaque ? 'btn-success' : 'btn-secondary'} `}>Assinar plano</button>
                        </div>
                    }
                </div>
            </>)
    }
}

class LicencaDashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showBanner: (window.location.href.indexOf("licenca") === -1 && window.location.href.indexOf("revenda") === -1 && window.location.href.indexOf("plataforma") === -1),
            currentLicence: getLicencaAtual()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.showBanner && this.state.currentLicence?.status === "PENDING" && (this.state.currentLicence.paymentMethod === "banksplit" || this.state.currentLicence.paymentMethod === "card") &&
                    <div className="col-12 mb-3">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="card text-white bg-warning">
                                    {
                                        this.state.currentLicence.paymentMethod === "banksplit" &&
                                        <>
                                            <div className="card-body">
                                                <h5 className="card-title text-white">Informação</h5>
                                                <h6 className="card-title text-white">
                                                    Ainda não identificamos o pagamento da assinatura deste estabelecimento. Caso já tenha pago desconsidere este alerta.
                                                </h6>
                                            </div>
                                            <div className="card-footer">
                                                {
                                                    this.state.currentLicence.url ?
                                                        <a href={this.state.currentLicence.url} target='_blank'>
                                                            <button className='btn btn-warning text-white'>Visualizar cobrança</button>
                                                        </a> :
                                                        <a href="/licenca/minha-licenca?type=charges-modal">
                                                            <button className='btn btn-warning text-white'>Verificar dados</button>
                                                        </a>
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.currentLicence.paymentMethod === "card" &&
                                        <>
                                            <div className="card-body">
                                                <h5 className="card-title text-white">Informação</h5>
                                                <h6 className="card-title text-white">
                                                    Ainda não identificamos a renovação por cartão de crédito deste estabelecimento, configura se todos os dados de pagamento estão correto.
                                                </h6>
                                            </div>
                                            <div className="card-footer">
                                                <a href="/licenca/minha-licenca?type=update-card">
                                                    <button className='btn btn-warning text-white'>Atualizar dados</button>
                                                </a>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>)
    }
}

class LicencaRenvendasDashboard extends React.Component {

    render() {
        return (
            <NavBar>
                <LicencaRenvendasSimplify showBillButton={true} showProjection={true} />
            </NavBar>)
    }
}

class LicencaRenvendasSimplify extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            cobrancas: [],
            startDate: new Moment(new Date()),
            isAdmin: isAdministrador()
        }
        this.loadPeriod()
    }

    loadPeriod = async () => {
        this.setState({ isLoading: true })
        const option = {
            method: "POST",
            body: {
                date: this.state.startDate.format("MM/yyyy")
            }
        }
        this.setState({ cobrancas: [] })
        const result = await fetchFunction("/newSubscription/revenda/exist-by-date", option)
        this.setState({ isLoading: false })
        if (result.success) {
            this.setState({ cobrancas: result.data.sort((a, b) => b.status.localeCompare(a.status)) })
        }
    }

    handleStartDateChange = (newValue) => {
        this.setState({ startDate: newValue })
    }

    render() {
        return (
            <>
                {
                    this.props.showProjection &&
                    <LicencaRenvendasProjection />
                }
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span>Cobranças de assinaturas</span>
                        <div className='col-lg-2'>
                            <MobileDatePicker
                                label="Período"
                                inputFormat="MM/yyyy"
                                value={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                onAccept={this.loadPeriod}
                                views={['month', 'year']}
                                renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                            />
                        </div>
                    </div>
                    {
                        this.state.cobrancas?.length === 0 && this.state.isLoading === false &&
                        <EmptyContent text={`Nenhuma cobrança existente para o período de ${this.state.startDate.format("MM/yyyy")}`} />
                    }
                    {
                        this.state.isLoading && <Loading />
                    }
                    {
                        !this.state.isLoading && <LicencaRenvendasProjectionTable selectedChanges={this.state.cobrancas} key={this.state.cobrancas} />
                    }
                </div>
                <Dialog maxWidth={'md'} onClose={(e) => this.setState({ isShowDetailModal: false })} open={this.state.isShowDetailModal}>
                    <MuiDialogContent>
                        <ListarLicencaHistorico estacionamentoId={this.state.detail?.estacionamentoId} />
                    </MuiDialogContent>
                </Dialog>
                <Dialog maxWidth="md" open={this.state.isShowBill}>
                    <MuiDialogTitle className='text-center'>
                        Reenviar cobranças
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <h5>Deseja mesmo cobrar novamente todos <br /> os clientes pendentes via WhatsApp?</h5> <br />
                        <h5>{this.state.messageRetryBill}</h5>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowBill: false })} className='btn btn-secondary'>Cancelar</button>
                            <button onClick={(e) => this.generateNewBills()} className='btn btn-danger'>Sim, confirmo</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>)
    }
}

class LicencaRenvendasProjectionTable extends React.Component {

    constructor(props) {
        super(props);
        const isAdmin = isAdministrador()
        const selectedChanges = (props.selectedChanges ?? []).filter(e => e.status !== "CANCELED")
        const payeds = selectedChanges.filter(e => e.status === 'PAYED')
        const generated = selectedChanges.filter(e => e.status === 'GENERATED')
        const pending = selectedChanges.filter(e => e.status === 'PENDING')
        const revenda = getRevenda()
        this.state = {
            formaCobranca: revenda?.formaCobranca,
            isAdmin: isAdmin,
            estacionamentoId: props.estacionamentoId,
            changeDateBySubscription: true,
            habilitarEmissaoNotaFiscal: revenda?.habilitarEmissaoNotaFiscal,
            generateNote: revenda?.habilitarEmissaoNotaFiscal,
            notifyClient: false,
            generateBanksplit: false,
            sendBanksplit: false,

            currentNewSubscriptionId: props.currentNewSubscriptionId,
            payedChangesSumTotal: selectedChanges.map(e => e.netValue).reduce((a, b) => a + b, 0),
            payedChangesSumQuantity: selectedChanges.length,

            selectedChanges: selectedChanges.sort((a, b) => a.changeDate - b.changeDate),
            payedChangesTotal: payeds.map(e => e.netValue).reduce((a, b) => a + b, 0),
            payedChangesQuantity: payeds.length,

            generatedChangesTotal: generated.map(e => e.netValue).reduce((a, b) => a + b, 0),
            generatedChangesQuantity: generated.length,

            pendingChangesTotal: pending.map(e => e.netValue).reduce((a, b) => a + b, 0),
            pendingChangesQuantity: pending.length,
            changesItems: [],
            semPararTotal: 0,
            semPararQuantity: 0
        }
        this.loadSemParar()
    }

    loadSemParar = async () => {
        const revenda = getRevenda()
        const parameters = await getParametersWithSemPararActivated({ revendaId: revenda?.id })
        const total = parameters.filter(e => e.codigoEstabelecimentoSemParar != revenda.tagSemPararCodigoEstabelecimentoTeste).length
        this.setState({ semPararTotal: total * revenda.tagSemPararRecebimento })
        this.setState({ semPararQuantity: total })
        this.setState({ payedChangesSumTotal: this.state.payedChangesSumTotal + total })
    }

    renderStatus(status) {
        return (
            <>
                {status === 'PAYED' && <h5><span className="badge text-bg-success w-100">Pago</span></h5>}
                {status === 'GENERATED' && <h5><span className="badge text-bg-secondary w-100">Aguardando</span></h5>}
                {status === 'PENDING' && <h5><span className="badge text-bg-danger w-100">Pendente</span></h5>}
                {status === 'CANCELED' && <h5><span className="badge text-bg-danger w-100">Cancelado</span></h5>}
            </>
        )
    }

    renderDate(item) {
        const changeDate = Moment(item.changeDate._seconds * 1000)
        const newLicenceDate = Moment(item.newLicenceDate ? item.newLicenceDate._seconds * 1000 : item.changeDate._seconds * 1000)
        if (changeDate.isBefore(Moment(), "day")) {
            return <span>{changeDate.format("DD/MM/YYYY")}</span>
        } else {
            return <Tooltip title={`Nova data da licença ao efetuar o pagamento: ${newLicenceDate.format("DD/MM/YYYY")}`} placement="top">
                <span>{changeDate.format("DD/MM/YYYY")}</span>
            </Tooltip>
        }
    }

    showWarnings = (item) => {
        this.setState({ changesItems: item.warnings })
        this.setState({ isOpenChangesModal: true })
    }

    showChangeDate = (item) => {
        this.setState({ changeSelected: item })
        this.setState({ netValue: item.netValue })
        this.setState({ paymentMethod: item.paymentMethod })
        this.setState({ dataVencimento: moment(item.changeDate._seconds * 1000) })
        this.setState({ newLicenceDate: moment(item.newLicenceDate._seconds * 1000) })
        this.setState({ statusSelected: item.status })
        this.setState({ isOpenChangeDateModal: true })
    }

    showUse = async (item) => {
        console.log(item);
        this.setState({ changeSelected: item })
        this.setState({ isOpenUseModal: true })
        this.setState({ isOpenUseLoading: true })
        const option = {
            method: "GET"
        }
        const response = await fetchFunction(`/analytics/use/licence/${item.id}`, option)
        this.setState({ analyticsUse: response.data[0].analytics[0] })
        this.setState({ isOpenUseLoading: false })
    }

    generateNewNote = async (item) => {
        const confirm = window.confirm(`Deseja mesmo emitir a nota fiscal para esta cobrança?`);
        if (confirm) {
            const option = {
                method: "POST"
            }
            const result = await fetchFunction(`/newSubscription/generateNote/${item.id}`, option)
            if (result.success) {
                reloadWithAlert(result.message)
            } else {
                toastWarning(result.message)
            }
        }
    }

    removeCharge = async (item) => {
        const confirm = window.confirm(`Deseja mesmo remover esta cobrança?`);
        if (confirm) {
            await deleteNewSubscriptionItem({ id: item.id })
            this.setState({ selectedChanges: this.state.selectedChanges.filter(e => e.id !== item.id) })
        }
    }

    hideChangeDate = () => {
        this.setState({ isChangeDateLoading: false })
        this.setState({ isOpenChangeDateModal: false })
    }

    confirmChangeDate = async () => {
        if (this.state.sendBanksplit && this.state.dataVencimento.isBefore(new Date())) {
            toastWarning("A data de vencimento do boleto não pode ser menor que a data atual.")
            return
        }
        const body = {
            changeDate: this.state.dataVencimento.format("DD/MM/YYYY"),
            netValue: this.state.netValue,
            status: this.state.statusSelected,
            reason: this.state.reason,
            changeDateBySubscription: (this.state.statusSelected === "PAYED" ? this.state.changeDateBySubscription ?? false : false),
            generateNote: (this.state.statusSelected === "PAYED" ? this.state.generateNote ?? false : false),
            sendLink: (this.state.statusSelected === "PAYED" ? false : this.state.sendLink),
            notifyClient: (this.state.statusSelected === "PAYED" ? this.state.notifyClient ?? false : false)
        }
        const option = {
            method: "PUT",
            body: body
        }
        this.setState({ isChangeDateLoading: true })
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.changeSelected.parksIds[0] })
        this.hideChangeDate()
        const result = await fetchFunction(`/newSubscription/update-change-date/${this.state.changeSelected.id}`, option, parameters.X_API_KEY)
        if (result.success) {
            sendLogByUser("Licença", `Alterou os dados da cobrança.`, body)
            toastSuccess(result.message)
        } else {
            toastWarning(result.message)
        }
    }

    handleDataFaturatDateChange = (newValue) => {
        this.setState({ dataVencimento: newValue })
    }

    updateNetValue = (event, maskedvalue, floatvalue) => {
        this.setState({ netValue: floatvalue })
    }

    changeStatus = (target) => {
        const status = target.value
        this.setState({ statusSelected: status })
        var changeSelected = this.state.changeSelected
        changeSelected.status = status
        this.setState({ changeSelected: changeSelected })
    }

    changePaymentMethod = (target) => {
        const paymentMethod = target.value
        var changeSelected = this.state.changeSelected
        changeSelected.paymentMethod = paymentMethod
        this.setState({ paymentMethod: paymentMethod })
        if (paymentMethod === "card") {
            this.setState({ generateBanksplit: false })
            this.setState({ sendBanksplit: false })
        }
    }

    sendBillByWhatsApp = async (change) => {
        const confirm = window.confirm(`Deseja mesmo enviar esta cobrança para o responsável?`);
        if (confirm) {
            const client = await getClientById({ id: change.clienteId })
            const body = {
                template: "PARK_CHARGE",
                phone: client.celular,
            }
            const options = {
                method: 'POST',
                body: body
            };
            await fetchFunctionCall("/sendWhatsAppMessageWithTemplate", options)
        }
    }

    showCharge = (item) => {
        const revenda = getRevenda()
        const url = `${revenda.siteTicket}/assinatura/estabelecimento/detalhes?id=${item.id}`
        goToNewWindow(url)
    }

    forceCardPurchase = async (change) => {
        const confirm = window.confirm(`Deseja mesmo efetuar a cobrança com o cartão principal cadastrado?`);
        if (confirm) {
            const body = {
                changeId: change.id,
            }
            const options = {
                method: 'POST',
                body: body
            };
            const result = await fetchFunction("/newSubscription/purchaseByCard", options)
            if (result.success) {
                reloadWithAlert(result.message)
            } else {
                toastWarning(result.message)
            }
        }
    }

    showPayedChanges = async () => {
        const confirm = window.confirm(`Deseja mesmo exportar os assinantes ativos?`)
        if (!confirm) {
            return
        }
        const map = [...new Set(this.state.selectedChanges.map(e => e.clienteId))]
        toastLoading("Buscando assinantes...")
        var items = []
        for (const item of map) {
            const client = await getClientById({ id: item })
            toastLoading(client.nome)
            const parks = await getAllParksByClientId({ clientId: item })
            items.push({
                unidades: parks.length,
                valorAssinaturas: this.state.selectedChanges.filter(e => e.clienteId === item).map(e => e.netValue).reduce((a, b) => a + b, 0),
                ...client
            })
        }
        toastLoading("Exportando...")
        var exported = items.map(e => {
            const imposto = e.valorAssinaturas / 100 * 5.65
            const totalLiquido = e.valorAssinaturas - imposto
            const revendaTotal = totalLiquido / 2
            const selfGroupTotal = totalLiquido / 2
            return {
                "Nome matriz": e.nome,
                "Representante": e.representante,
                "Email": e.email,
                "Telefone": e.celular,
                "Documento": e.documento,
                "Data de cadastro": Moment(e.createdAt.seconds * 1000).format("DD/MM/YYYY"),
                "Unidades": e.unidades,
                "Valor assinatura(s)": e.valorAssinaturas,
                "CEP": e.endereco.cep,
                "Endereço": e.endereco?.logradouro,
                "Número": e.endereco?.numero,
                "Bairro": e.endereco?.bairro,
                "Cidade": e.endereco?.cidade,
                "Estado": e.endereco?.estado,
                "Complemento": e.endereco?.complemento,
                "Impostos": imposto.toFixed(2),
                "Total Liquido": totalLiquido.toFixed(2),
                "Revenda": revendaTotal.toFixed(2),
                "SelfGroup": selfGroupTotal.toFixed(2),
            }
        })
        exportAndDownloadXLS(exported, `Assinantes`, ";")
        toastDismissLoading()
    }

    showSemPararAssociateds = async () => {
        if (!this.state.parksSemParar) {
            toastLoading("Buscando estabelecimentos com sem parar ativo...")

            const revenda = getRevenda()
            const parameters = await getParametersWithSemPararActivated({ revendaId: revenda?.id })
            const allowed = parameters.filter(e => e.codigoEstabelecimentoSemParar !== revenda.tagSemPararCodigoEstabelecimentoTeste)
            var parks = []
            for (const item of allowed) {
                const park = await getParkById({ id: item.estacionamentoId })
                if (park) {
                    toastLoading(`Buscando estabelecimento\n${park.nomeInterno ?? park.nome}...`)
                    parks.push({
                        ...park,
                        ...item
                    })
                }
            }
            this.setState({ parksSemParar: parks.sort((a, b) => (a.nome).localeCompare(b.nome)) })
            toastDismissLoading()
        }
        this.setState({ isOpenSemPararAssociatedsModal: true })
    }

    exportSemParar = async () => {
        const rows = this.state.parksSemParar.map(e => {
            return {
                "Documento": e.documento,
                "Nome": `${e.nome} ${e.nomeInterno}`,
                "Codigo estabelecimento": e.codigoEstabelecimentoSemParar
            }
        })
        exportAndDownloadXLS(rows, `SEM_PARAR_MEDICAO_${Moment().format("YYYY_MM")}`, ",")
    }

    render() {
        return (<>
            {
                this.state.selectedChanges.length > 0 ?
                    <div>
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td width={10}></td>
                                        <td className="d-none d-sm-table-cell"><strong>Estabelecimentos</strong></td>
                                        <td className="d-none d-sm-table-cell"><strong>Plano</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Frequência</strong></td>
                                        <td align='center'><strong>Vencimento</strong></td>
                                        <td align='right'><strong>Valor</strong></td>
                                        <td width={10}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.selectedChanges?.map(item =>
                                            <tr key={item.id} className={item.id === this.state.currentNewSubscriptionId && 'bg-warning text-white'}>
                                                <td width={10}>
                                                    {this.renderStatus(item.status)}
                                                </td>
                                                <td className="d-none d-sm-table-cell">
                                                    <Tooltip title={`${item.id} - ${item.groupId}`} placement="top">
                                                        <span>{valueByMaximunChars(item.parks.map(e => e.nome).join(", "), 50)}</span>
                                                    </Tooltip>
                                                </td>
                                                <td className="d-none d-sm-table-cell">
                                                    {item.plan?.nome}
                                                </td>
                                                <td className="d-none d-sm-table-cell" align='center'>
                                                    {item.frequence}
                                                </td>
                                                <td align='center'>{this.renderDate(item)}</td>
                                                <td align='right'>
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.netValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </td>
                                                <td align='right'>
                                                    <div className="btn-group">
                                                        {
                                                            this.state.isAdmin && item.warnings?.length > 0 &&
                                                            <Tooltip title="Visualizar mudanças" placement="top">
                                                                <button type="button" onClick={(e) => this.showWarnings(item)} className="btn btn-warning text-white">
                                                                    <i className="fas fa-list" />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        <Tooltip title="Visualizar cobrança" placement="top">
                                                            <button onClick={(e) => this.showCharge(item)} type="button" className="btn btn-info text-white">
                                                                <i className="fas fa-external-link-square-alt" />
                                                            </button>
                                                        </Tooltip>
                                                        {
                                                            item.status !== "PAYED" && item.status !== "CANCELED" &&
                                                            <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                <button type="button" onClick={() => { this.sendBillByWhatsApp(item) }} className="btn btn-success">
                                                                    <i className="fa-brands fa-whatsapp" />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            item.paymentMethod === "card" &&
                                                            <>
                                                                {
                                                                    item.status === "PENDING" &&
                                                                    <Tooltip title="Efetuar cobrança" placement="top">
                                                                        <button type="button" onClick={() => { this.forceCardPurchase(item) }} className="btn btn-success">
                                                                            <i className="fas fa-credit-card" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            item.newNote?.currentStatus === "NOTE_GENERATED" &&
                                                            <Tooltip title="Visualizar PDF" placement="top">
                                                                <a href={item.newNote.pdf_nfse} target='_blank' className="btn btn-success">
                                                                    <i className="fas fa-file-pdf" />
                                                                </a>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            item.newNote?.currentStatus === "NOTE_PROCESSING" &&
                                                            <Tooltip title="Nota em processamento" placement="top">
                                                                <button className="btn btn-secondary">
                                                                    <i className="fas fa-file-alt" />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            (item.status === "PAYED" && !item.newNote && item.netValue > 0) &&
                                                            <Tooltip title="Emitir nota" placement="top">
                                                                <button onClick={(e) => this.generateNewNote(item)} target='_blank' className="btn btn-secondary">
                                                                    <i className="fas fa-file-pdf" />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            this.state.isAdmin &&
                                                            <>
                                                                <ClientButton item={item} />
                                                                <Tooltip title="Visualizar consumo" placement="top">
                                                                    <button type="button" onClick={(e) => this.showUse(item)} className="btn btn-info text-white">
                                                                        <i className="fas fa-sitemap" />
                                                                    </button>
                                                                </Tooltip>
                                                                {
                                                                    item.status !== "PAYED" &&
                                                                    <>
                                                                        <Tooltip title="Editar cobrança" placement="top">
                                                                            <button type="button" onClick={(e) => this.showChangeDate(item)} className="btn btn-secondary">
                                                                                <i className="fas fa-edit" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Remover cobrança" placement="top">
                                                                            <button onClick={(e) => this.removeCharge(item)} target='_blank' className="btn btn-danger">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <Dialog maxWidth='md' onClose={(e) => this.setState({ isOpenSemPararAssociatedsModal: false })} fullWidth open={this.state.isOpenSemPararAssociatedsModal} >
                                <MuiDialogTitle className='text-center'>
                                    Estabelecimentos com sem parar ativo
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='card-body'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td><strong>Nome</strong></td>
                                                    <td align='center'><strong>Documento</strong></td>
                                                    <td align='center'><strong>Código</strong></td>
                                                    <td width={10}></td>
                                                </tr>
                                            </thead>
                                            <tbody className='table-responsive'>
                                                {
                                                    this.state.parksSemParar?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <Tooltip title={item.id} placement="top">
                                                                    <span>{item.nome} {item.nomeInterno}</span>
                                                                </Tooltip>
                                                            </td>
                                                            <td align='center'>{documentMask(item.documento)}</td>
                                                            <td align='center'>{item.codigoEstabelecimentoSemParar}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <Tooltip role="button" title="Visualizar estabelecimento" placement="top">
                                                                        <a href={`/revenda/cliente/${item.clienteId}?e=${item.estacionamentoId}`} target="_blank" className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </Tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={this.exportSemParar} className='btn btn-success'>
                                            <i className="fas fa-table mx-2" />
                                            Exportar
                                        </button>
                                        <button onClick={(e) => this.setState({ isOpenSemPararAssociatedsModal: false })} className='btn btn-secondary'>Fechar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog maxWidth='xs' onClose={this.hideChangeDate} fullWidth open={this.state.isOpenChangeDateModal} >
                                <MuiDialogTitle className='text-center'>
                                    Detalhes da cobrança
                                </MuiDialogTitle>
                                {
                                    this.state.isChargeDetailLoading ?
                                        <Loading />
                                        :
                                        <>
                                            <MuiDialogContent>

                                            </MuiDialogContent>
                                            <MuiDialogActions>
                                                <div className='btn-group m-3'>
                                                    <button onClick={this.hideChangeDate} className='btn btn-secondary'>Fechar</button>
                                                </div>
                                            </MuiDialogActions>
                                        </>
                                }
                            </Dialog>
                            <Dialog maxWidth='xs' onClose={this.hideChangeDate} fullWidth open={this.state.isOpenChangeDateModal} >
                                <MuiDialogTitle className='text-center'>
                                    Alterar dados da cobrança
                                </MuiDialogTitle>
                                {
                                    this.state.isChangeDateLoading ?
                                        <Loading />
                                        :
                                        <>
                                            <MuiDialogContent>
                                                <div className="col-lg-12">
                                                    <MobileDatePicker
                                                        label="Data"
                                                        inputFormat="DD/MM/yyyy"
                                                        value={this.state.dataVencimento}
                                                        onChange={this.handleDataFaturatDateChange}
                                                        renderInput={(params) => <TextField {...params} size="small" className='form-control w-100' />}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Valor</label>
                                                    <CurrencyInput value={this.state.netValue} prefix="R$" className='form-control' onChangeEvent={this.updateNetValue} />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Status</label>
                                                    <select className='form-select' value={this.state.statusSelected} onChange={(e) => this.changeStatus(e.target)}>
                                                        <option value="GENERATED">Aguardando</option>
                                                        <option value="PENDING">Pendente</option>
                                                        <option value="PAYED">Pago</option>
                                                        <option value="CANCELED">Cancelado</option>
                                                    </select>
                                                </div>
                                                {/* <div className="col-lg-12">
                                                    <label>Método de pagamento</label>
                                                    <select className='form-select' value={this.state.paymentMethod} onChange={(e) => this.changePaymentMethod(e.target)}>
                                                        <option value="banksplit">Boleto</option>
                                                        <option value="card">Cartão de crédito</option>
                                                    </select>
                                                </div> */}
                                                <div className='col-lg-12'>
                                                    <label>Motivo</label>
                                                    <textarea rows='4' onChange={(e) => this.setState({ reason: e.target.value })} value={this.state.reason} className="form-control" />
                                                </div>
                                                {
                                                    this.state.formaCobranca === "SELFPARKING" && <>
                                                        {
                                                            this.state.changeSelected?.status === "PENDING" && <>
                                                                {
                                                                    this.state.changeSelected?.paymentMethod === "banksplit" &&
                                                                    <>
                                                                        {/* <div className="col-lg-12">
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="generateBanksplit" checked={this.state.generateBanksplit} onChange={(e) => this.setState({ generateBanksplit: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="generateBanksplit">
                                                                                    Gerar um novo boleto para o cliente
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.generateBanksplit && */}
                                                                        <div className="col-lg-12">
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="sendLink" checked={this.state.sendLink} onChange={(e) => this.setState({ sendLink: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="sendLink">
                                                                                    Enviar link de cobrança para o cliente
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        {/* } */}
                                                                    </>
                                                                }
                                                                {
                                                                    this.state.changeSelected?.paymentMethod === "card" &&
                                                                    <div className='mt-3'>
                                                                        <AlertDanger message="O cliente deve ter cadastrado um cartão de crédito para realizar a cobrança." />
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {
                                                    this.state.statusSelected === "PAYED" &&
                                                    <div className="col-lg-12 mt-3">
                                                        <div className='alert alert-success'>
                                                            <div className="form-check form-switch my-2">
                                                                <input className="form-check-input" id="changeDateBySubscription" checked={this.state.changeDateBySubscription} onChange={(e) => this.setState({ changeDateBySubscription: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="changeDateBySubscription">
                                                                    Alterar data de vencimento da licença para <strong>{this.state.newLicenceDate.format("DD/MM/YYYY")}</strong>.
                                                                </label>
                                                            </div>
                                                            {
                                                                this.state.habilitarEmissaoNotaFiscal && this.state.netValue > 0 &&
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="generateNote" checked={this.state.generateNote} onChange={(e) => this.setState({ generateNote: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="generateNote">
                                                                        Emitir nota fiscal para este pagamento.
                                                                    </label>
                                                                </div>
                                                            }
                                                            <div className="form-check form-switch my-2">
                                                                <input className="form-check-input" id="notifyClient" checked={this.state.notifyClient} onChange={(e) => this.setState({ notifyClient: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="notifyClient">
                                                                    Notificar cliente sobre o registro do pagamento.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </MuiDialogContent>
                                            <MuiDialogActions>
                                                <div className='btn-group m-3'>
                                                    <button onClick={this.hideChangeDate} className='btn btn-secondary'>Fechar</button>
                                                    <button onClick={this.confirmChangeDate} className='btn btn-success'>Confirmar</button>
                                                </div>
                                            </MuiDialogActions>
                                        </>
                                }
                            </Dialog>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-primary" role='button' onClick={(e) => this.showPayedChanges()}>
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Total</h5>
                                        <h3 className="card-text text-center text-white">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.payedChangesSumTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        </h3>
                                        <h5 className='monthly-card monthly-card-blue'>
                                            <span>{this.state.payedChangesSumQuantity}</span>
                                        </h5>
                                        <div className="text-center">
                                            <small className="card-text text-white">Cobranças do mês</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-success">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Total pago</h5>
                                        <h3 className="card-text text-center text-white">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.payedChangesTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        </h3>
                                        <h5 className='monthly-card monthly-card-green'>
                                            <span>{this.state.payedChangesQuantity}</span>
                                        </h5>
                                        <div className="text-center">
                                            <small className="card-text text-white">Cobranças já pagas</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-danger">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Total pendente</h5>
                                        <h3 className="card-text text-center text-white">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.pendingChangesTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        </h3>
                                        <h5 className='monthly-card monthly-card-red'>
                                            <span>{this.state.pendingChangesQuantity}</span>
                                        </h5>
                                        <div className="text-center">
                                            <small className="card-text text-white">Cobranças não pagas</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.isAdmin &&
                                <div className='col-lg-3'>
                                    <div className="card text-light bg-secondary">
                                        <div className="card-body">
                                            <h5 className="card-title text-center text-white">Total aguardando</h5>
                                            <h3 className="card-text text-center text-white">
                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.generatedChangesTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                            </h3>
                                            <h5 className='monthly-card monthly-card-gray'>
                                                <span>{this.state.generatedChangesQuantity}</span>
                                            </h5>
                                            <div className="text-center">
                                                <small className="card-text text-white">Cobranças em vencimento</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                isSelfParking() && isNullOrEmpty(this.state.estacionamentoId) &&
                                <div className='col-lg-3 mt-3'>
                                    <div className="card text-light bg-success" role='button' onClick={() => this.showSemPararAssociateds()}>
                                        <div className="card-body">
                                            <h5 className="card-title text-center text-white">Sem Parar</h5>
                                            <h3 className="card-text text-center text-white">
                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.semPararTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                            </h3>
                                            <h5 className='monthly-card monthly-card-gray'>
                                                <span>{this.state.semPararQuantity}</span>
                                            </h5>
                                            <div className="text-center">
                                                <small className="card-text text-white">Clientes com sem parar ativo</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <Dialog maxWidth={"sm"} fullWidth onClose={(e) => this.setState({ isOpenUseModal: false })} open={this.state.isOpenUseModal}>
                            <MuiDialogTitle className='text-center'>
                                Limites
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h5>Limites do plano</h5>
                                            <label>Notas</label><br />
                                            <h5>{this.state.changeSelected?.plan?.quantidadeNotasMes ?? 0}</h5>
                                            <label>Mensagens</label><br />
                                            <h5>{this.state.changeSelected?.plan?.quantidadeMensagensMes ?? 0}</h5>
                                            <label>Veículos</label><br />
                                            <h5>{this.state.changeSelected?.plan?.quantidadeVeiculosMes ?? 0}</h5>
                                        </div>
                                        <div className='col-lg-6'>
                                            {
                                                this.state.isOpenUseLoading ? <Loading /> : <>
                                                    <h5>Uso do mês</h5>
                                                    <label>Notas</label><br />
                                                    <h5>{this.state.analyticsUse?.notes?.quantity ?? 0}</h5>
                                                    <label>Mensagens</label><br />
                                                    <h5>{this.state.analyticsUse?.messages?.quantity ?? 20}</h5>
                                                    <label>Veículos</label><br />
                                                    <h5>{this.state.analyticsUse?.tickets?.quantity ?? 0}</h5>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isOpenUseModal: false })} className='btn btn-secondary'>Fechar</button>
                                    {/* <button onClick={this.saveNewUse} className='btn btn-danger'>Confirmar</button> */}
                                </div>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog maxWidth={"md"} fullWidth onClose={(e) => this.setState({ isOpenChangesModal: false })} open={this.state.isOpenChangesModal}>
                            <MuiDialogTitle className='text-center'>
                                Alertas
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                {
                                    this.state.changesItems?.length === 0 ? <EmptyContent text="Nenhuma mudança disponível" /> :
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td align='center'><strong>Data e Horário</strong></td>
                                                        <td align='center'><strong>Mensagem</strong></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.changesItems?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td align='center'>{Moment(item.createdAt._seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}</td>
                                                            <td align='center'>{item.message}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isOpenChangesModal: false })} className='btn btn-secondary'>Fechar</button>
                                </div>
                            </MuiDialogActions>
                        </Dialog>
                    </div > : <EmptyContent text="Nenhuma cobrança disponível" />
            }
        </>)
    }
}

class LicencaRenvendasProjection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isEmpty: true,
            series: [],
            options: {}
        };
        this.loadProjections()
    }

    loadProjections = async () => {
        this.setState({ isLoading: true })
        const option = {
            method: "GET"
        }
        const result = await fetchFunction("/newSubscription/revenda/all-changes", option)
        if (result.success) {
            this.setState({ allChanges: result.data })
            this.setState({ isEmpty: result.data.length === 0 })
            const setList = [...new Set(result.data.map(e => `${e.month}/${e.year}`))]
            const periods = setList.map(e => Moment(e, "MM/YYYY").toDate()).sort((a, b) => a - b)
            var all = []
            var canceled = []
            var payed = []
            var generateds = []
            var pendings = []
            for (const date of periods) {
                const items = result.data.filter(e => {
                    const a = `${e.month.toString().padStart("2", "0")}/${e.year}`
                    const b = Moment(date).format("MM/YYYY")
                    return a === b
                })
                const subscriptions = items.filter(e => e.status !== "CANCELED")
                all.push({
                    quantidade: subscriptions.length,
                    valor: subscriptions.map(e => e.netValue).reduce((a, b) => a + b, 0),
                    mes: date
                })
                const payeds = items.filter(e => e.status === "PAYED")
                payed.push({
                    quantidade: payeds.length,
                    valor: payeds.map(e => e.netValue).reduce((a, b) => a + b, 0),
                    mes: date
                })
                const generated = items.filter(e => e.status === "GENERATED")
                generateds.push({
                    quantidade: generated.length,
                    valor: generated.map(e => e.netValue).reduce((a, b) => a + b, 0),
                    mes: date
                })
                const pending = items.filter(e => e.status === "PENDING")
                pendings.push({
                    quantidade: pending.length,
                    valor: pending.map(e => e.netValue).reduce((a, b) => a + b, 0),
                    mes: date
                })
            }
            const data = [
                {
                    name: 'Assinaturas',
                    data: all.map(e => e.valor.toFixed(2)),
                    quantidades: all.map(e => e.quantidade),
                    meses: all.map(e => e.mes)
                },
                {
                    name: 'Pagos',
                    data: payed.map(e => e.valor.toFixed(2)),
                    quantidades: payed.map(e => e.quantidade),
                    meses: payed.map(e => e.mes)
                },
                {
                    name: 'Pendentes',
                    data: pendings.map(e => e.valor.toFixed(2)),
                    quantidades: pendings.map(e => e.quantidade),
                    meses: pendings.map(e => e.mes)
                },
                {
                    name: 'Aguardando',
                    data: generateds.map(e => e.valor.toFixed(2)),
                    quantidades: generateds.map(e => e.quantidade),
                    meses: generateds.map(e => e.mes)
                }
            ]
            const showChangesDetail = this.showChangesDetail
            this.setState({ series: data })
            this.setState({
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        events: {
                            click: function (event, chartContext, opts) {
                                const meses = data[opts.seriesIndex]?.meses[opts.dataPointIndex]
                                if (meses) {
                                    showChangesDetail(meses)
                                }
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 10,
                        colors: ['transparent']
                    },
                    colors: ["#0d6efd", "#21C703", "#FF1700", "#8E8D8D"],
                    xaxis: {
                        categories: periods.map(e => Moment(e).format("MM/yyyy")),
                    },
                    yaxis: {
                        title: {
                            text: 'Projeção 2 anos'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        y: {
                            formatter: function (value, opts) {
                                const quantidade = data[opts.seriesIndex].quantidades[opts.dataPointIndex]
                                return `${quantidade} assinaturas - R$${value}`
                            }
                        },

                        x: {
                            formatter: function (value) {
                                return "Toque para ver detalhes"
                            }
                        }
                    }
                },
            })
        }
        this.setState({ isLoading: false })
    }

    showChangesDetail = (date) => {
        const period = Moment(date, "MM/YYYY").format("MM/YYYY")
        const items = this.state.allChanges.filter(e => `${e.month.toString().padStart("2", "0")}/${e.year}` === period)
        this.setState({ selectedChanges: items })
        this.setState({ selectedPeriod: period })
        this.setState({ isOpenSelectedChanges: true })
    }

    hideChangesDetail = () => {
        this.setState({ isOpenSelectedChanges: false })
    }

    exportData = async () => {
        const revendaId = getRevendaIdByPark()
        const revenda = await getRevendaById({ id: revendaId })
        const data = this.state.selectedChanges.filter(e => e.status !== "CANCELED").map(e => {
            const totalComImposto = e.netValue * (revenda.imposto ?? 0) / 100
            const totalComTaxa = (e.netValue - totalComImposto) * (revenda.taxa ?? 0) / 100
            return {
                "Status": e.status === "PAYED" ? "Pago" : "Pendente",
                "Cliente": e.clienteName,
                'Vencimento': moment(e.changeDate._seconds * 1000).format("DD/MM/YYYY"),
                "Plano": e.plan?.nome,
                "Frequencia": e.frequence,
                "Forma de pagamento": e.paymentMethod === "banksplit" ? "Boleto bancário" : "Cartão de crédito",
                "Valor bruto": e.netValue.toFixed(2),
                "Imposto": `${revenda.imposto ?? 0}%`,
                "Desconto imposto": totalComImposto.toFixed(2),
                "Taxa": `${revenda.taxa}%`,
                "Repasse Revenda": totalComTaxa.toFixed(2),
                "Repasse plataforma": totalComTaxa.toFixed(2)
            }
        }).sort((a, b) => a.Status.localeCompare(b.Status))
        exportAndDownloadXLS(data, `Assinaturas_${revenda.nome}_${this.state.selectedPeriod}`, ",")
    }

    render() {
        return (
            <div className="card mb-4">
                {
                    this.state.isEmpty && !this.state.isLoading && <EmptyContent text={`Nenhuma projeção existente`} />
                }
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isEmpty && !this.state.isLoading && <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
                }
                <Dialog maxWidth="lg" fullWidth onClose={this.hideChangesDetail} open={this.state.isOpenSelectedChanges}>
                    <MuiDialogTitle className='text-center'>
                        Período selecionado de cobranças {this.state.selectedPeriod}
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <LicencaRenvendasProjectionTable selectedChanges={this.state.selectedChanges} periodSelected={this.state.periodSelected} />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={this.exportData} className='btn btn-success'>
                                <i className="fas fa-table mx-2" />
                                Exportar
                            </button>
                            <button onClick={this.hideChangesDetail} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </div>)
    }
}

export { MinhaLicenca, LicencaDashboard, LicencaRenvendasDashboard, LicencaRenvendasSimplify, LicencaRenvendasProjectionTable, ItemPlan };