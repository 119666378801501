import React from 'react';
import { NavBar } from '../../../components/navbar'
import { getEstacionamento, getRevenda, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from '../../../config/auth'
import Moment from 'moment';
import { ClientButton, EmptyContent, Loading } from '../../../components/common/commons'
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { documentMask, exportAndDownloadXLS, goToNewWindow, isValidDocument, isValidCNPJ, queryString, reloadWindow, reloadWithAlert, setPathWindow, takeIfIsNotNullOrEmpty, toastError, toastSuccess, toastWarning, isJSON, isNullOrEmpty, toastLoading, toastDismissLoading, toCurrency, valueByMaximunChars } from '../../../shared/utils';
import { sendClickButton, sendLogByUser, sendScreenView } from '../../../shared/analytics';
import { deleteNewNoteById, updateNewNoteById, getAllByRessaleId, getNewNotesInRangeByParkId, getNewNoteById } from '../../../store/collections/newNotesWorker';
import Tooltip from '@mui/material/Tooltip';
import { fetchFunction } from '../../../shared/function-request';
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import moment from 'moment';
import { getParameter } from '../../../store/collections/parameterWorker';
import { OperationSingleNoteModal } from '../../operation/singlenote';
import { AlertDanger, AlertSuccess } from '../../../shared/alert-manager';
import { searchCEP } from '../../../shared/cepWorker';
import { getChargeById } from '../../../store/collections/monthlyChagesWorker';
import { getTicketByNoteIdentifier } from '../../../store/collections/ticketWorker';
import ReactJson from 'react-json-view';

const NOTE_PROCESSING = "NOTE_PROCESSING"
const NOTE_SENT = "NOTE_SENT"
const NOTE_GENERATED = "NOTE_GENERATED"
const NOTE_CANCELED = "NOTE_CANCELED"
const NOTE_ERROR = "NOTE_ERROR"
const NOTE_CONTIGENCY = "NOTE_CONTIGENCY"

class ListarNotaNovo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isShowingLog: false,
            type: null,
            currentStatus: null,
            tomador: false,
            isNoteProcessOpen: false,
            isNoteCancelOpen: false,
            nome: "",
            documento: "",
            valor: 0.0,
            statusGenerateNote: '',
            estacionamentoId: getEstacionamento()?.id,
            isAdministrador: isAdministrador(),
            revendaId: getRevendaIdByPark(),
            id: queryString("id"),
            detail: queryString("detail"),
            notesSelected: [],
            notes: [],
            noteSelected: null,
            noteSelectedDocument: '',
            data: moment(),
            tipoDocumento: 'CPF',
            noteSelectedEmail: '',
            document: "",
            isShowingStatus: false,
            motivo: "",
            isShowLog: false,
            isShowGenerateNote: false,
            isShowingLoadingProccess: false,
            isShowGenerateManual: false,
            isShowExecuteProcessing: false,
            isShowCancelNote: false,
            currentItemLog: "",
            limit: queryString("limit") ?? 10,
            type: queryString("type") ?? "Todos",
            startDate: new Moment(new Date()),
            endDate: new Moment(new Date()),
            notes: [],
            currentItem: null,
            random: Math.random()
        }
        this.loadCurrentIfNeed()
    }

    componentDidMount = () => {
        this.load()
        const from = queryString("from")
        if (from === "email") {
            sendScreenView("Nota fiscal", "Ver todas | Por e-mail")
        } else {
            sendScreenView("Nota fiscal", "Ver todas")
        }
    }

    applyFilter = () => {
        this.load()
    }

    load = async () => {
        const startDateURL = Moment(this.state.startDate).format("MM/DD/YYYY")
        const endDateURL = Moment(this.state.endDate).format("MM/DD/YYYY")
        var id = this.state.id ?? ""
        if (!isNullOrEmpty(id)) {
            id = `&id=${id}`
        }
        var detail = this.state.detail ?? ""
        if (!isNullOrEmpty(detail)) {
            detail = `&detail=${detail}`
        }
        setPathWindow(`/nota-fiscal/listar/?startDate=${startDateURL}&endDate=${endDateURL}&type=${this.state.type}&limit=${this.state.limit}${id}${detail}`)

        this.setState({ isLoading: true });
        const notes = await getNewNotesInRangeByParkId({
            parkId: this.state.estacionamentoId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            status: this.state.currentStatus,
            type: this.state.type,
            limit: this.state.limit
        })
        this.setState({ notes: notes })
        this.setState({ auxNotes: notes })
        this.setState({ isLoading: false });

    }

    loadCurrentIfNeed = async () => {
        if (this.state.detail && this.state.id) {
            toastLoading("Carregando...")
            const item = await getNewNoteById({ id: this.state.id })
            if (item) {
                this.editNote(item)
            }
            toastDismissLoading()
        }
    }

    currentStatusChange = (event) => {
        this.setState({ currentStatus: event.target.value })
    }

    typeChange = (event) => {
        this.setState({ type: event.target.value })
    }

    cancelNote = async (item) => {
        this.setState({ currentItem: item })
        this.setState({ isNoteCancelOpen: true })
    }

    removeRegister = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover este registro?`);
        if (confirm) {
            const user = getUsuarioCompact()
            const message = `Apagar um registro de nota com o status ${item.currentStatus}.`
            sendLogByUser("Nota fiscal", message, item)
            await deleteNewNoteById({ id: item.id })
            toastSuccess("Registro apagado com sucesso!")
            this.load()
        }
    }

    showMensalista = async (item) => {
        toastSuccess("Buscando cobrança...")
        const charge = await getChargeById({ id: item.identifier })
        if (charge) {
            goToNewWindow(`/visualizar/mensalista/${charge.monthlyId}?chargeId=${charge.id}`)
        } else {
            toastSuccess("Cobrança não encontrada.")
        }
    }

    showTicket = async (item) => {
        toastSuccess("Buscando ticket...")
        const ticket = await getTicketByNoteIdentifier({ identifier: item.identifier })
        if (ticket) {
            goToNewWindow(`/buscar/ticket?id=${ticket.id}`)
        } else {
            toastWarning("Ticket não encontrado.")
        }
    }

    confirmCancelNote = async () => {
        if (this.state.motivo === "") {
            toastWarning("Informe o motivo do cancelamento")
            return
        }
        const option = {
            method: 'POST',
            body: {
                motivo: this.state.motivo
            }
        }
        this.setState({ cancelNoteLoading: true })
        const result = await fetchFunction(`/newnote/cancel/${this.state.currentItem.id}`, option)
        this.setState({ cancelNoteLoading: false })
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            toastError(result.message)
        }
    }

    resendPreccess = async () => {
        if (this.state.notesSelected.length === 0) {
            toastWarning("Selecione uma nota para processamento.")
            return
        }
        var confirm = window.confirm(`Deseja mesmo re-enviar para a prefeitura as notas selecionadas com erro?`);
        if (confirm) {
            const option = {
                method: 'POST',
                body: {
                    notes: this.state.notesSelected.filter(e => e)
                }
            }
            sendLogByUser("Nota fiscal", `Re-executou o processo de geração de notas com erros.`, option)
            toastLoading("Aguarde...")
            await fetchFunction(`/newnote/process-selected`, option)
            toastDismissLoading()
            toastSuccess("Processo de re-envio de notas executado com sucesso, você pode navegar no sistema normalmente.")
        }
    }

    showProcessNote = (item) => {
        this.setState({ isNoteProcessOpen: true })
        this.setState({ currentItem: item })
    }

    confirmProcessNote = async () => {
        const item = this.state.currentItem
        const message = `Re-enviou para processamento a nota ${item.id} do status ${item.currentStatus}.`
        sendLogByUser("Nota fiscal", message, item)
        const option = {
            method: 'POST',
            body: {
                id: item.id
            }
        }
        this.setState({ processNoteLoading: true })
        await fetchFunction(`/newnote/process`, option)
        reloadWindow()
    }

    editNote = async (item) => {
        toastLoading("Buscando dados...")
        var parameter = await getParameter()
        this.setState({ habilitarEmissaoNotaMostrarCampoEndereco: parameter.habilitarEmissaoNotaMostrarCampoEndereco })
        this.setState({ name: item.name })
        this.setState({ document: item.document })
        if (item.cep) {
            this.fetchCEP(item.cep)
        }
        this.setState({ numero: item.numero })
        this.setState({ issuer: item.issuer })
        this.setState({ valor: item.valor })
        this.setState({ name: item.name })
        this.setState({ document: item.document })
        this.setState({ currentItem: item })
        this.setState({ currentXML: item.xml })
        this.setState({ currentPDF: item.pdf_nfse })
        toastDismissLoading()
        this.setState({ isNoteEditOpen: true })
    }

    confirmEditedNewNote = async () => {
        const item = this.state.currentItem
        item.name = this.state.name
        item.document = this.state.document
        item.issuer = this.state.issuer
        item.valor = this.state.valor
        item.pdf_nfse = this.state.currentPDF
        item.xml = this.state.currentXML
        if (this.state.issuer) {
            if (!isValidDocument(this.state.document)) {
                toastWarning("Informe um documento válido.")
                return
            }
            if (!this.state.name || this.state.name === "") {
                toastWarning("Informe o nome ou razão social.")
                return
            }
            item.document = this.state.document
            item.name = this.state.name
        } else {
            item.document = null
            item.name = null
        }
        if (this.state.cep) {
            item.cep = this.state.cep
            item.numero = this.state.numero
            item.logradouro = valueByMaximunChars(this.state.logradouro, 20)
            item.complemento = this.state.complemento
            item.bairro = valueByMaximunChars(this.state.bairro, 20)
            item.cidade = valueByMaximunChars(this.state.cidade, 20)
            item.uf = this.state.uf
        }
        if (item.currentStatus === "NOTE_GENERATED") {
            await updateNewNoteById({ id: item.id, data: item })
        } else {
            item.currentStatus = "NOTE_PROCESSING"
            if (!item.status) {
                item.status = []
            }
            item.status.push({
                createdBy: getUsuarioCompact(),
                createdAt: new Date(),
                status: "NOTE_PROCESSING"
            })
            const option = {
                method: 'POST',
                body: {
                    id: item.id
                }
            }
            await updateNewNoteById({ id: item.id, data: item })
            await fetchFunction(`/newnote/process`, option)
        }
        toastSuccess("Dados alterados com sucesso!")
        this.setState({ isNoteEditOpen: false })
        this.load()
    }

    exportRows = () => {
        const rows = this.state.notes.map(e => {
            function convertStatus(currentStatus) {
                switch (currentStatus) {
                    case NOTE_PROCESSING: return "Nota em processamento"
                    case NOTE_SENT: return "Nota na prefeitura"
                    case NOTE_GENERATED: return "Nota gerada"
                    case NOTE_CANCELED: return "Nota cancelada"
                    case NOTE_ERROR: return "Erro ao tentar emitir a nota"
                    case NOTE_CONTIGENCY: return "Contigência"
                }
            }
            return {
                CRIADO_EM: Moment(e.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm"),
                TIPO: e.noteType,
                DOCUMENTO: e.document?.padStart(11, "0")?.toString() ?? "Não informado",
                VALOR: e.valor.toFixed(2),
                STATUS: convertStatus(e.currentStatus),
                PDF: e.pdf_nfse ?? "",
                XML: e.xml ?? ""
            }
        })
        exportAndDownloadXLS(rows, `NOTAS_EMITIDAS_ULTIMOS_${this.state.days}_DIAS`)
    }

    showStatus = (item) => {
        this.setState({ currentItem: item })
        this.setState({ isShowingStatus: true })
    }

    hideStatus = () => {
        this.setState({ isShowingStatus: false })
    }

    showLog = (item) => {
        this.setState({ currentItemLog: JSON.stringify(item.log) })
        this.setState({ isShowingLog: true })
    }

    hideLog = () => {
        this.setState({ isShowingLog: false })
    }

    showGenerateNoteModal = () => {
        this.setState({ isOpenGenerateNoteModal: true })
    }

    hideGenerateNoteModal = () => {
        this.setState({ isOpenGenerateNoteModal: false })
    }

    saveGenerateNoteModal = () => {
        this.load()
        this.hideGenerateNoteModal()
    }

    changeStatus = (item, index) => {
        this.setState({ statusSelected: item })
        this.setState({ currentXML: this.state.currentItem.xml })
        this.setState({ currentPDF: this.state.currentItem.pdf_nfse })
        this.setState({ statusIndexSelected: index })
        this.setState({ isOpenModalChangeStatus: true })
    }

    confirmNewStatus = async () => {
        var newStatus = this.state.newStatusSelected
        if (newStatus !== '') {
            var note = this.state.currentItem
            note.xml = this.state.currentXML
            note.pdf_nfse = this.state.currentPDF
            note.status[this.state.statusIndexSelected].status = newStatus
            note.currentStatus = newStatus
            if (newStatus === "NOTE_GENERATED") {
                note.status[this.state.statusIndexSelected].log = null
            }
            await updateNewNoteById({ id: note.id, data: note })
            const user = getUsuario()
            const message = `Alterou o último status da nota de ${this.state.statusSelected.status} para ${newStatus}.`
            sendLogByUser("Nota fiscal", message, true, note)
            this.setState({ isOpenModalChangeStatus: false })
            this.setState({ isShowingStatus: false })
        } else {
            toastWarning("Informe o novo status")
        }
    }

    onSelectedNotes = (notes) => {
        this.setState({ notes: notes })
        this.setState({ isLoading: false })
    }

    onCheckAllNotesWithError = (e) => {
        if (e.target.checked) {
            for (let index = 0; index < this.state.notes.length; index++) {
                const element = this.state.notes[index];
                if (element.currentStatus === NOTE_ERROR || element.currentStatus === NOTE_PROCESSING) {
                    this.state.notesSelected[index] = this.state.notes[index].id
                }
            }
        } else {
            for (let index = 0; index < this.state.notes.length; index++) {
                const element = this.state.notes[index];
                if (element.currentStatus === NOTE_ERROR || element.currentStatus === NOTE_PROCESSING) {
                    this.state.notesSelected[index] = null
                }
            }
        }
        this.setState({ notesSelected: this.state.notesSelected }, () => {
            this.setState({ showButtonToProcess: this.state.notesSelected.filter(e => e).length > 0 })
        })
    }

    onCheckedNote = (e, index) => {
        if (e.target.checked) {
            this.state.notesSelected[index] = this.state.notes[index].id
        } else {
            this.state.notesSelected[index] = null
        }
        this.setState({ notesSelected: this.state.notesSelected }, () => {
            this.setState({ showButtonToProcess: this.state.notesSelected.filter(e => e).length > 0 })
        })
    }

    handleParksChange = (newValue) => {
        this.setState({ parks: newValue })
    }

    handleStartDateChange = (newValue) => {
        this.setState({ startDate: newValue })
    }

    handleEndDateChange = (newValue) => {
        this.setState({ endDate: newValue })
    }

    handleLimitChange = (e) => {
        this.setState({ limit: parseInt(e.target.value) })
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ uf: result.uf }, () => {
                this.updateAddress()
            })
        }
    }

    updateNumber = (e) => {
        this.setState({ numero: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateLogradouro = (e) => {
        this.setState({ logradouro: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateBairro = (e) => {
        this.setState({ bairro: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateAddress = () => {
        this.setState({ allAddress: `${this.state.logradouro}, ${this.state.numero} - ${this.state.bairro}, ${this.state.uf}` })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Selecione o seu filtro
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-2 mt-4'>
                                    <MobileDatePicker
                                        label="Data inicial"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.startDate}
                                        onChange={this.handleStartDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                    />
                                </div>
                                <div className='col-lg-2 mt-4'>
                                    <MobileDatePicker
                                        label="Data final"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.endDate}
                                        onChange={this.handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                    />
                                </div>
                                <div className='col-lg-2'>
                                    <label>Tipo</label>
                                    <select className="form-select" value={this.state.type} onChange={this.typeChange}>
                                        <option value=''>Todos</option>
                                        <option value='ticket'>Pagamento de ticket</option>
                                        <option value='mensalista'>Pagamento de mensalista</option>
                                        <option value='customizado'>Gerado manualmente</option>
                                    </select>
                                </div>
                                <div className='col-lg-2'>
                                    <label>Status</label>
                                    <select className="form-select" value={this.state.currentStatus} onChange={this.currentStatusChange}>
                                        <option value=''>Todos</option>
                                        <option value="NOTE_PROCESSING">Notas em processamento</option>
                                        <option value="NOTE_SENT">Notas na prefeitura</option>
                                        <option value="NOTE_GENERATED">Notas gerada</option>
                                        <option value="NOTE_CANCELED">Notas cancelada</option>
                                        <option value="NOTE_CONTIGENCY">Contigência</option>
                                        <option value="NOTE_ERROR">Erro</option>
                                    </select>
                                </div>
                                <div className='col-lg-2'>
                                    <label>Quantidade</label>
                                    <select className="form-select" value={this.state.limit} onChange={this.handleLimitChange}>
                                        <option value={5}>5 registros</option>
                                        <option value={10}>10 registros</option>
                                        <option value={15}>15 registros</option>
                                        <option value={20}>20 registros</option>
                                        <option value={25}>25 registros</option>
                                        <option value={100}>100 registros</option>
                                    </select>
                                </div>
                                <div className='col-lg-2'>
                                    <button type="button" className="btn btn-primary my-4" onClick={(e) => this.applyFilter()}>Aplicar filtro</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog ullWidth maxWidth={"xs"} open={this.state.isNoteProcessOpen}>
                        {
                            this.state.processNoteLoading ? <Loading /> :
                                <>
                                    <MuiDialogTitle className='text-center'>
                                        Emissão de nota
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        <h5 className='text-center'>Deseja mesmo enviar este registro para que a prefeitura faça a emissão da nota?</h5>
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={(e) => this.setState({ isNoteProcessOpen: false })} className='btn btn-secondary'>Fechar</button>
                                            <button onClick={() => this.confirmProcessNote()} className='btn btn-success'>Confirmar</button>
                                        </div>
                                    </MuiDialogActions>
                                </>
                        }
                    </Dialog>
                    <Dialog fullWidth maxWidth={"xs"} open={this.state.isNoteCancelOpen}>
                        {
                            this.state.cancelNoteLoading ? <Loading /> :
                                <>
                                    <MuiDialogTitle className='text-center'>
                                        Cancelar nota fiscal
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        <div className='col-lg-12'>
                                            <div className='alert alert-danger'>
                                                <strong>Atenção: </strong> Este registro já foi convertido em nota fiscal, caso faça o cancelamento, esta ação não poderá ser desfeita!
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <label>Motivo</label>
                                            <select className="form-select" onChange={(e) => this.setState({ motivo: e.target.value })} >
                                                <option>Selecionar</option>
                                                <option value="1">Erro na emissão</option>
                                                <option value="2">Serviço não prestado</option>
                                                <option value="4">Duplicidade da nota</option>
                                            </select>
                                        </div>
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={(e) => this.setState({ isNoteCancelOpen: false })} className='btn btn-secondary'>Fechar</button>
                                            <button onClick={() => this.confirmCancelNote()} className='btn btn-danger'>Confirmar</button>
                                        </div>
                                    </MuiDialogActions>
                                </>
                        }
                    </Dialog>
                    <Dialog onClose={this.hideLog} fullWidth maxWidth={"sm"} open={this.state.isShowingLog}>
                        <MuiDialogTitle className='text-center'>
                            Log do evento
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='mt-2'>
                                {
                                    isJSON(this.state.currentItemLog) ?
                                        <ReactJson name={false} displayDataTypes={false} src={JSON.parse(this.state.currentItemLog)} enableClipboard={false} /> :
                                        <span>{this.state.currentItemLog}</span>
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.hideLog} className='btn btn-secondary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={(e) => this.setState({ isNoteEditOpen: false })} fullWidth maxWidth={"xs"} open={this.state.isNoteEditOpen}>
                        <MuiDialogTitle className='text-center'>
                            Detalhes da nota
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <Tooltip title={`${this.state.currentItem?.id} - ${this.state.currentItem?.identifier}`} placement="top">
                                <span>
                                    {this.state.currentItem?.currentStatus === NOTE_PROCESSING && <h6><span className="badge text-bg-warning text-white">Processando</span> </h6>}
                                    {this.state.currentItem?.currentStatus === NOTE_SENT && <h6><span className="badge text-bg-warning text-white">Na prefeitura</span> </h6>}
                                    {this.state.currentItem?.currentStatus === NOTE_GENERATED && <h6><span className="badge text-bg-success">Nota gerada</span></h6>}
                                    {this.state.currentItem?.currentStatus === NOTE_CANCELED && <h6><span className="badge text-bg-danger">Cancelada</span></h6>}
                                    {
                                        this.state.currentItem?.currentStatus === NOTE_ERROR &&
                                        <AlertDanger tag="Erro">
                                            <div className='mt-2'>{this.state.currentItem?.status?.filter(e => e.status === NOTE_ERROR && e.log).map(e => e.log).join(", ")}</div>
                                        </AlertDanger>
                                    }
                                    {this.state.currentItem?.currentStatus === NOTE_CONTIGENCY && <h6><span className="badge text-bg-danger">Contigência</span></h6>}
                                </span>
                            </Tooltip>
                            {
                                this.state.currentItem?.currentStatus !== NOTE_GENERATED ?
                                    <>
                                        <div className="form-check form-switch my-2">
                                            <input className="form-check-input" id="habilitarRetiradaDeCaixaAcima" checked={!this.state.issuer} onChange={(e) => this.setState({ issuer: !e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="habilitarRetiradaDeCaixaAcima">
                                                Não informar os dados do cliente
                                            </label>
                                        </div>
                                        {
                                            this.state.issuer && <>
                                                <div className="col-lg-12">
                                                    <label>Nome</label>
                                                    <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Documento</label>
                                                    <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" />
                                                </div>
                                                {
                                                    this.state.habilitarEmissaoNotaMostrarCampoEndereco &&
                                                    <div className='alert alert-warning mt-3'>
                                                        <div className='row'>
                                                            <div className="col-lg-8">
                                                                <label>CEP</label>
                                                                <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>Número</label>
                                                                <input type="text" onChange={(e) => this.updateNumber(e)} value={this.state.numero} className="form-control" />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Logradouro</label>
                                                                <input type="text" onChange={(e) => this.updateLogradouro(e)} value={this.state.logradouro} className="form-control" />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Bairro</label>
                                                                <input type="text" onChange={(e) => this.updateBairro(e)} value={this.state.bairro} className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mt-2">
                                                            <h6>{this.state.allAddress}</h6>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {
                                            this.state.currentItem?.type === "customizado" ?
                                                <div className="col-lg-12">
                                                    <label>Valor</label>
                                                    <CurrencyInput value={this.state.valor} prefix="R$" className='form-control' onChangeEvent={(e, b, floatvalue) => this.setState({ valor: floatvalue })} />
                                                </div> :
                                                <div className="col-lg-12">
                                                    <label>Valor</label><br />
                                                    <h5 className='text-success'>
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                    </h5>
                                                </div>
                                        }
                                        <div className='mt-3'>
                                            <AlertDanger message="Ao confirmar a edição o sistema automaticamente tentará gerar a nota." />
                                        </div>
                                    </> : <>
                                        <div className="col-lg-12">
                                            <label>Nome ou razão social</label>
                                            <h6>{takeIfIsNotNullOrEmpty(this.state.currentItem?.name) ?? "Não informado"}</h6>
                                        </div>
                                        <div className="col-lg-12">
                                            <label>Documento</label>
                                            <h6>{takeIfIsNotNullOrEmpty(documentMask(this.state.currentItem?.document)) ?? "Não informado"}</h6>
                                        </div>
                                        <div className="col-lg-12">
                                            <label>E-mail</label>
                                            <h6>{takeIfIsNotNullOrEmpty(this.state.currentItem?.email) ?? "Não informado"}</h6>
                                        </div>
                                        <div className='col-lg-12'>
                                            <label>Data da solicitação</label>
                                            <h6>{Moment(this.state.currentItem?.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</h6>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="col-lg-12">
                                                <label>Valor</label>
                                                <h3 className='text-success'>{toCurrency(this.state.currentItem?.valor)}</h3>
                                            </div>
                                        </div>
                                    </>
                            }
                            {
                                this.state.isAdministrador && <div className='col-lg-12'>
                                    <div className="col-lg-12">
                                        <label>Nome</label>
                                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Documento</label>
                                        <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" />
                                    </div>
                                    <label>XML</label>
                                    <input type="text" value={this.state.currentXML} onChange={(e) => { this.setState({ currentXML: e.target.value }) }} className="form-control" />
                                    <label>PDF</label>
                                    <input type="text" value={this.state.currentPDF} onChange={(e) => { this.setState({ currentPDF: e.target.value }) }} className="form-control" />
                                </div>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions>
                            {
                                this.state.currentItem?.currentStatus !== NOTE_GENERATED || this.state.isAdministrador ?
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isNoteEditOpen: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={() => this.confirmEditedNewNote()} className='btn btn-danger'>Salvar</button>
                                    </div> : <div className='btn-group m-3'>
                                        {
                                            !isNullOrEmpty(this.state.currentItem?.xml) &&
                                            <a href={(this.state.currentItem?.xml)} target='_blank' className="btn btn-secondary">
                                                <i className="fas fa-file-code mx-2" />
                                                XML
                                            </a>
                                        }
                                        {
                                            !isNullOrEmpty(this.state.currentItem?.pdf_nfse) &&
                                            <a href={(this.state.currentItem?.pdf_nfse)} target='_blank' className="btn btn-success">
                                                <i className="fas fa-file-pdf mx-2" />
                                                PDF
                                            </a>
                                        }
                                        <button onClick={(e) => this.setState({ isNoteEditOpen: false })} className='btn btn-secondary'>Fechar</button>
                                    </div>

                            }
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.hideStatus} maxWidth={"sm"} fullWidth open={this.state.isShowingStatus}>
                        <MuiDialogTitle className='text-center'>
                            Todos os status
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <table className="table table-striped">
                                            <tr>
                                                <th width={10}></th>
                                                <th>Data e Hora</th>
                                                <th>Operador</th>
                                                <th width={10}></th>
                                            </tr>
                                            <tbody>
                                                {this.state.currentItem?.status.map((item, index) =>
                                                    <tr key={index}>
                                                        <td width={10}>
                                                            {item.status === NOTE_PROCESSING && <h6><span className="badge text-bg-warning w-100 text-white">Processando</span> </h6>}
                                                            {item.status === NOTE_SENT && <h6><span className="badge text-bg-warning w-1000 text-white">Na prefeitura</span> </h6>}
                                                            {item.status === NOTE_GENERATED && <h6><span className="badge text-bg-success w-100">Nota gerada</span></h6>}
                                                            {item.status === NOTE_CANCELED && <h6><span className="badge text-bg-danger w-100">Cancelada</span></h6>}
                                                            {item.status === NOTE_ERROR && <h6><span className="badge text-bg-danger w-100">Erro</span></h6>}
                                                            {item.status === NOTE_CONTIGENCY && <h6><span className="badge text-bg-danger w-100">Contigência</span></h6>}
                                                        </td>
                                                        <td>
                                                            {Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY HH:mm A")}
                                                        </td>
                                                        <td>
                                                            {item.createdBy?.nome}
                                                        </td>
                                                        <td width={10}>
                                                            <div className='btn-group'>
                                                                {
                                                                    this.state.currentItem.currentStatus !== NOTE_GENERATED &&
                                                                    <Tooltip title="Alterar status" placement="top">
                                                                        <button type="button" onClick={() => { this.changeStatus(item, index) }} className="btn btn-danger">
                                                                            <i className="fas fa-random" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    item.log &&
                                                                    <Tooltip title="Visualizar log" placement="top">
                                                                        <button className="btn btn-secondary" onClick={(e) => this.showLog(item)}>
                                                                            <i className="fas fa-code" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.hideStatus} className='btn btn-secondary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='xs' fullWidth open={this.state.isOpenGenerateNoteModal} >
                        <OperationSingleNoteModal onClose={this.hideGenerateNoteModal} onSave={this.saveGenerateNoteModal} />
                    </Dialog>
                    {
                        this.state.isLoading ?
                            <div className="card mb-4">
                                <div className="card-body py-3">
                                    <EmptyContent text="Carregando informações..." />
                                </div>
                            </div> :
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Notas geradas
                                    <span className='btn-group'>
                                        {
                                            this.state.notes.length !== 0 &&
                                            <button onClick={this.exportRows} className="btn btn-sm btn-secondary">
                                                <i className="fas fa-table mx-2" />
                                                Exportar
                                            </button>
                                        }
                                        <button onClick={this.showGenerateNoteModal} className="btn btn-sm btn-success">
                                            <i className="fas fa-plus mx-2" />
                                            Gerar nota avulsa
                                        </button>
                                    </span>
                                </div>
                                <div className="row">
                                    {
                                        this.state.notes.length === 0 ?
                                            <div className="card-body py-3">
                                                <EmptyContent text="Nenhuma nota localizada com esse filtro" />
                                            </div> :
                                            <div className='col-md-12'>
                                                <SimpleNotesTotals notes={this.state.auxNotes} onSelected={this.onSelectedNotes} />
                                                {
                                                    this.state.showButtonToProcess &&
                                                    <div className='col-lg-2 mx-3'>
                                                        <button className='btn btn-danger' onClick={(e) => this.resendPreccess()}>Executar processo</button>
                                                    </div>
                                                }
                                                <div className='table-responsive'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <td width={1} align='center'>
                                                                    <Tooltip title="Selecionar todas com erro" placement="top">
                                                                        <div className="form-switch">
                                                                            <input className="form-check-input" checked={this.state.checkAllNotesWithError} onChange={(e) => this.onCheckAllNotesWithError(e)} type="checkbox" />
                                                                        </div>
                                                                    </Tooltip>
                                                                </td>
                                                                <td width={20} align='center'><strong>Status</strong></td>
                                                                <td width={10} align='center'><strong>Tipo</strong></td>
                                                                <td align='center'><strong>Nome</strong></td>
                                                                <td align='center'><strong>Documento</strong></td>
                                                                <td className="d-none d-sm-table-cell" align='center' ><strong>Data da solicitação</strong></td>
                                                                <td className="d-none d-sm-table-cell" align='center' ><strong>Data do serviço</strong></td>
                                                                <td align='right'><strong>Valor</strong></td>
                                                                <td width='80' />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.notes.map((item, index) =>
                                                                    <tr key={index} className={item.id === this.state.id && "table-success"}>
                                                                        <td width={1} align='center'>
                                                                            {
                                                                                (item.currentStatus === NOTE_ERROR || item.currentStatus === NOTE_PROCESSING) &&
                                                                                <Tooltip title="Re-enviar" placement="top">
                                                                                    <div className="form-switch">
                                                                                        <input className="form-check-input" id={item.id} checked={this.state.notesSelected[index] === item.id} onChange={(e) => this.onCheckedNote(e, index)} type="checkbox" />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        </td>
                                                                        <td width={10}>
                                                                            <Tooltip title={`${item.id} - ${item.identifier}`} placement="top">
                                                                                <span>
                                                                                    {item.currentStatus === NOTE_PROCESSING && <h6><span className="badge text-bg-warning text-white w-100">Processando</span> </h6>}
                                                                                    {item.currentStatus === NOTE_SENT && <h6><span className="badge text-bg-warning w-1000 text-white">Na prefeitura</span> </h6>}
                                                                                    {item.currentStatus === NOTE_GENERATED && <h6><span className="badge text-bg-success w-100">Nota gerada</span></h6>}
                                                                                    {item.currentStatus === NOTE_CANCELED && <h6><span className="badge text-bg-danger w-100">Cancelada</span></h6>}
                                                                                    {item.currentStatus === NOTE_ERROR && <h6><span className="badge text-bg-danger w-100">Erro</span></h6>}
                                                                                    {item.currentStatus === NOTE_CONTIGENCY && <h6><span className="badge text-bg-danger w-100">Contigência</span></h6>}
                                                                                </span>
                                                                            </Tooltip>
                                                                        </td>
                                                                        <td width={10}>
                                                                            <span>
                                                                                {item.type === "mensalista" && <h6><span className="badge text-bg-secondary w-100">{item.noteType === "NFC-e" ? "NFC-e - " : "NFS-e - "} Mensalista</span> </h6>}
                                                                                {item.type === "ticket" && <h6><span className="badge text-bg-info w-100 text-white">{item.noteType === "NFC-e" ? "NFC-e - " : "NFS-e - "} Ticket</span> </h6>}
                                                                                {item.type === "customizado" && <h6><span className="badge text-bg-primary w-100">{item.noteType === "NFC-e" ? "NFC-e - " : "NFS-e - "} Avulsa</span> </h6>}
                                                                                {item.type === "cobranca" && <h6><span className="badge text-bg-success w-100">{item.noteType === "NFC-e" ? "NFC-e - " : "NFS-e - "} Licença</span> </h6>}
                                                                            </span>
                                                                        </td>
                                                                        <td align='center'>{takeIfIsNotNullOrEmpty(item.name) ?? "Não informado"}</td>
                                                                        <td align='center'>{item.document ? (documentMask(item.document) ?? "Não informado") : "Não informado"}</td>
                                                                        <td width={300} align='center' className="d-none d-sm-table-cell">
                                                                            {Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}
                                                                        </td>
                                                                        <td width={300} align='center' className="d-none d-sm-table-cell">
                                                                            {
                                                                                item.dataCompetencia ? <span>{Moment(item.dataCompetencia.seconds * 1000).format("DD/MM/YYYY")}</span> : <span>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY")}</span>
                                                                            }
                                                                        </td>
                                                                        <td align='right'><NumberFormat value={item.valor} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                                        <td align='right'>
                                                                            <div className='btn-group'>
                                                                                {
                                                                                    (item.newNote?.xml ?? item.xml) &&
                                                                                    <Tooltip title="Baixar XML" placement="top">
                                                                                        <a href={(item.newNote?.xml ?? item.xml)} download target='_blank' className="btn btn-secondary">
                                                                                            <i className="fas fa-file-code" />
                                                                                        </a>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    (item.newNote?.pdf_nfse ?? item.pdf_nfse) &&
                                                                                    <Tooltip title="Visualizar PDF" placement="top">
                                                                                        <a href={(item.newNote?.pdf_nfse ?? item.pdf_nfse)} target='_blank' className="btn btn-success">
                                                                                            <i className="fas fa-file-pdf" />
                                                                                        </a>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    item.currentStatus === NOTE_PROCESSING &&
                                                                                    <>
                                                                                        <Tooltip title="Enviar novamente" placement="top">
                                                                                            <button className="btn btn-success" onClick={(e) => this.showProcessNote(item)}>
                                                                                                <i className="far fa-paper-plane" />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Apagar registro" placement="top">
                                                                                            <button className="btn btn-danger" onClick={(e) => this.removeRegister(item)}>
                                                                                                <i className="fas fa-trash" />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    item.currentStatus === NOTE_GENERATED &&
                                                                                    <Tooltip title="Cancelar nota" placement="top">
                                                                                        <button className="btn btn-danger" onClick={(e) => this.cancelNote(item)}>
                                                                                            <i className="fas fa-trash" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    item.currentStatus === NOTE_ERROR || this.state.isAdministrador &&
                                                                                    <>
                                                                                        <Tooltip title="Editar dados" placement="top">
                                                                                            <button className="btn btn-warning text-white" onClick={(e) => this.editNote(item)}>
                                                                                                <i className="fas fa-edit" />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                }
                                                                                <button type="button" onClick={(e) => { this.showStatus(item) }} className="btn btn-secondary">
                                                                                    <i className="fas fa-bars" />
                                                                                </button>
                                                                                {
                                                                                    (item.currentStatus === NOTE_CANCELED || item.currentStatus === NOTE_ERROR) &&
                                                                                    <>
                                                                                        <Tooltip title="Apagar registro" placement="top">
                                                                                            <button className="btn btn-danger" onClick={(e) => this.removeRegister(item)}>
                                                                                                <i className="fas fa-trash" />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    item.type !== 'mensalista' &&
                                                                                    <Tooltip title="Visualizar ticket" placement="top">
                                                                                        <button className="btn btn-primary" onClick={(e) => this.showTicket(item)}>
                                                                                            <i className="fas fa-eye" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    item.type === 'mensalista' &&
                                                                                    <Tooltip title="Visualizar mensalista" placement="top">
                                                                                        <button className="btn btn-primary" onClick={(e) => this.showMensalista(item)}>
                                                                                            <i className="fas fa-eye" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Dialog onClose={(e) => this.setState({ isOpenModalChangeStatus: false })} fullWidth maxWidth={"xs"} open={this.state.isOpenModalChangeStatus}>
                                                    <MuiDialogTitle className='text-center'>
                                                        Alterar status
                                                    </MuiDialogTitle>
                                                    <MuiDialogContent>
                                                        <AlertDanger message="Alterando este status você alterará o status principal da nota." />
                                                        <label>Selecionar novo status</label>
                                                        <select className="form-select" value={this.state.newStatusSelected} onChange={(e) => this.setState({ newStatusSelected: e.target.value })}>
                                                            <option value="">Selecionar</option>
                                                            <option value="NOTE_GENERATED">Nota gerada</option>
                                                            <option value="NOTE_PROCESSING">Processando</option>
                                                            <option value="NOTE_SENT">Na prefeitura</option>
                                                            <option value="NOTE_CANCELED">Cancelada</option>
                                                            <option value="NOTE_CONTIGENCY">Contigência</option>
                                                            <option value="NOTE_ERROR">Erro</option>
                                                        </select>
                                                        {
                                                            this.state.newStatusSelected === "NOTE_GENERATED" && <div className='col-lg-12'>
                                                                <label>XML</label>
                                                                <input type="text" value={this.state.currentXML} onChange={(e) => { this.setState({ currentXML: e.target.value }) }} className="form-control" />
                                                                <label>PDF</label>
                                                                <input type="text" value={this.state.currentPDF} onChange={(e) => { this.setState({ currentPDF: e.target.value }) }} className="form-control" />
                                                            </div>
                                                        }
                                                    </MuiDialogContent>
                                                    <MuiDialogActions>
                                                        <div className='btn-group m-3'>
                                                            <button onClick={(e) => this.setState({ isOpenModalChangeStatus: false })} className='btn btn-secondary'>Fechar</button>
                                                            <button onClick={(e) => this.confirmNewStatus()} className='btn btn-danger'>Confirmar</button>
                                                        </div>
                                                    </MuiDialogActions>
                                                </Dialog>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                </NavBar >
            </>)
    }
}

class SimpleNotesTotals extends React.Component {

    constructor(props) {
        super(props)
        const successNotes = props.notes.filter(e => e.currentStatus === "NOTE_GENERATED")
        const processingNotes = props.notes.filter(e => e.currentStatus === "NOTE_PROCESSING")
        const errorNotes = props.notes.filter(e => e.currentStatus === "NOTE_ERROR")
        this.state = {
            totalNotesQuantity: props.notes.length,
            totalNotes: props.notes.map(e => parseFloat(e.valor)).reduce((a, b) => a + b, 0),
            successTotalNotes: successNotes.map(e => parseFloat(e.valor)).reduce((a, b) => a + b, 0),
            successQuantity: successNotes.length,
            errorTotalNotes: errorNotes.map(e => parseFloat(e.valor)).reduce((a, b) => a + b, 0),
            errorQuantity: errorNotes.length,
            processingTotalNotes: processingNotes.map(e => parseFloat(e.valor)).reduce((a, b) => a + b, 0),
            processingQuantity: processingNotes.length,
        }
    }

    onSelected = (status) => {
        if (status === "") {
            this.props.onSelected(this.props.notes)
        } else {
            this.props.onSelected(this.props.notes.filter(e => e.currentStatus === status))
        }
    }

    render() {
        return (
            <div className='card-body'>
                <div className='row'>
                    {
                        this.state.totalNotesQuantity > 0 &&
                        <div className='col-lg-3'>
                            <div className="card text-light bg-secondary mb-3" role='button' onClick={(e) => this.onSelected("")}>
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Valor total</h5>
                                    <h3 className="card-text text-center text-white">
                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.totalNotes} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">{this.state.totalNotesQuantity} Notas lançadas</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.successQuantity > 0 &&
                        <div className='col-lg-3'>
                            <div className="card text-light bg-success mb-3" role='button' onClick={(e) => this.onSelected("NOTE_GENERATED")}>
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Valor</h5>
                                    <h3 className="card-text text-center text-white">
                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.successTotalNotes} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">{this.state.successQuantity} Notas geradas</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.processingQuantity > 0 &&
                        <div className='col-lg-3'>
                            <div className="card text-light bg-warning mb-3" role='button' onClick={(e) => this.onSelected("NOTE_PROCESSING")}>
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Valor</h5>
                                    <h3 className="card-text text-center text-white">
                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.processingTotalNotes} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">{this.state.processingQuantity} Notas em processamento</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.errorQuantity > 0 &&
                        <div className='col-lg-3'>
                            <div className="card text-light bg-danger mb-3" role='button' onClick={(e) => this.onSelected("NOTE_ERROR")}>
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Valor</h5>
                                    <h3 className="card-text text-center text-white">
                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.errorTotalNotes} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">{this.state.errorQuantity} Notas com erro</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>)
    }
}

class SimpleNewNotes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            revendaId: getRevenda()?.id,
            limit: props.limit ?? 5,
            items: []
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const items = await getAllByRessaleId({ ressaleId: this.state.revendaId, limit: this.state.limit })
        this.setState({ items: items.filter(e => e.currentStatus !== NOTE_GENERATED) })
        this.setState({ isLoading: false })
    }

    changeLimit = (e) => {
        this.setState({ limit: parseInt(e.target.value) }, () => {
            this.load()
        })
    }

    render() {
        return (
            <>
                <div className='card mb-4'>
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Últimas notas
                        <div className='col-lg-3'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                        <option value={5}>5 registros</option>
                                        <option value={10}>10 registros</option>
                                        <option value={15}>15 registros</option>
                                        <option value={20}>20 registros</option>
                                        <option value={25}>25 registros</option>
                                        <option value={100}>100 registros</option>
                                        <option value={200}>200 registros</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            this.state.isLoading ? <Loading /> :
                                this.state.items.length === 0 ?
                                    <div className="card-body py-3">
                                        <EmptyContent text="Nenhuma nota localizada com esse filtro" />
                                    </div> :
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10} align='center'><strong>Status</strong></td>
                                                        <td><strong>Documento</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center' ><strong>Data da solicitação</strong></td>
                                                        <td align='right'><strong>Valor</strong></td>
                                                        <td width='80' className="d-none d-sm-table-cell" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td width={10}>
                                                                    <Tooltip title={item.id} placement="top">
                                                                        <span>
                                                                            {item.currentStatus === NOTE_PROCESSING && <h6><span className="badge text-bg-warning text-white w-100">Aguardando</span> </h6>}
                                                                            {item.currentStatus === NOTE_SENT && <h6><span className="badge text-bg-warning text-white w-1000 text-white">Na prefeitura</span> </h6>}
                                                                            {item.currentStatus === NOTE_GENERATED && <h6><span className="badge text-bg-success w-100">Nota gerada</span></h6>}
                                                                            {item.currentStatus === NOTE_CANCELED && <h6><span className="badge text-bg-danger w-100">Cancelada</span></h6>}
                                                                            {item.currentStatus === NOTE_ERROR && <h6><span className="badge text-bg-danger w-100">Erro</span></h6>}
                                                                            {item.currentStatus === NOTE_CONTIGENCY && <h6><span className="badge text-bg-danger w-100">Contigência</span></h6>}
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>{item.document ? (documentMask(item.document) ?? "Não informado") : "Não informado"}</td>
                                                                <td width={300} align='center' className="d-none d-sm-table-cell">
                                                                    {
                                                                        item.createdAt ? <span>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</span> : <span>-</span>
                                                                    }
                                                                </td>
                                                                <td align='right'><NumberFormat value={item.valor} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                                <td align='right' className="d-none d-sm-table-cell">
                                                                    <div className='btn-group'>
                                                                        {
                                                                            item.type === 'ticket' &&
                                                                            <Tooltip title="Visualizar ticket" placement="top">
                                                                                <a href={'/buscar/ticket?note-identifier=' + item.identifier} target='_blank' rel="noopener noreferrer" className="btn btn-success">
                                                                                    <i className="fas fa-ticket-alt" />
                                                                                </a>
                                                                            </Tooltip>
                                                                        }
                                                                        {
                                                                            item.type === 'mensalista' &&
                                                                            <Tooltip title="Visualizar mensalista" placement="top">
                                                                                <a href={'/visualizar/mensalista?note-identifier=' + item.identifier} target='_blank' rel="noopener noreferrer" className="btn btn-success">
                                                                                    <i className="fas fa-ticket-alt" />
                                                                                </a>
                                                                            </Tooltip>
                                                                        }
                                                                        <ClientButton item={item} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

class NewNoteWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            revendaId: getRevendaIdByPark(),
            isEnabled: false,
            limit: props.limit ?? 5,
            issuer: false,
            document: "",
            cep: "",
            addressNumber: "",
            name: "",
            items: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        var parameter = await getParameter()
        this.setState({ isEnabled: parameter.habilitarEmissaoNotaV2 })
        if (parameter.habilitarEmissaoNotaV2) {
            this.setState({ name: this.props.name })
            this.setState({ document: this.props.document })
            this.setState({ tomador: (this.props.document && this.props.name) })
            this.setState({ habilitarEmissaoNotaMostrarCampoNome: parameter.habilitarEmissaoNotaMostrarCampoNome })
            this.setState({ habilitarEmissaoNotaMostrarCampoEndereco: parameter.habilitarEmissaoNotaMostrarCampoEndereco })
            this.setState({ habilitarEmissaoNotaMostrarTomador: parameter.habilitarEmissaoNotaMostrarTomador })
            this.setState({ notaFiscal: parameter.habilitarEmissaoNotaParaTodosOsPagamentos }, () => {
                this.updateData()
            })
        }
        if (!this.state.habilitarEmissaoNotaMostrarTomador) {
            this.setState({ tomador: true })
        }
        this.setState({ isLoading: false })
    }

    updateData = () => {
        if (!this.props.onChangeValue) {
            return
        }
        if (this.state.notaFiscal) {
            const data = {
                name: this.state.name,
                document: this.state.document,
                tomador: this.state.tomador === true,
                cep: this.state.cep,
                numero: this.state.addressNumber
            }
            this.props.onChangeValue(data)
        } else {
            this.props.onChangeValue(null)
        }
    }


    changeDocument = (e) => {
        this.setState({ document: e.target.value }, () => {
            this.updateData()
        })
    }

    changeCep = (e) => {
        this.setState({ cep: e.target.value }, () => {
            this.updateData()
        })
    }

    changeAddressNumber = (e) => {
        this.setState({ addressNumber: e.target.value }, () => {
            this.updateData()
        })
    }

    enableNewNote = () => {
        sendClickButton(this.props.screen, "Habilitar emissão de nota")
    }

    changeName = (e) => {
        this.setState({ name: e.target.value }, () => {
            this.updateData()
        })
    }

    changeTomador = (e) => {
        this.setState({ tomador: !e.target.checked }, () => {
            this.updateData()
        })
    }

    changeEnableNote = (e) => {
        this.setState({ notaFiscal: e.target.checked }, () => {
            this.updateData()
        })
    }

    render() {
        return (
            <>
                {
                    this.state.isEnabled &&
                    <AlertSuccess tag="Emissão de notas">
                        {
                            this.state.isLoading ? <Loading /> : <>
                                {
                                    this.state.isEnabled ? <>
                                        <div className="form-check form-switch my-2">
                                            <input className="form-check-input text-success" id="notaFiscal" checked={this.state.notaFiscal} onChange={(e) => this.changeEnableNote(e)} type="checkbox" />
                                            <label className="form-check-label" htmlFor="notaFiscal">
                                                Emitir nota fiscal
                                            </label>
                                        </div>
                                        {
                                            this.state.notaFiscal && <>
                                                {
                                                    this.state.habilitarEmissaoNotaMostrarTomador &&
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="tomador" checked={!this.state.tomador} onChange={(e) => this.changeTomador(e)} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="tomador">
                                                            Não informar os dados do cliente
                                                        </label>
                                                    </div>
                                                }
                                                {
                                                    this.state.tomador &&
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            {
                                                                this.state.habilitarEmissaoNotaMostrarCampoNome &&
                                                                <div className="col-lg-12">
                                                                    <label>Nome ou razão social</label>
                                                                    <input type="text" onChange={(e) => this.changeName(e)} value={this.state.name} className="form-control" />
                                                                </div>
                                                            }
                                                            <div className="col-lg-12">
                                                                <label>Número documento</label>
                                                                <input type="text" onChange={(e) => this.changeDocument(e)} value={this.state.document} className="form-control" />
                                                            </div>
                                                            {
                                                                this.state.habilitarEmissaoNotaMostrarCampoEndereco && !isValidCNPJ(this.state.document) &&
                                                                <div className="row">
                                                                    <div className="col-lg-8">
                                                                        <label>CEP</label>
                                                                        <input type="text" onChange={(e) => this.changeCep(e)} value={this.state.cep} className="form-control" />
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <label>Nº</label>
                                                                        <input type="text" onChange={(e) => this.changeAddressNumber(e)} value={this.state.addressNumber} className="form-control" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </> : <a href='/nota-fiscal/configurar' onClick={(e) => this.enableNewNote()} className='btn btn-success mt-2'>Habilitar emissão de notas</a>
                                }
                            </>
                        }
                    </AlertSuccess>
                }
            </>
        )
    }
}

export { SimpleNewNotes, ListarNotaNovo, NewNoteWidget };